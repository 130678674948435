import { memo, useMemo, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout, Breadcrumb } from "antd";
import { useRouteMatch, useLocation, matchPath } from "react-router";
import { useUser } from "../../models/user";
import { isSysAdmin } from "../../util/tool";
// import AppUsers from "../AppUsers";
import AppUserDetail from "../AppUsers/AppUserDetail";
// import AppLivesUser from "../AppLivesUser";
import Licenses from "../Licenses";
import Tasks from "../Tasks";
import TaskDetail from "../Tasks/detail";
import Permission from "../Permission";
import CompanyManager from "../CompanyManager";
import CompanyDetail from "../CompanyManager/CompanyDetail";
// import CompanyBaseInfo from "../CompanyInfo";
import CompanyInfoSetting from '../CompanyInfoSetting';
import Role from "../Role";
import Departments from "../Departments";
import Personal from "../Personal";
import Living from "../Living";
import LivingDetail from "../Living/detail";
import Statistics from "../Statistics";
import CompanyCapital from '../CompanyCapital';
import CompanyChargeRecord from '../CompanyChargeRecord';
import OperatorLog from '../OperatorLog';

import dataIcon from '../../assets/menu_icon_data.png';
import memberIcon from '../../assets/menu_icon_member.png';
import taskIcon from '../../assets/menu_icon_task.png';
import storageIcon from '../../assets/menu_icon_storage.png';
import recordIcon from '../../assets/menu_icon_record.png';
import licenceIcon from '../../assets/menu_icon_licence.png';
import appUserIcon from '../../assets/menu_icon_lndividual_user.png';
import settingIcon from '../../assets/menu_icon_setting.png';
import logIcon from '../../assets/menu_icon_log.png';
import { operatorLogUrl } from "../../services/systemUser";

export interface IRouterConfig {
  path: string;
  name: string;
  key?: string;
  icon?: JSX.Element;
  component?: () => any;
}

export interface ISubMenuItem extends IRouterConfig {

  menuVisible?: boolean;
  subPath?: IRouterConfig[];
}

export interface IMenuItem extends ISubMenuItem {
  subMenus?: ISubMenuItem[];
}

const statisticsMenu: IMenuItem = {
  path: "/statistics",
  name: "数据统计",
  key: "/glive/statistics",
  icon: <img src={dataIcon} className='menuIcon' alt='dataIcon' />,
  component: Statistics,
};

export const companyMenus: IMenuItem[] = [
  statisticsMenu,
  {
    path: "/departments",
    name: "部门管理",
    key: "/glive/web/departments/page",
    icon: <img src={memberIcon} className='menuIcon' alt='memberIcon' />,
    component: Departments,
    subPath: [
      {
        path: "/departments/appUsers/:uid",
        name: "用户详情",
        component: AppUserDetail,
      }
    ]
  },
  {
    path: "/tasks",
    name: "任务管理",
    key: "/glive/tasks",
    icon: <img src={taskIcon} className='menuIcon' alt='taskIcon' />,
    component: Tasks,
    subPath: [
      {
        path: "/tasks/:id",
        name: "任务详情",
        component: TaskDetail,
      }
    ]
  },
  {
    path: "/storage",
    name: "流量及存储空间",
    icon: <img src={storageIcon} className='menuIcon' alt='storageIcon' />,
    subMenus: [
      {
        path: "/restStorage",
        name: "剩余流量及存储空间",
        key: "/glive/company/detail",
        component: CompanyCapital,
      },
      {
        path: "/chargeRecord",
        name: "充值记录",
        key: "/glive/cc/record",
        component: CompanyChargeRecord,
      },
    ]
  },
  {
    path: "/setting",
    name: "设置",
    icon: <img src={settingIcon} className='menuIcon' alt='settingIcon' />,
    subMenus: [
      {
        path: "/info",
        name: "组织信息管理",
        key: "/glive/company/detail",
        component: CompanyInfoSetting,
      },
    ]
  },
  {
    path: "/operatorLog",
    name: "操作日志",
    key: operatorLogUrl,
    icon: <img src={logIcon} className='menuIcon' alt='logIcon' />,
    component: OperatorLog,
  },
  // {
  //   path: "/appLiveUsers",
  //   name: "直播用户",
  //   key: "/glive/users",
  //   icon: <UserSwitchOutlined />,
  //   component: AppLivesUser,
  // },
]

export const sysAdminMenus: IMenuItem[] = [
  statisticsMenu,
  {
    path: "/company",
    key: "/enterprises/page",
    name: "公司管理",
    icon: <img src={memberIcon} className='menuIcon' alt='memberIcon' />,
    component: CompanyManager,
    subPath: [
      {
        path: "/company/:id",
        name: "公司详情",
        component: CompanyDetail,
      }
    ]
  },
  {
    path: "/living",
    name: "直播记录",
    key: "/glive/tasks",
    icon: <img src={recordIcon} className='menuIcon' alt='recordIcon' />,
    component: Living,
    subPath: [
      {
        path: "/living/:id",
        name: "直播记录详情",
        component: LivingDetail,
      }
    ]
  },
  {
    path: "/licenses",
    name: "许可证管理",
    key: "/glive/licenses",
    icon: <img src={licenceIcon} className='menuIcon' alt='licenceIcon' />,
    component: Licenses,
  },
  {
    path: "/personal",
    name: "个人用户管理",
    key: "/glive/web/users/page",
    icon: <img src={appUserIcon} className='menuIcon' alt='appUserIcon' />,
    component: Personal,
    subPath: [
      {
        path: "/personal/:uid/notask",
        name: "用户详情",
        component: AppUserDetail,
      }
    ]
  },
  {
    path: "/setting",
    name: "设置",
    icon: <img src={settingIcon} className='menuIcon' alt='settingIcon' />,
    subMenus: [
      {
        path: "/permission",
        key: "/sys/sysPermission/getListByPage",
        name: "权限管理",
        // icon: <ApartmentOutlined />,
        component: Permission,
      },
      {
        path: "/role",
        key: "/sys/sysRole/getListByPage",
        name: "角色管理",
        // icon: <TeamOutlined />,
        component: Role,
      },
    ]
  },
];


const DashboardRoutes = () => {
  const { user } = useUser();
  const sysAdmin = user && isSysAdmin(user);
  const menus = useMemo(() => {
    return sysAdmin ? sysAdminMenus : companyMenus;
  }, [sysAdmin]);
  const routes = useMemo(() => {
    const arr: IRouterConfig[] = [];
    menus.forEach((item: IMenuItem) => {
      if (item.subMenus) {
        item.subMenus.forEach((subItem: ISubMenuItem) => {
          arr.push(subItem);
          if (subItem.subPath) {
            subItem.subPath.forEach((i) => arr.push(i));
          }
        })
      } else {
        arr.push(item);
      }
      if (item.subPath) {
        item.subPath.forEach((i) => arr.push(i));
      }
    })
    return arr;
  }, [menus]);
  const defaultPathName = statisticsMenu.path;

  useEffect(() => {
    return () => {
      console.log('DashboardRoutes unmount');
    }
  }, []);

  const location = useLocation();
  console.log(location);

  // const breadcrumbList = useMemo(() => {
  //   const { pathname } = location;
  //   let list: IRouterConfig[] = [];
  //   for (let index = 0; index < menus.length; index++) {
  //     const menu = menus[index];
  //     let match = matchPath(menu.path, pathname)
  //     console.log('match', match);
  //     if (match) {
  //       list.push(menu);
  //       break;
  //     }
  //     const paths = menu.subMenus || menu.subPath || [];
  //     for (let i = 0; i < paths.length; i++) {
  //       const subMenu = paths[i];
  //       let subMatch = matchPath(subMenu.path, pathname)
  //       console.log('match subMenu', subMenu.path, subMatch);
  //       if (subMatch) {
  //         list.push(menu);
  //         list.push(subMenu);
  //         break;
  //       }
  //     }
  //   }
  //   return list;
  // }, [location, menus]);

  // const defaultPathName = sysAdmin ? "/permission" : "/tasks";
  // console.log('defaulrPathName', user, defaulrPathName);

  return (
    <div>
      {/* {user && <Breadcrumb>
        {breadcrumbList.map(i => <Breadcrumb.Item key={i.path} >
          {i.icon ? i.icon : null}
          <span>{i.name}</span>
        </Breadcrumb.Item>)}
      </Breadcrumb>} */}
      {user && (
        <Switch>
          <Route path="/" exact >
            <Redirect to={{ pathname: defaultPathName }} />
          </Route>
          {routes.map((r: ISubMenuItem) => (
            <Route key={r.path} path={r.path} exact>
              {r.component ? <r.component /> : null}
            </Route>
          ))}
        </Switch>
      )}
    </div>
  );
};

export default memo(DashboardRoutes);
