import React from "react";
import { Progress, ProgressProps } from "antd";
import styles from './index.module.css';

interface IProps extends ProgressProps {
  title: string;
  unit?: string;
  total: number;
  value: number;
}

export default class TimeProgress extends React.Component<IProps> {

  render() {
    const { title, total, value, unit, strokeColor } = this.props;
    let realTotal = 0;
    let realValue = 0;
    let percent = 0;
    // console.log(total, value,);
    if (total === 0 || total === undefined || total === null || isNaN(total) || isNaN(value)) {
      percent = 0;
    } else {
      realTotal = total;
      realValue = value;
      percent = (value / total) * 100;
    }
    return <div>
      <div className={styles.title}>
        {title}：
        {realValue}/{realTotal}{(unit)}
      </div>
      <Progress showInfo={false} strokeColor={strokeColor} percent={percent} />
    </div>;
  }
}