import { FC, useCallback, useEffect, useState } from "react";
import { Form, Input, message, Modal } from "antd";
import {
  addUser,
  fetchUserDetail,
  updateUser,
} from "../../../services/appUser";
import ChooseDepartModal from "../../Departments/ChooseDepartModal";
import { isSuccessResponse, mobileExp } from "../../../util";

const FormItem = Form.Item;

const areaCode = '+86';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

interface IProps {
  visible: boolean;
  uid: number | undefined;
  onCancel: () => void;
  onOk: () => void;
}

const AddUserModal: FC<IProps> = ({ visible, onCancel, onOk, uid }) => {
  const [form] = Form.useForm();
  const [departSele, setDepartSele] = useState<boolean>(false);
  const [selDepartment, setSelDepartment] = useState<IDepartments | undefined>(
    undefined
  );

  useEffect(() => {
    uid &&
      fetchUserDetail(String(uid)).then((resp) => {
        if (isSuccessResponse(resp)) {
          console.log(resp.data);
          const department = resp.data.departments;
          const newData = { ...resp.data };
          if (department?.length !== 0) {
            department &&
              form.setFieldsValue({
                departmentName: department[0].name,
              });
          }
          if (resp.data.phone.includes(areaCode)) {
            newData.phone = resp.data.phone.replace(areaCode, '');
          }
          console.log('phone', newData);
          form.setFieldsValue(newData);
        }
      });
  }, [visible, uid, form]);

  // const validatorPassword = useCallback(
  //   (rule: any, value: any, callback: any) => {

  //     const password = form.getFieldValue("checkPassword");
  //     if (password && password !== value) {
  //       callback(new Error("两次密码输入不一致"));
  //     } else {
  //       callback();
  //     }
  //   },
  //   [form]
  // );

  // const checkPassword = useCallback(
  //   (rule: any, value: any, callback: any) => {
  //     const password = form.getFieldValue("password");
  //     if (password && password !== value) {
  //       callback(new Error("两次密码输入不一致"));
  //     } else {
  //       callback();
  //     }
  //   },
  //   [form]
  // );

  const showDepartment = useCallback(() => {
    setDepartSele(true);
  }, []);

  const hideDepartment = useCallback(() => {
    setDepartSele(false);
  }, []);

  const handleOk = useCallback(
    (code: IDepartments | undefined) => {
      setSelDepartment(code);
      form.setFieldsValue({
        departmentName: code ? code.name : "",
      });
      hideDepartment();
    },
    [setSelDepartment, form, hideDepartment]
  );

  const modalOnOk = useCallback(() => {
    const { phone, nickname } = form.getFieldsValue();
    const error = form.getFieldsError();
    if (error) {
      for (let index = 0; index < error.length; index++) {
        const element = error[index];
        if (element && element.errors && element.errors.length > 0) {
          return;
        }
      }
    }
    const mobile = areaCode + phone;
    if (uid) {
      updateUser({
        phone: mobile,
        nickname,
        uid,
        departmentId: selDepartment ? Number(selDepartment.id) : 0,
      }).then((resp) => {
        if (isSuccessResponse(resp)) {
          message.success('更新成功');
          onOk();
        }
      });
    } else {
      addUser({
        phone: mobile,
        nickname,
        departmentId: selDepartment ? Number(selDepartment.id) : undefined,
      }).then((resp) => {
        if (isSuccessResponse(resp)) {
          message.success('添加成功');
          onOk();
        }
      });
    }
  }, [form, onOk, selDepartment, uid]);

  const handldCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  const title = uid ? "编辑用户" : "添加用户";

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={handldCancel}
      onOk={modalOnOk}
    >
      <Form layout="horizontal" form={form}>
        <FormItem
          {...formItemLayout}
          hasFeedback
          label="手机号"
          name="phone"
          rules={[
            { required: true, message: "请输入手机号" },
            {
              pattern: mobileExp.exp,
              message: mobileExp.errorMessage,
            },
          ]}
        >
          {/* <div style={{ display: "flex", alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>{areaCode}</span> */}
          <Input addonBefore={areaCode} />
          {/* </div> */}
        </FormItem>
        <FormItem
          {...formItemLayout}
          hasFeedback
          label="昵称"
          name="nickname"
          rules={[
            {
              pattern: /^[一-龥a-zA-Z0-9]{1,20}$/,
              message: "需要1-20个字符，只能输入中文、数字或英文字母",
            },
          ]}
        >
          <Input />
        </FormItem>
        {/* {!uid && (
          <FormItem
            {...formItemLayout}
            hasFeedback
            label="密码"
            name="password"
            rules={[
              { required: true, message: "请输入密码" },
              {
                pattern: pwdExp.exp,
                message: pwdExp.errorMessage,
              }
              // { validator: validatorPassword },
            ]}
          >
            <Input.Password />
          </FormItem>
        )}
        {!uid && (
          <FormItem
            {...formItemLayout}
            hasFeedback
            label="确认密码"
            name="checkPassword"
            rules={[{ required: true }, { validator: checkPassword }]}
          >
            <Input.Password />
          </FormItem>
        )} */}
        <FormItem
          {...formItemLayout}
          hasFeedback
          label="部门"
          name="departmentName"
        >
          <Input onClick={showDepartment} />
        </FormItem>
      </Form>
      <ChooseDepartModal
        visible={departSele}
        handleOk={handleOk}
        handleCancel={hideDepartment}
      />
    </Modal>
  );
};

export default AddUserModal;
