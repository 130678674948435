import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isSuccessResponse } from "../util";

interface IFilter extends IAepPage {
  [key: string]: any;
}

interface IOptions {
  params?: IAepPage;
  batchDeleteFn?: (
    keys: React.Key[]
  ) => Promise<IResponse<{}> | IErrorResponse>;
  crossPageSelect?: boolean;
}

export default function useList<T>(
  fetchListFn: (
    params: IFilter
  ) => Promise<(IResponse<IAepPagination<T>> | IErrorResponse) | null>,
  options?: IOptions
) {
  const { params = {}, batchDeleteFn, crossPageSelect } = options || {};
  const { current = 1, size = 10 } = params;
  const filterRef = useRef<IFilter>({ current, size });
  const [list, setList] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const paginationRef = useRef({
    current,
    size,
    pages: 1,
    total: 0,
  });

  const fetchList = useCallback(
    (params?: IFilter) => {
      const newFilter = params || filterRef.current;
      const { current = 1, size = 10 } = newFilter;
      filterRef.current = newFilter;
      setLoading(true);
      fetchListFn({ ...newFilter, current, size })
        .then((res) => {
          if (isSuccessResponse(res)) {
            setList(res.data.records);
            paginationRef.current = {
              current,
              size,
              pages: res.data.pages,
              total: res.data.total,
            };
          }
        })
        .finally(() => setLoading(false));
    },
    [fetchListFn]
  );

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!crossPageSelect) {
      setSelectedRowKeys([]);
    }
  }, [list, crossPageSelect]);

  const fetchNext = useCallback(() => {
    const { current, size, pages } = paginationRef.current;
    if (current === pages) {
      return;
    }
    fetchList({ ...filterRef.current, current: current + 1, size });
  }, [fetchList]);

  const fetchPre = useCallback(() => {
    const { current, size } = paginationRef.current;
    if (current === 1) {
      return;
    }
    fetchList({ ...filterRef.current, current: current - 1, size });
  }, [fetchList]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: setSelectedRowKeys,
    }),
    [selectedRowKeys, setSelectedRowKeys]
  );

  const handleDelete = useCallback(() => {
    if (!batchDeleteFn) {
      return;
    }
    if (selectedRowKeys.length === 0) {
      message.warn("请先勾选需要删除项");
      return;
    }

    Modal.confirm({
      title: "确定要删除已选择项吗？",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return batchDeleteFn(selectedRowKeys).then((resp) => {
          if (isSuccessResponse(resp)) {
            message.success("删除成功");
            setSelectedRowKeys([]);
            fetchList({ ...filterRef.current, current: 1 });
          } else if (!resp.message) {
            message.error("删除失败");
          }
        });
      },
    });
  }, [batchDeleteFn, selectedRowKeys, fetchList]);

  return {
    list,
    loading,
    filter: filterRef.current,
    pagination: paginationRef.current,
    selectedRowKeys,
    rowSelection,
    setSelectedRowKeys,
    fetchList,
    fetchNext,
    fetchPre,
    handleDelete,
  };
}
