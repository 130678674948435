import { useCallback, useMemo } from "react";
import { Space } from "antd";
import GTable from '../../components/GTable';
import { Link } from "react-router-dom";
import { companyPage, companyDetailUrl, companyInfoUrl } from "../../services/company";
import { formatDate } from "../../util";
import useList from "../../hooks/useList";
import FilterForm from "./FilterForm";
import { CheckPermissionKeyInListHook } from '../../containers/PermissionComponents';

export const columnsList = [
  {
    title: '名称',
    dataIndex: 'name'
  },
  {
    title: '系统用户名',
    dataIndex: 'sysUserName',
  },
  {
    title: '角色名',
    dataIndex: 'roleName',
  },
  {
    title: '创建时间',
    dataIndex: 'ctime',
    render: (value: string) => value ? formatDate(value) : '-',
  },
  {
    title: '操作',
    dataIndex: 'opt',
    render: (_: any, record: ICompany) => (
      <Space size="middle" >
        <Link className='tableDetailButton' to={`/company/${record.id}`}>详情</Link>
      </Space>
    ),
  }
];

const CompanyManager = () => {

  const {
    list,
    loading,
    filter,
    pagination,
    fetchList,
  } = useList<ICompany>(companyPage);

  const canDetail = CheckPermissionKeyInListHook(companyDetailUrl) || CheckPermissionKeyInListHook(companyInfoUrl);

  const columns = useMemo(() => {
    if (canDetail) {
      return columnsList;
    }
    return columnsList.filter(i => i.dataIndex !== 'opt');
  }, [canDetail]);

  const handlePageChange = useCallback((page: number, size?: number) => {
    fetchList({ ...filter, page: size !== pagination.pageSize ? 1 : page, size })
  }, [fetchList, filter, pagination]);

  const handleSearch = useCallback((data: any) => {
    fetchList({ ...data, page: 1, size: pagination.pageSize })
  }, [fetchList, pagination.pageSize]);

  return (
    <div className='normalPage'>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <h3>公司列表</h3>
      </div>
      <FilterForm onSearch={handleSearch} />
      <GTable
        rowKey="id"
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange
        }}
      />
    </div>
  )
}

export default CompanyManager;
