export const taskStatusMap = {
  INITIALIZED: '待处理',
  RUNNING: '进行中',
  FINISH: '已完成',
};

export const taskStatusOptions = Object.entries(taskStatusMap).map(([value, label]) => ({
  value,
  label,
}));
