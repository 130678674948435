import { FC, ReactElement, useCallback } from "react";
import { Tree, Popconfirm } from "antd";
import _ from "lodash";
import { CaretDownOutlined } from "@ant-design/icons";
import editIcon from '../../assets/member_management_bt_edit.png';
import deleteIcon from '../../assets/member_management_bt_delete.png';
import addIcon from '../../assets/member_management_bt_add.png';

import '../Departments/index.scss';

const TreeNode = Tree.TreeNode;

function isVersionId(id: string) {
  return id[0] === "v";
}

function splitList(list: IPermission[]) {
  const interfaceList: IPermission[] = [];
  const versionList: IPermission[] = [];
  list.forEach((v) => {
    v.permissionKey ? interfaceList.push(v) : versionList.push(v);
  });
  return { interfaceList, versionList };
}

function getChildrenIds(
  id: string,
  permissionList: IPermission[],
  ids: string[] = []) {
  permissionList.forEach((p) => {
    if (p.pPermissionId === id) {
      ids.push(p.id);
      getChildrenIds(p.id, permissionList, ids);
    }
  });
  return ids;
}

function getParentIds(
  id: string,
  permissionList: IPermission[],
  versionList: IPermission[]
) {
  // 添加permissionKey限制
  let target = null;
  if (isVersionId(id)) {
    const rid = id.split("-")[1];
    target = versionList.find((t) => t.id === rid);
  } else {
    target = permissionList.find((t) => t.id === id && t.uri);
  }
  if (!target || !target.pPermissionId) return ["0"];

  const list: string[] = getParentIds(
    target.pPermissionId,
    permissionList,
    []
  );
  const ids: string[] = [target.pPermissionId].concat(list);
  return ids;
}

interface IProps {
  list: IPermission[];
  checkedKeys?: string[];
  onSelect?: (keys: string[]) => void;
  onEdit?: (p: IPermission, versionList: IPermission[] | undefined) => void;
  onAdd?: (p: IPermission) => void;
  onDelete?: (p: IPermission) => void;
  checkable: boolean;
}

const PermissionTree: FC<IProps> = ({
  list,
  checkedKeys,
  onSelect,
  onEdit,
  onAdd,
  onDelete,
  checkable,
}) => {

  const getCheckedKeys = useCallback((ids: string[] | undefined) => {
    if (!ids) {
      return [];
    }
    const keys = ids.filter((id) => {
      return !list.some((p) => p.pPermissionId === id);
    });
    return keys;
  }, [list]);

  const select = useCallback((ids: any, e: any) => {
    console.log("ids", ids, e, e.event);
    const checked = e.checked;
    const selId = _.get(e, "node.key", 0);
    const { versionList } = splitList(list);
    // 版本的id为 'v-id'; 接口权限的id为 id
    let idSet: Set<string> = new Set();
    const id = selId;
    const cids = isVersionId(id) ? [] : getChildrenIds(id, list).map((i) => i);
    const pids = ids
      .reduce((p: string[], i: string) => {
        const tem = getParentIds(i, list, versionList);
        const l = p.concat(tem);
        return l;
      }, [])
      .map((j: any) => j);
    idSet = new Set(ids.concat(cids, pids));
    if (!checked) {
      cids.forEach((i) => idSet.delete(i));
    }
    if (onSelect) {
      onSelect(Array.from(idSet));
    }
  }, [onSelect, list]);

  const renderTitle = useCallback((t?: IPermission, versionList?: IPermission[]) => {
    // const { select, edit, add } = this.props;
    if (!t) {
      return (
        <span>
          根权限
          {onSelect ? null : onAdd ? (
            <img
              src={addIcon}
              alt='addIcon'
              className='smallAddIcon'
              onClick={() => onAdd({ id: "0" })}
            />
          ) : null}
        </span>
      );
    }
    if (onSelect)
      return t.permissionName ? t.permissionName : `版本号：${t.versionName}`;
    return (
      <span>
        {t.permissionName ? t.permissionName : `可选版本号：${t.versionName}`}
        {!t.versionName
          ? [
            onEdit ? (
              <img
                src={editIcon}
                alt='editIcon'
                className='editIcon'
                onClick={() => {
                  if (onEdit) {
                    onEdit(t, versionList);
                  }
                }}
              />
            ) : null,
            onDelete ? (
              <Popconfirm
                key="delete"
                title="确定删除该权限吗？"
                onConfirm={() => {
                  if (onDelete) {
                    onDelete(t);
                  }
                }}
                okText="确定"
                cancelText="取消"
              >
                <img
                  src={deleteIcon}
                  alt='deleteIcon'
                  className='editIcon'
                />
              </Popconfirm>
            ) : null,

            onAdd ? (
              <img
                src={addIcon}
                alt='addIcon'
                className='smallAddIcon'
                onClick={() => {
                  onAdd && onAdd(t);
                }}
              />
            ) : null,
          ]
          : null}
      </span>
    );
  }, [onSelect, onAdd, onEdit, onDelete]);

  const renderTree = (permissionList: IPermission[], id: string) => {
    const doms: ReactElement[] = [];

    permissionList.forEach((t) => {
      if (t.pPermissionId !== id) return;
      if (!t.permissionKey) return;

      const props: {
        title: string | JSX.Element;
        children?: ReactElement[];
        key: string;
      } = {
        title: renderTitle(
          t,
          list.filter((v) => !v.permissionKey && t.id === v.pPermissionId)
        ),
        key: t.permissionKey ? t.id : `v-${t.id}`,
      };
      // 版本权限id有可能会和接口权限一致，permissionKey===null为版本
      if (
        permissionList.some((l) => l.pPermissionId === t.id && t.permissionKey)
      ) {
        props.children = renderTree(list, t.id);
      }

      doms.push(<TreeNode {...props} />);
    });
    return doms;
  };

  let tree;
  list.forEach((l) =>
    !list.some((p) => p.id === l.pPermissionId) ? (l.pPermissionId = "0") : null
  );
  if (!tree) {
    tree = renderTree(list, "0");
  }

  return (
    <Tree
      switcherIcon={<CaretDownOutlined className='arrowIcon' />}
      selectable={!!select}
      checkedKeys={getCheckedKeys(checkedKeys)}
      showLine
      defaultExpandedKeys={["0"]}
      onCheck={select}
      checkable={checkable}
    // draggable={false}
    // onDrop={onDrop}
    >
      <TreeNode title={renderTitle()} key="0">
        {tree}
      </TreeNode>
    </Tree>
  );
};

export default PermissionTree;
