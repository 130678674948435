import { useState, useCallback } from "react";
import { Tabs } from "antd";
import { companyDetailUrl, companyChargeRecordUrl, companyTimeInfoUrl } from "../../../services/company";
import { CheckPermissionKeyInListHook } from '../../../containers/PermissionComponents';
import CompanyBaseInfo from './CompanyBaseInfo';
import CompanyTimeInfo from './CompanyTimeInfo';
import CompanyChargeRecord from './CompanyChargeRecord';

const { TabPane } = Tabs;

const CompanyDetail = () => {
  const [activeKey, setActiveKey] = useState('baseInfo');
  const canDetail = CheckPermissionKeyInListHook(companyDetailUrl);
  const companyInfo = CheckPermissionKeyInListHook(companyTimeInfoUrl);
  const companyChargeRecord = CheckPermissionKeyInListHook(companyChargeRecordUrl);
  console.log('canDetail', canDetail);

  const onChange = useCallback((activeKey: string) => {
    setActiveKey(activeKey);
  }, []);

  return (
    <div className='normalPage'>
      <Tabs defaultActiveKey="baseInfo" onChange={onChange} type="card">
        {canDetail && <TabPane tab="公司信息" key="baseInfo">
          <CompanyBaseInfo active={activeKey === 'baseInfo'} />
        </TabPane>}
        {companyInfo && <TabPane tab="时长信息" key="timeInfo">
          <CompanyTimeInfo active={activeKey === 'timeInfo'} />
        </TabPane>}
        {companyChargeRecord && <TabPane tab="充值记录" key="chargeRecord">
          <CompanyChargeRecord active={activeKey === 'chargeRecord'} />
        </TabPane>}
      </Tabs>
    </div>
  )
}

export default CompanyDetail;
