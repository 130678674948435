import TitleValueLabel from '../../../../components/TitleLabel'
import { View, Text, } from '../../../../components/reactComponents'
import { getLivingTimeString } from '../../../../util/tool';
import TaskMediaItem from './TaskMediaItem';

import './index.scss'

const TaskLocalInfoItem = ({ log, executeUserId }: { log: ITaskLogItem, executeUserId?: number }) => {
  const { file, username, time, description, size } = log.logDetail.localUpload || {};
  return (<View className='liveInfoItem'>
    <View className='handlerView'>
      <Text className='handlerText'>
        由<Text className='handler'>{username}</Text>开始录制
      </Text>
      <View>
        <Text className='bigText'>共{getLivingTimeString(time || 0)}</Text>
        <View className='liveInfoEndView localInfoItem'>
          <View className='liveInfoEndHeader'>
            <View className='desView'>
              <TitleValueLabel title='录制描述：' value={description || ''} />
            </View>
          </View>
          {file && <View className='mediaView'>
            <TaskMediaItem
              startTime='视频大小'
              url={file}
              downloadUrls={[file]}
              username={username}
              type='video'
              size={size || 0}
              time={time}
            />
          </View>}
        </View>
      </View>
    </View>
  </View>)
}

export default TaskLocalInfoItem;
