import { Table, TableProps } from "antd";

const GTable = ({ pagination, ...others }: TableProps<any>) => {
  return (
    <Table
      pagination={{
        ...pagination,
        showTotal: (total) => <span className='tableTotalItem'>查询结果{total}条</span>
      }}
      {...others}
    />
  )
}

export default GTable;
