import { Space } from "antd";
import GTable from '../../components/GTable';
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import useList from "../../hooks/useList";
import { usersPage } from "../../services/personal";
import { formatDate } from "../../util";
import FilterForm from "./FilterForm";
const Personal = () => {
  const { list, loading, filter, pagination, fetchList } =
    useList<IAppUser>(usersPage);

  const columns = useMemo(() => {
    return [
      {
        title: "昵称",
        dataIndex: "nickname",
      },
      {
        title: "手机号",
        dataIndex: "phone",
      },
      {
        title: "创建时间",
        dataIndex: "createdAt",
        render: (value: string) => (value ? formatDate(value) : "-"),
      },
      {
        title: "操作",
        dataIndex: "opt",
        render: (_: any, record: IAppUser) => {
          return (
            <>
              <Space size="middle" style={{ marginRight: "1vw" }}>
                <Link className='tableDetailButton' to={`/personal/${record.uid}/notask`}>详情</Link>
              </Space>

              {/* <Space size="middle" style={{ marginRight: "1vw" }}>
                <Popconfirm
                  title="确定删除该用户吗?"
                  onConfirm={() => onConfirm(record)}
                  onCancel={() => {}}
                  okText="确定"
                  cancelText="取消"
                >
                  <span style={{ color: "#ff0000" }}>删除</span>
                </Popconfirm>
              </Space> */}
            </>
          );
        },
      },
    ];
  }, []);

  // const onConfirm = useCallback((record: any) => {
  //   deleteUser(record.uid).then((resp) => {
  //     if (isSuccessResponse(resp)) {
  //       handlePageChange(1);
  //     }
  //   });
  // }, []);

  const handlePageChange = useCallback(
    (page: number, size?: number) => {
      fetchList({
        ...filter,
        page: size !== pagination.pageSize ? 1 : page,
        size,
      });
    },
    [fetchList, filter, pagination]
  );

  const handleSearch = useCallback(
    (data: any) => {
      fetchList({ ...data, page: 1, size: pagination.pageSize });
    },
    [pagination.pageSize, fetchList]
  );

  return (
    <div className='normalPage'>
      <div>
        <h3>个人用户列表</h3>
      </div>
      <FilterForm onSearch={handleSearch} />
      <GTable
        rowKey="uid"
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange,
        }}
      />
    </div>
  );
};

export default Personal;
