import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Spin } from "antd";
import HeaderTitle from '../../../components/HeaderTitle';
import TitleLabel from '../../../components/TitleLabel';
import { TaskStatusView } from "../TaskList";
import { fetchTaskDetail } from "../../../services/task";
import { formatDate, isSuccessResponse, DATETIME_NO_SECOND_FORMAT } from "../../../util";
import AccessoryList from './AccessoryList';

interface IParams {
  id: string;
}

const infoList = [
  { title: '任务标题', dataIndex: 'title' },
  { title: '任务描述', dataIndex: 'content' },
  { title: '期望完成时间', dataIndex: 'expectedFinishTime', render: (value: string, data: ITask) => value ? formatDate(value, DATETIME_NO_SECOND_FORMAT) : '-' },
  { title: '发起人', dataIndex: 'createUserNickname', render: (value: string, data: ITask) => <span>{value || data.createUsername}</span>, },
  { title: '执行人', dataIndex: 'executeUserNickname', render: (value: string, data: ITask) => <span>{value || data.executeUsername}</span>, },
  { title: '附件', dataIndex: 'extendVOS', render: (value: IExtendVOSItem[]) => <AccessoryList list={value} /> },
];

const TaskInfo = () => {

  const params = useParams<IParams>();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ITask | null>(null);

  useEffect(() => {
    setLoading(true);
    fetchTaskDetail(params.id).then((resp) => {
      if (isSuccessResponse(resp)) {
        setData(resp.data)
      }
    }).finally(() => setLoading(false));
  }, [params.id]);

  const tmpData: any = data as any;

  return (
    <Spin spinning={loading}>
      <HeaderTitle title={<span>
        <span>任务基本信息</span>
        <span style={{ marginLeft: 20 }}>{data && <TaskStatusView taskStatus={data.taskStatus} />}</span>
      </span>} />
      {
        data && (<div style={{ padding: '30px 20px' }}>
          {infoList.map(i => {
            const { dataIndex, title } = i;
            const value = tmpData[dataIndex];
            return <TitleLabel
              style={{ marginBottom: 10 }}
              title={title + '：'}
              key={dataIndex}
              value={i.render ? i.render(value, data) : value}
            />
          })}
        </div>)
      }
    </Spin>
  )
}

export default TaskInfo;
