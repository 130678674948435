import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Avatar, Modal, Form, message } from "antd";
import { useCallback, useState } from "react";
import FormModal from "../../components/FormModal";
import PasswordInput from '../../components/Input/PasswordInput';
import { useAuth } from "../../models/auth";
import { useUser } from "../../models/user";
import { resetPassword } from '../../services/systemUser';
import { isSuccessResponse, } from "../../util";
import avatarIcon from '../../assets/role_corporate_avatar2.png';
import { setTimeout } from "timers";

const UserInfo = () => {

  const { signout } = useAuth();
  const { user } = useUser();
  const [show, setShow] = useState(false);

  const confirmLogout = useCallback(() => {
    Modal.confirm({
      title: '确定要退出登录吗？',
      onOk: async () => {
        setTimeout(() => {
          signout();
        }, 200);
        return true;
      }
    })
  }, [signout]);

  const handleOk = useCallback(async ({ newPassword, oldPassword }: any) => {
    const res = await resetPassword({ newPassword, oldPassword });
    if (isSuccessResponse(res)) {
      message.success('修改密码成功');
    }
    setShow(false);
    return res;
  }, []);

  const handleMenu = useCallback(({ key }) => {
    if (key === 'signout') {
      confirmLogout();
    } else if (key === 'resetPassword') {
      setShow(true);
    }
  }, [confirmLogout]);

  return user && (
    <div style={{ display: 'inline-block' }}>
      <Dropdown
        overlay={(
          <Menu onClick={handleMenu}>
            <Menu.Item key="resetPassword">修改密码</Menu.Item>
            <Menu.Item key="signout">退出登录</Menu.Item>
          </Menu>
        )}
      >
        <div
          className="ant-dropdown-link"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {/* <UserOutlined /> */}
          <Avatar src={avatarIcon} />
          <span style={{ margin: '0 5px' }}>
            {user.nickName || user.username}
          </span>
          <DownOutlined />
        </div>
      </Dropdown>
      <FormModal
        title="修改密码"
        visible={show}
        onCancel={() => { setShow(false) }}
        onOk={handleOk}
      >
        <Form.Item
          key='oldPassword'
          name='oldPassword'
          label='旧密码'
          rules={[{ required: true, message: '请输入旧密码' }]}
        >
          <PasswordInput />
        </Form.Item>
        <Form.Item
          key='newPassword'
          name='newPassword'
          label='新密码'
          rules={[{ required: true, message: '请输入新密码' }]}
        >
          <PasswordInput />
        </Form.Item>
      </FormModal>
    </div>
  )
}

export default UserInfo;