import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import zhCN from 'antd/lib/locale/zh_CN';

import { AuthProvider } from "./models/auth";
import PrivateRoute from "./containers/PrivateRouter";
import Login from "./pages/Login";
// import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Compose from "./containers/Compose";
import { ConfigProvider } from "antd";


export default function App() {
  return (
    <Compose
      components={[AuthProvider]}
    >
      <ConfigProvider locale={zhCN}>
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            {/* <Route path="/register">
              <Register />
            </Route> */}
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </ConfigProvider>
    </Compose>
  );
}
