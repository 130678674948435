import { Button, Form, Input, Select, Space } from "antd";
import { FC, useCallback } from "react";
import { DATETIME_FORMAT } from "../../util";
import DatePicker from "../../components/DatePicker";
import { taskStatusOptions } from "../../constant";

const { RangePicker } = DatePicker;

interface IProps {
  onSearch: (data: any) => void;
}

const FilterForm: FC<IProps> = ({ onSearch }) => {
  const [form] = Form.useForm();

  const handleSearch = useCallback(() => {
    form.validateFields().then(({ key, value, times, taskStatus }) => {
      const data: any = {};
      if (value) {
        data[key] = value;
      }
      if (taskStatus) {
        data.taskStatus = taskStatus;
      }
      console.log('times', times)
      if (times && times.length > 0) {
        data.timeType = 1;
        data.startTime = times[0].format(DATETIME_FORMAT);
        data.endTime = times[1].format(DATETIME_FORMAT);
      }
      onSearch(data)
    })
  }, [form, onSearch]);

  const handleReset = useCallback(() => {
    form.resetFields();
    onSearch({});
  }, [form, onSearch]);
  return (
    <Form
      form={form}
      layout="inline"
      style={{ marginBottom: 20, marginTop: 30 }}
    >
      <Form.Item>
        <Input.Group compact>
          <Form.Item
            name="key"
            noStyle
            initialValue="executeUserNickname"
          >
            <Select>
              <Select.Option value="executeUserNickname">执行人</Select.Option>
              <Select.Option value="createUserNickname">发起人</Select.Option>
              <Select.Option value="title">标题</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="value"
            noStyle
            initialValue=""
          >
            <Input style={{ width: '67%' }} placeholder="请输入关键字" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label="状态" name="taskStatus" initialValue="">
        <Select style={{ width: 90 }}>
          <Select.Option value="">全部</Select.Option>
          {
            taskStatusOptions.map((o) => (
              <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
            ))
          }
        </Select>
      </Form.Item>
      <Form.Item name="times" label="创建时间">
        <RangePicker
          showTime
          format={DATETIME_FORMAT}
          placeholder={['开始时间', '结束时间']}
        />
      </Form.Item>
      <Form.Item>
        <Space size="small">
          <Button type="primary" onClick={handleSearch}>搜索</Button>
          <Button onClick={handleReset}>重置</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default FilterForm;
