import { FC, useCallback } from "react";
import { Button, Form, Input, Select, Space } from "antd";

interface IProps {
  onSearch: (data: any) => void;
}

const FilterForm: FC<IProps> = ({ onSearch }) => {
  const [form] = Form.useForm();

  const handleSearch = useCallback(() => {
    form.validateFields().then(({ key, value, activated }) => {
      const data: any = {};
      if (value) {
        data[key] = value;
      }
      if (activated !== '') {
        data.activated = activated;
      }
      onSearch(data)
    })
  }, [form, onSearch]);

  const handleReset = useCallback(() => {
    form.resetFields();
    onSearch({});
  }, [form, onSearch]);
  return (
    <Form
      form={form}
      layout="inline"
      style={{ marginTop: 20, marginBottom: 10, paddingLeft: 20 }}
    >
      <Form.Item>
        <Input.Group compact>
          <Form.Item
            name="key"
            noStyle
            initialValue="nickname"
          >
            <Select>
              <Select.Option value="nickname">昵称</Select.Option>
              <Select.Option value="phone">手机号</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="value"
            noStyle
            initialValue=""
          >
            <Input style={{ width: '67%' }} placeholder="请输入关键字" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      {/* <Form.Item label="是否已激活" name="activated" initialValue="">
        <Select style={{ width: 80 }}>
          <Select.Option value="">全部</Select.Option>
          <Select.Option value={1}>是</Select.Option>
          <Select.Option value={0}>否</Select.Option>
        </Select>
      </Form.Item> */}
      <Form.Item>
        <Space size="small">
          <Button type="primary" onClick={handleSearch}>搜索</Button>
          <Button onClick={handleReset}>重置</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default FilterForm;
