import request from "../util/request";

const taskPageUrl = '/glive/web/tasks/page';
const taskCreateUrl = '/glive/web/tasks/create';
const editAndDeleteTaskUrl = '/glive/web/tasks';
const taskDetailUrl = '/glive/web/tasks/{taskId}';
const taskLogUrl = '/glive/web/tasks/{taskId}/logs';

const uploadUrl = '/tools/web/upload_file';
const singleUploadUrl = '/tools/single_upload';



interface IUploadFileData {
  contentLength: number;
  createdAt: string;
  sourceUrl: string;
  succeed: boolean;
}

export {
  taskPageUrl,
  taskCreateUrl,
  editAndDeleteTaskUrl,
  taskDetailUrl,
  taskLogUrl,
}

export function fetchTaskList(params: IPage) {
  return request<IResponse<IPagination<ITask>> | IErrorResponse>(taskPageUrl, {
    method: 'GET',
    params,
  })
}

export function createTask(data: ITask) {
  return request<IResponse<{}> | IErrorResponse>(taskCreateUrl, {
    method: 'POST',
    data,
  })
}

export function editTask(data: ITask) {
  return request<IResponse<{}> | IErrorResponse>(editAndDeleteTaskUrl, {
    method: 'PUT',
    data,
  })
}

export function batchDeleteTask(taskIds: any[]) {
  return request<IResponse<{}> | IErrorResponse>(editAndDeleteTaskUrl, {
    method: 'DELETE',
    data: { taskIds },
  })
}

export function fetchTaskDetail(taskId: string | number) {
  const url = taskDetailUrl.replace('{taskId}', `${taskId}`);
  return request<IResponse<ITask> | IErrorResponse>(url, {
    method: 'GET',
  })
}

export function fetchTaskLogs(taskId: string | number) {
  const url = taskLogUrl.replace('{taskId}', `${taskId}`);
  return request<IResponse<ITaskLogItem[]> | IErrorResponse>(url, {
    method: 'GET',
  })
}

export function uploadFile({ file }: { file: File }) {
  const data = new FormData();
  data.append('file', file, file.name);
  return request<IResponse<IUploadFileData>>(uploadUrl, {
    method: 'POST',
    data,
  });
}

export function singleUploadFile({ file }: { file: File }) {
  const data = new FormData();
  data.append('file', file, file.name);
  return request<IResponse<IUploadFileData>>(singleUploadUrl, {
    method: 'POST',
    data,
  });
}

