
import request from "../util/request";

const userPanelUrl = '/glive/statistics/user/panel';
const userTrendUrl = '/glive/statistics/user/trend'

const cameraPanelUrl = '/glive/statistics/camera/panel'
const cameraTrendUrl = '/glive/statistics/camera/trend'

export {
    userPanelUrl,
    userTrendUrl,
    cameraPanelUrl,
    cameraTrendUrl
}

interface ITrendParams {
  endTime?:string
  startTime?:string
}

export function userPanel() {
  return request<IResponse<IUserPanel> | IErrorResponse>(userPanelUrl, {
    method: 'GET',
  })
}

export function userTrend(data?:ITrendParams) {
  return request<IResponse<IUserTrend[]> | IErrorResponse>(userTrendUrl, {
    method: 'POST',
    data
  })
}

export function cameraPanel() {
  return request<IResponse<ICameraPanel> | IErrorResponse>(cameraPanelUrl, {
    method: 'GET',
  })
}

export function cameraTrend(data?:ITrendParams) {
  return request<IResponse<ICameraTrend[]> | IErrorResponse>(cameraTrendUrl, {
    method: 'POST',
    data
  })
}
