import { useEffect, useState, useRef, useCallback } from "react";
import { Descriptions, Spin } from "antd";
import { isSuccessResponse, formatDate } from "../../../util";

const infoList = [
  {
    title: '公司Logo',
    dataIndex: 'sysLogo',
    render: (value: string) => value ? <img src={value} style={{ width: '5rem', height: '5rem' }} alt="logo" /> : '',
  },
  {
    title: '公司名称',
    dataIndex: 'enterpriseName'
  },
  {
    title: '系统用户名',
    dataIndex: 'username',
  },
  {
    title: '创建时间',
    dataIndex: 'ctime',
    render: (value: string) => value ? formatDate(value) : '-',
  },
]

const CompanyBaseInfo = ({ baseInfoFun, active }: { baseInfoFun: () => Promise<any>, active: boolean }) => {
  const activeRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ICompany | null>(null);

  const queryInfo = useCallback(() => {
    setLoading(true);
    baseInfoFun().then((resp) => {
      if (isSuccessResponse(resp)) {
        console.log(resp.data);
        setData(resp.data)
      }
    }).finally(() => setLoading(false));
  }, [baseInfoFun]);

  // useEffect(() => {
  //   setLoading(true);
  //   baseInfoFun().then((resp) => {
  //     if (isSuccessResponse(resp)) {
  //       console.log(resp.data);
  //       setData(resp.data)
  //     }
  //   }).finally(() => setLoading(false));
  // }, [baseInfoFun]);

  useEffect(() => {
    console.log('useEffect', activeRef.current, active)
    if (activeRef.current !== active) {
      activeRef.current = active;
      if (activeRef.current) {
        queryInfo();
      }
    }
  }, [queryInfo, active]);


  return (
    <div className='normalPage'>
      <Spin spinning={loading}>
        {
          data && (
            <Descriptions title="公司基本信息">
              {infoList.map((item: any) => {
                const { title, dataIndex, render } = item;
                const info: any = data as any;
                const value = info[dataIndex];
                return (
                  <Descriptions.Item label={title} key={dataIndex}>
                    {render ? render(value, data) : value}
                  </Descriptions.Item>
                )
              })}
            </Descriptions>
          )
        }
      </Spin>
    </div>
  )
}

export default CompanyBaseInfo;
