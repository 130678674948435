import { useCallback, useMemo, useState, useEffect, useRef } from "react";
import { Button, Space } from "antd";
import { Link } from "react-router-dom";
import {
  batchDeleteTask,
  fetchTaskList,
  editAndDeleteTaskUrl,
  taskCreateUrl,
  taskDetailUrl,
} from "../../services/task";
import GTable from '../../components/GTable';
import { formatDate } from "../../util";
import { taskStatusMap } from "../../constant";
import useList from "../../hooks/useList";
import TaskModal from "./TaskModal";
import FilterForm from "./FilterForm";
import { CheckPermissionKeyInListHook } from '../../containers/PermissionComponents';

import './index.scss';

interface IProps {
  appUser?: IAppUser | null;
  active?: boolean;
}

export const TaskStatusView = ({ taskStatus }: { taskStatus: TaskStatus }) => {
  const title = taskStatusMap[taskStatus] || taskStatus;
  let className;
  switch (taskStatus) {
    case 'INITIALIZED':
      className = 'taskStatusInitView';
      break;
    case 'RUNNING':
      className = 'taskStatusRunningView';
      break;
    case 'FINISH':
      className = 'taskStatusFinishView';
      break;
    default:
      className = 'taskStatusInitView';
      break;
  }
  return <span className={className}>
    {title}
  </span>
}

const Tasks = ({ appUser, active }: IProps) => {
  // const isMountRef = useRef(false);
  const activeRef = useRef(active);

  const {
    list,
    loading,
    filter,
    pagination,
    rowSelection,
    fetchList,
    handleDelete
  } = useList<ITask>((p: any) => fetchTaskList({ ...p, ...appUser ? { executeUserId: appUser.uid } : {} }),
    {
      batchDeleteFn: batchDeleteTask
    });
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState<ITask | null>(null);

  const handleEdit = useCallback((data: ITask) => {
    setEditData(data);
    setVisible(true);
  }, []);

  const rowSelectionAndDisabled = useMemo(
    () => ({
      ...rowSelection,
      getCheckboxProps: (record: ITask) => ({
        disabled: record.taskStatus !== 'INITIALIZED'
      })
    }),
    [rowSelection]
  );


  const canCreate = CheckPermissionKeyInListHook(taskCreateUrl);
  const canEditAndDeleteTask = CheckPermissionKeyInListHook(editAndDeleteTaskUrl);
  const canDetail = CheckPermissionKeyInListHook(taskDetailUrl);

  const columns = useMemo(() => {
    const list: { title: string, dataIndex: string, render?: any }[] = [{
      title: '标题',
      dataIndex: 'title',
      render: (value: string) => <span className='taskTitle'>{value}</span>,
    },
    {
      title: '发起人',
      dataIndex: 'createUserNickname',
      render: (value: string, data: ITask) => <span>{value || data.createUsername}</span>,
    },
    {
      title: '执行人',
      dataIndex: 'executeUserNickname',
      render: (value: string, data: ITask) => <span>{value || data.executeUsername}</span>,
    },
    {
      title: '开始时间',
      dataIndex: 'createdAt',
      render: (value: string) => value ? formatDate(value) : '-',
    },
    {
      title: '完成时间',
      dataIndex: 'finishTime',
      render: (value: string) => value ? formatDate(value) : '-',
    },
    {
      title: '状态',
      dataIndex: 'taskStatus',
      render: (value: TaskStatus) => <TaskStatusView taskStatus={value} />,
    },];
    if (canEditAndDeleteTask || canDetail) {
      list.push({
        title: '操作',
        dataIndex: 'opt',
        render: (_: any, record: ITask) => (
          <Space size="middle">
            {(canEditAndDeleteTask && record.taskStatus === 'INITIALIZED') && <span className='tableEditButton' onClick={() => handleEdit(record)}>编辑</span>}
            {canDetail && <Link className='tableDetailButton' to={`/tasks/${record.id}`}>详情</Link>}
          </Space>
        ),
      });
    }
    return list;
  }, [handleEdit, canEditAndDeleteTask, canDetail]);

  const handlePageChange = useCallback((page: number, pageSize?: number) => {
    fetchList({ ...filter, page: pageSize !== pagination.pageSize ? 1 : page, size: pageSize })
  }, [fetchList, filter, pagination]);

  const handleSearch = useCallback((data: any) => {
    fetchList({ ...data, page: 1, size: pagination.pageSize })
  }, [fetchList, pagination.pageSize]);

  const handleAdd = useCallback(() => {
    setEditData(null);
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setEditData(null);
    setVisible(false);
  }, [])

  const handleOk = useCallback(() => {
    setVisible(false);
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    // if (isMountRef.current) {
    if (activeRef.current !== active) {
      activeRef.current = active;
      if (activeRef.current) {
        handlePageChange(1);
      }
    }
    // }
    // isMountRef.current = true;
  }, [active, handlePageChange]);

  const noUser = !appUser;

  return (
    <div className={`normalPage taskList ${noUser ? '' : 'noPadding'}`}>
      {noUser && <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <h3>任务列表</h3>
        <Space size="small">
          {canCreate && <Button type="primary" onClick={handleAdd}>新增任务</Button>}
          {canEditAndDeleteTask && <Button type="primary" danger onClick={handleDelete}>批量删除</Button>}
        </Space>
      </div>}
      {noUser && <FilterForm onSearch={handleSearch} />}
      <GTable
        rowKey="id"
        columns={columns}
        dataSource={list}
        loading={loading}
        rowSelection={noUser ? rowSelectionAndDisabled : undefined}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange
        }}
      />
      {visible && <TaskModal
        visible={visible}
        task={editData}
        onCancel={handleClose}
        onOk={handleOk}
        appUser={appUser}
      />}
    </div>
  )
}

export default Tasks;
