import TaskInfo from "./info";
import TaskLogs from "./logs";
import {
  taskLogUrl,
  taskDetailUrl,
} from "../../../services/task";
import { CheckPermissionKeyInListHook } from '../../../containers/PermissionComponents';


const TaskDetail = () => {
  const canDetail = CheckPermissionKeyInListHook(taskDetailUrl);
  const canLog = CheckPermissionKeyInListHook(taskLogUrl);
  return (
    <div className='normalPage'>
      {canDetail && <div>
        <TaskInfo />
      </div>}
      {canLog && <div>
        <TaskLogs />
      </div>}
    </div>
    // <Tabs defaultActiveKey="info" type='card'>
    //   {canDetail && <TabPane tab="基本信息" key="info">
    //     <TaskInfo />
    //   </TabPane>}
    //   {canLog && <TabPane tab="任务日志" key="logs">
    //     <TaskLogs />
    //   </TabPane>}
    // </Tabs>
  )
}

export default TaskDetail;
