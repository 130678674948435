import { memo } from "react";
import { Layout } from "antd";
// import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useState } from "react";
import { UserProvider } from "../../models/user";
import { DepartmentProvider } from "../../models/department";
import { CompanyProvider } from '../../models/companyList';
import { TrendProvider } from "../../models/trend";
import Compose from "../../containers/Compose";
import UserInfo from "./UserInfo";
import DashboardMenu from "./Menu";
import DashboardRoutes from "./Routes";
import SysInfo from "./SysInfo";

import "./index.scss";
// import { useLocation } from "react-router-dom";

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  const [collapsed] = useState(false);

  // const location = useLocation();

  // const handleToggle = useCallback(() => {
  //   setCollapsed(!collapsed);
  // }, [collapsed]);
  // const bgcolor = location.pathname.includes("statistics");
  return (
    <Compose components={[UserProvider, DepartmentProvider, TrendProvider, CompanyProvider]}>
      <Layout className='siteLayout'>
        <Sider
          className='content'
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ maxWidth: 'none', minWidth: 'none', flex: 1, width: 'auto' }}
        >
          <SysInfo collapsed={collapsed} />
          <DashboardMenu />
        </Sider>
        <Layout>
          <Header
            className='siteLayoutBackground'
            style={{
              padding: 0,
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 20,
            }}
          >
            {/* {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'trigger',
                onClick: handleToggle,
              }
            )} */}
            <div />
            <UserInfo />
          </Header>
          <Content
            className='siteLayoutBackground'
            style={{
              margin: "24px 16px",
              minHeight: 280,
              height: "100%",
              overflow: "auto",
              backgroundColor: '#f0f2f5',
            }}
          >
            <DashboardRoutes />
          </Content>
        </Layout>
      </Layout>
    </Compose>
  );
};

export default memo(Dashboard);
