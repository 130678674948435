import { FC, useCallback } from "react"
import { Form, Input, InputNumber, message } from "antd"
import { isSuccessResponse } from "../../util";
import { createLicense, LicensesTypeEnum, LicensesResolutionEnum } from "../../services/license";
import FormModal from "../../components/FormModal";


interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  showStore?: boolean;
}

const LicenseModal: FC<IProps> = ({ visible, showStore, onCancel, onOk }) => {

  const handleOk = useCallback(async (values: any) => {
    const { cloudStorage } = values;
    console.log('cloudStorage', cloudStorage);
    return createLicense({
      ...values,
      type: LicensesTypeEnum.live,
      resolution: LicensesResolutionEnum.hd,
      cloudStorage: cloudStorage ? cloudStorage * 1024 * 1024 : 0,
    }).then((resp) => {
      if (isSuccessResponse(resp)) {
        onOk()
      } else if (!resp.message) {
        message.error('生成失败');
      }
    })
  }, [onOk]);

  return (
    <FormModal
      title="生成授权码"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form.Item
        name="batchNum"
        label="批次号"
        rules={[{ required: true, message: '批次号必填' }]}
      >
        <Input maxLength={20} />
      </Form.Item>
      <Form.Item
        name="availableMinutes"
        label="可用分钟数"
        rules={[{ required: true, message: '可用分钟数必填' }]}
      >
        <InputNumber />
      </Form.Item>
      {showStore && <Form.Item
        name="cloudStorage"
        label="可用存储量"
        rules={[{ required: false, message: '可用存储量选填' }]}
      >
        <InputNumber />
      </Form.Item>}
      <Form.Item
        name="num"
        label="数量"
        rules={[{ required: true, message: '数量必填' }]}
      >
        <InputNumber min={1} max={500} />
      </Form.Item>
      <div style={{ position: 'relative', top: '-6.7rem', left: '13.5rem' }}><span>G</span></div>
    </FormModal>
  )
}

export default LicenseModal;
