import { FC, useCallback, useState, useMemo } from "react";
import { message, Modal, Upload } from 'antd';
import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { isSuccessResponse } from "../../util";
import { getHTTPSUrl } from '../../util/tool';
import {
  downloadTemplate,
  importTemplate,
} from "../../services/appUser";

import './index.scss';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: ({ success }: { success: boolean }) => Promise<void>;
}


const ImportUserModal: FC<IProps> = ({ visible, onCancel, onOk }) => {

  const [file, setFile] = useState<any>();

  const onDownload = (url: string, fileName: string) => {
    let x = new XMLHttpRequest();
    x.open("GET", url, true);
    x.responseType = "blob";
    x.onload = function () {
      console.log('x.response', x.response);
      let url = window.URL.createObjectURL(x.response);
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    };
    x.send();
  };

  const onDownloadTemplate = useCallback((e) => {
    e.stopPropagation();
    downloadTemplate().then((resp) => {
      if (isSuccessResponse(resp)) {
        const { url, fileName } = resp.data;
        onDownload(getHTTPSUrl(url), fileName);
      }
    });
  }, []);

  const onChangeFile = useCallback(({ fileList }) => {
    console.log('fileList', fileList);
    setFile(fileList[0]);
  }, [])

  const handleOk = useCallback(async () => {
    if (file) {
      const res = await importTemplate({ file: file.originFileObj, resultFileName: '未导入的用户列表' })
      if (isSuccessResponse(res)) {
        message.success('导入成功');
        onOk({ success: true });
        return true;
      }
    } else {
      message.error('未选择导入文件');
    }
    setFile(undefined);
    onOk({ success: false });
    return false;
  }, [onOk, file]);

  const emptyView = useMemo(() => {
    return <>
      <PlusOutlined className='fileIcon' />
      <div className='fileName'>点击或者拖拽文件到该区域</div>
      <div className='tip'>支持文件格式：.xlsx 和 .xls。
        <span className='link' onClick={onDownloadTemplate}>点击下载导入模板</span>
      </div>
    </>
  }, [onDownloadTemplate]);

  return (
    <Modal
      title="批量导入"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <div className='importUserModal'>
        <Upload
          accept=".xls,.xlsx"
          beforeUpload={() => false}
          onChange={onChangeFile}
          maxCount={1}
          showUploadList={false}
          fileList={file ? [file] : []}
          className='importUserModalUpload'
        >
          <div className='borderView'>
            {file ? <>
              <FileExcelOutlined className='fileIcon' />
              <div className='fileName'>{file.name}</div>
            </> : emptyView}
          </div>
        </Upload>
      </div>
    </Modal>
  )
}

export default ImportUserModal;
