import { memo } from 'react';
import { useUser } from "../../models/user";
import './index.scss';


const SysInfo = ({ collapsed }: { collapsed: boolean }) => {
  const { user, company } = useUser();
  const logo = user && user.sysLogo ? user.sysLogo : null;
  const sysName = company ? company.sysName : process.env.REACT_APP_TITLE;

  return (
    <div className='sysInfoView'>
      <div className='logoDiv'>
        {logo && <img src={logo} className='logoImage' alt="Logo" />}
        <span className='sysName'>{collapsed ? '' : sysName}</span>
      </div>
    </div>
  )
};

export default memo(SysInfo);
