import { Area } from "@ant-design/charts";
import { Button } from "antd";
import { useCallback } from "react";

import HeaderTitle from '../../components/HeaderTitle';

import "./trend.scss";

interface IProps {
  data: any;
  title: string;
  id: number;
  onActive: (timeType: number) => void;
  value: number;
}

interface IOptionProps {
  label: string;
  value: string | number;
}

const selectTypeList: IOptionProps[] = [{ label: '最近7天', value: 7 }, { label: '最近30天', value: 30 }];

const RadioGroup = ({ options, onChange, value }: {
  options: IOptionProps[], onChange: (v: IOptionProps) => void, value: string | number
}) => {

  const buttonClick = useCallback((item: IOptionProps) => {
    onChange(item);
  }, [onChange])

  return (
    <div>
      {options.map((i: IOptionProps) => {
        const { label, value: iValue } = i;
        const isSelected = iValue === value;
        return <Button type={isSelected ? 'primary' : 'default'} style={{ marginLeft: 15 }} onClick={() => buttonClick(i)}>
          {label}
        </Button>
      })}
    </div>
  );
}

const Trend = (props: IProps) => {
  const { data, title, onActive, value = 7 } = props;

  const config = {
    data,
    height: 280,
    xField: "time",
    yField: "value",
    line: {
      color: '#fbc840'
    },
    point: {
      size: 5,
      shape: "point",
      color: '#fbc840'
    },
    theme: {
      defaultColor: '#fbc840'
    },
    grid: {
      alignTick: true,
    },
    areaStyle: function areaStyle() {
      return { fill: 'l(270) 0:#ffffff 0.5:#fffeba 1:#fff5d7' };
    },
  };

  const onChange = useCallback((e) => {
    onActive(e.value)
  }, [onActive])

  return (
    <div className='trendCard'>
      <div className="content">
        <HeaderTitle title={title} />
        <div>
          <RadioGroup
            options={selectTypeList}
            onChange={onChange}
            value={value}
          />
        </div>
      </div>
      <Area {...config} />
    </div>
  );
};

export default Trend;
