import { useCallback, useMemo, useEffect, useRef } from "react";
import Table from '../../../../components/GTable';
import {
  liveInfoDataList,
  storeInfoDataList,
} from '../CompanyTimeInfo';
import { companyChargeRecord, IChargeRecord } from "../../../../services/company";
import { formatDate } from "../../../../util";
import useList from "../../../../hooks/useList";
// import FilterForm from "./FilterForm";

const columnsList: { title: string; dataIndex: string; render?: any }[] = [
  {
    title: '充值时间',
    dataIndex: 'createdAt',
    render: (value: string) => value ? formatDate(value) : '-',
  }
];

// const list = [...liveInfoDataList, ...recordInfoDataList, ...storeInfoDataList];
const list = [liveInfoDataList[0], storeInfoDataList[0]];

list.forEach((i) => {
  const { title, chargeKey, unitCount, unit } = i;
  columnsList.push({
    title,
    dataIndex: chargeKey,
    render: (number: number) => number > 0 ? `${Math.floor(number / unitCount)}${unit}` : '-',
  });
});

const CompanyChargeRecordTable = ({ active, companyId }: { active?: boolean, companyId?: string }) => {
  const activeRef = useRef(active);
  const queryRecord = useCallback(async (p: any) => {
    return companyChargeRecord({ ...p, companyId })
  }, [companyId]);

  const {
    list,
    loading,
    filter,
    pagination,
    fetchList,
  } = useList<IChargeRecord>(queryRecord);

  const columns = useMemo(() => {
    return columnsList;
  }, []);

  const handlePageChange = useCallback((page: number, pageSize?: number) => {
    fetchList({ ...filter, page: pageSize !== pagination.pageSize ? 1 : page, size: pageSize });
  }, [fetchList, filter, pagination]);

  // const handleSearch = useCallback((data: any) => {
  //   fetchList({ ...data, page: 1, size: pagination.pageSize });
  // }, [fetchList, pagination.pageSize]);

  useEffect(() => {
    if (activeRef.current !== active) {
      activeRef.current = active;
      if (activeRef.current) {
        handlePageChange(1);
      }
    }
  }, [active, handlePageChange]);

  return (
    <div>
      {!companyId && <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <h3>充值记录</h3>
      </div>}
      {/* <HeaderTitle title='充值记录' /> */}
      {/* <FilterForm onSearch={handleSearch} /> */}
      <Table
        rowKey="createdAt"
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange
        }}
      />
    </div>
  )
}

export default CompanyChargeRecordTable;
