import { useCallback, useMemo, useEffect, useRef } from "react";
import { Table } from "antd";
import { useParams } from "react-router";
import { fetchUseRecordList } from "../../../../services/appUser";
import { formatDate } from "../../../../util";
import { IParams } from '../Detail';
import useList from "../../../../hooks/useList";

const columnsList = [
  {
    title: '开始时间',
    dataIndex: 'createdAt',
    render: (value: string) => value ? formatDate(value) : '-',
  },
  {
    title: '结束时间',
    dataIndex: 'updatedAt',
    render: (value: string) => value ? formatDate(value) : '-',
  },
  {
    title: '使用时长',
    dataIndex: 'usedTime',
    render: (value: number) => {
      const minute = parseInt(`${value / 60}`);
      const seconds = value - minute * 60;
      const minuteStr = `${minute}分钟`;
      return `${minute > 0 ? minuteStr : ''}${seconds}秒`
    },
  },
];

const UseRecordList = ({ active }: { active: boolean }) => {
  const activeRef = useRef(active);
  const params = useParams<IParams>();
  const {
    list,
    loading,
    filter,
    pagination,
    fetchList,
  } = useList<IUseRecord>((p: any) => fetchUseRecordList({ ...p, uid: params.uid }));

  const columns = useMemo(() => {
    return columnsList;
  }, []);

  const handlePageChange = useCallback((page: number, pageSize?: number) => {
    fetchList({ ...filter, page: pageSize !== pagination.pageSize ? 1 : page, size: pageSize })
  }, [fetchList, filter, pagination]);

  useEffect(() => {
    if (activeRef.current !== active) {
      activeRef.current= active;
      if(activeRef.current){
        handlePageChange(1);
      }
    }
  }, [active, handlePageChange]);

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange
        }}
      />
    </div>
  )
}

export default UseRecordList;
