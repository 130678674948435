
const dayjs = require("dayjs");

const sizeUintArray = ['K', 'M', 'G', 'T'];

const noSecondTimeFormat = 'YYYY-MM-DD HH:mm';

// eslint-disable-next-line no-shadow
export enum LiveStatusEnum {
  living = 'LIVING',  // 直播中
  order = 'ORDER',  // 已预约
  finish = 'FINISH',  // 已结束
  cancel = 'CANCEL',  // 取消预约
}

// eslint-disable-next-line no-shadow
export enum RemarkTypeEnum {
  text = 'TEXT',  // 文字
  picture = 'PICTURE',  // 图片
  video = 'VIDEO',  // 视频
}


// eslint-disable-next-line no-shadow
export enum TaskStatusEnum {
  init = 'INITIALIZED',  // 待处理
  running = 'RUNNING',  // 进行中
  finish = 'FINISH',  //已完成
}

// eslint-disable-next-line no-shadow
export enum TaskLogTypeEnum {
  create = 'CREATE',
  update = 'UPDATE',
  order = 'ORDER',
  living = 'LIVING',
  upload = 'UPLOAD',
  delete = 'DELETE',
  cancel = 'CANCEL',
  finish = 'FINISH',
}


export function isCollector(type: string) {
  return type === 'COLLECTOR';
}

export function isSysAdmin(user: IUser) {
  if (user.adminType === 0) {
    return true;
  }
  return false;
}

export function isCompanyAdmin(user: IUser) {
  if (user.adminType === 2) {
    return true;
  }
  return false;
}

export function isUserFreeze(status: string) {
  return status !== 'NORMAL';
}

function getDistanceTime(second: number) {
  let resetSecond = second;
  let minute = Math.floor(second / 60);
  resetSecond = second - minute * 60;
  let hour = 0;
  let day = 0;
  if (minute >= 60) {
    hour = Math.floor(minute / 60);
    minute = minute - hour * 60;
    if (hour >= 24) {
      day = Math.floor(hour / 24);
      hour = hour - day * 24;
    }
  }
  return [day, hour, minute, resetSecond];
}

const displayUnitList = ['天', '小时', '分钟', '秒'];

// 数组分别是天，时，分，秒
export function getDistanceTimeDisplayString(seconds: number) {
  const disTimes = getDistanceTime(seconds);
  // const displayUnit = 2;
  let startIndex = disTimes.findIndex(i => !!i && i > 0);
  const endIndex = disTimes.length;
  if (startIndex < 0) {
    startIndex = endIndex - 1;
  }
  // Math.min(disTimes.length - 1, startIndex + displayUnit);
  console.log('startIndex', startIndex, endIndex)
  let str = '';
  for (let i = startIndex; i < endIndex; i++) {
    str += `${disTimes[i]}${displayUnitList[i]}`
  }
  return str;
}


export function getLivingTimeString(seconds: number) {
  return getDistanceTimeDisplayString(seconds);
  // if (seconds >= 60) {
  //   return getDistanceTimeDisplayString(seconds);
  // } else {
  //   return '1分钟'
  // }
}

// 资产使用
export function minuteTimeString(seconds: number) {
  const minutes = Math.ceil(seconds / 60);
  return `${minutes}分钟`
}

export function getCloudStorageDisplayString(cloudStorage: number | undefined | null) {
  return cloudStorage ? `${(Math.ceil(cloudStorage / (1024 * 1024)))}G` : '-';
}

export function getFileSize(size: number) {
  let title = "";
  if (size) {
    let fileSize = size;
    for (let index = 0; index < sizeUintArray.length; index++) {
      const element = sizeUintArray[index];
      if (fileSize < 1024) {
        title = fileSize.toFixed(2) + element;
        break;
      }
      fileSize = fileSize / 1024;
    }
  }
  return title;
}

export function noSecondTimeString(time?: string) {
  return time ? dayjs(time).format(noSecondTimeFormat) : '';
}

export function getHTTPSUrl(url: string) {
  return url.replace('http://', 'https://');
}

const imageTypeList = ['png', 'jpg', 'jpeg', 'gif'];
const videoTypeList = ['mp4', 'avi', 'mkv', 'mov', 'flv', 'mpeg', 'rmvb', 'wmv', 'm4v', 'f4v', '3gp'];
const fileTypeList = ['pdf']; // 目前只支持pdf文件


export function getFileTypeByFileName(fileName: string) {
  if (fileName && fileName.length > 0) {
    const list = fileName.split('.');
    const typeName = list[list.length - 1];
    if (imageTypeList.includes(typeName)) {
      return 'image';
    } else if (videoTypeList.includes(typeName)) {
      return 'video';
    } else if (fileTypeList.includes(typeName)) {
      return 'pdf';
    }
  }
  return '';
}
