import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Spin } from "antd";
import { fetchTaskLogs } from "../../../services/task";
import HeaderTitle from '../../../components/HeaderTitle';
import TaskLogList from './TaskLogList'

import {
  isSuccessResponse,
} from "../../../util";
// import VideoPlayer from "../../../components/Video";

interface IParams {
  id: string;
}


// const mp4FileList: string[] = [
//   "https://glive-1251025085.cos.ap-guangzhou.myqcloud.com/recording/2411621409494274/1621409578/e4dbb0b9fe41630c496fc28c9050c167_2411621409494274_0.mp4",
//   "https://glive-1251025085.cos.ap-guangzhou.myqcloud.com/recording/2411621409494274/1621409635/055aa5e9da4ea6620bcf52b1575d152f_2411621409494274_0.mp4",
// ];

// const UpdateLog = ({ log }: { log: ITaskLog }) => {
//   const { valueChangeList, remarkList, } = log.logDetail;
//   console.log("remarkList", remarkList);
//   return (
//     <div className="taskLog">
//       {valueChangeList && valueChangeList.length > 0 && (
//         <div className="taskLogValueList">
//           {valueChangeList.map((c, index) => (
//             <div key={index}>
//               <span>{c.fieldName}</span>
//               <span
//                 style={{ color: "#909090" }}
//               >{`从${c.oldValue}变更为${c.newValue}`}</span>
//             </div>
//           ))}
//         </div>
//       )}
//       {remarkList && remarkList.length > 0 && (
//         <div className="taskLogRemarkList">
//           {remarkList.map((r, index) => {
//             const { remarkType, remarkContent } = r;
//             console.log(remarkType, remarkContent);

//             if (remarkType === "TEXT") {
//               return (
//                 <div key={index}>
//                   <span>备注：</span>
//                   <span style={{ color: "#909090" }}>{remarkContent}</span>
//                 </div>
//               );
//             }
//             if (remarkType === "PICTURE") {
//               console.log("r PICTURE", r);
//               return (
//                 <>
//                   <div key={index}>
//                     <Image width={300} src={remarkContent} alt="截图" />
//                   </div>
//                   <div style={{ marginTop: '20px' }}>
//                     <span>{getFileNameByUrl(remarkContent)}</span>
//                     <DownloadButton url={`${remarkContent}`} fileName={getFileNameByUrl(remarkContent) || "default.png"} />
//                   </div>
//                 </>
//               );
//             }
//             if (remarkType === "VIDEO") {
//               const { mp4FileList } = log;
//               const url = remarkContent;
//               return (
//                 <>
//                   <div key={index}>
//                     <VideoPlayer src={url} />
//                   </div>
//                   {(mp4FileList && mp4FileList.length > 0) ? mp4FileList.map((file, index) => {
//                     const fileName =
//                       splitFileName(
//                         getFileNameByUrl(file) as string,
//                         String(index)
//                       ) || "default.mp4";
//                     return (
//                       <div style={{ marginTop: '20px' }}>
//                         <span>{fileName}</span>
//                         <a
//                           style={{ marginTop: "1vh", marginLeft: "1vh" }}
//                           onClick={() => {
//                             downloadFileByUrl({ url: file, fileName });
//                           }}
//                         >
//                           下载
//                         </a>
//                       </div>
//                     );
//                   }) : null}
//                 </>
//               );
//             }
//             return null;
//           })}
//         </div>
//       )}
//     </div>
//   );
// };

const TaskLogs = () => {
  const params = useParams<IParams>();

  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState<ITaskLogItem[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchTaskLogs(params.id)
      .then((resp) => {
        if (isSuccessResponse(resp)) {
          setLogs(resp.data);
        }
      })
      .finally(() => setLoading(false));
  }, [params.id]);

  return (
    <Spin spinning={loading}>
      <HeaderTitle title='任务日志' />
      <div className="pt20">
        <TaskLogList logList={logs} />
      </div>
    </Spin>
  );
};

export default TaskLogs;
