import { FC, useCallback, useEffect, useState } from "react"
import { Form, Modal } from "antd"


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

interface IProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => Promise<any>;
}

const FormModal: FC<IProps> = ({ title, visible, children, onCancel, onOk }) => {

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const handleOk = useCallback(() => {
    form.validateFields().then((values) => {
      console.log('values', values);
      setLoading(true);
      return onOk(values).finally(() => setLoading(false));
    })
  }, [form, onOk]);

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={loading}
    >
      <Form
        {...formItemLayout}
        form={form}
      >
        {children}
      </Form>
    </Modal>
  )
}

export default FormModal;
