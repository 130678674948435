import { FC, ReactElement, useCallback } from "react";
import { Tree, Popconfirm } from "antd";
import _ from "lodash";
import { CaretDownOutlined } from "@ant-design/icons";
import { useUser } from "../../models/user";
import editIcon from '../../assets/member_management_bt_edit.png';
import deleteIcon from '../../assets/member_management_bt_delete.png';
import addIcon from '../../assets/member_management_bt_add.png';

import './index.scss';

const TreeNode = Tree.TreeNode;

function getChildrenIds(
  id: string,
  departmentsList: IDepartments[],
  ids: string[] = []
) {
  departmentsList.forEach((p) => {
    if (p.parentId === id) {
      ids.push(p.id);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getChildrenIds(p.id, departmentsList, ids);
    }
  });
  return ids;
}

function getParentIds(id: string, departmentsList: IDepartments[]) {
  let target = departmentsList.find((t) => t.id === id);
  if (!target || !target.parentId) return ["-1"];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const list: string[] = getParentIds(target.parentId, departmentsList);
  const ids: string[] = [target.parentId].concat(list);
  return ids;
}

interface IProps {
  list: IDepartments[];
  checkedKeys?: string[];
  checkable: boolean;
  onSelect?: (department: IDepartments | undefined) => void;
  onEdit?: (p: IDepartments) => void;
  onAdd?: (p: IDepartments) => void;
  onDelete?: (p: IDepartments) => void;
  onClick?: (p: IDepartments | undefined) => void;
}

const DepartmentTree: FC<IProps> = ({
  list,
  checkedKeys,
  checkable,
  onSelect,
  onEdit,
  onAdd,
  onDelete,
  onClick,
}) => {

  const getCheckedKeys = useCallback((ids: string[] | undefined) => {
    // console.log("ids", ids);
    if (!ids) {
      return [];
    }
    const keys = ids.filter((id) => {
      return !list.some((p) => p.parentId === id);
    });
    return keys;
  }, [list]);

  const select = useCallback((ids: any, e: any) => {
    const checked = e.checked;
    const selId = _.get(e, "node.key", 0);
    console.log("checked,", checked, selId);
    if (onSelect) {
      if (checked) {
        const d = list.find((i: IDepartments) => `${i.id}` === selId);
        // console.log("d", d);
        onSelect(d);
      } else {
        onSelect(undefined);
      }
    }
  }, [list, onSelect]);

  const { company } = useUser();

  const handleClick = useCallback((p: IDepartments | undefined) => {
    onClick && onClick(p);
  }, [onClick]);

  const renderTitle = useCallback((t?: IDepartments) => {
    if (!t) {
      return (
        <span>
          <span onClick={() => handleClick(undefined)}>
            {company?.enterpriseName}
          </span>
          {onSelect ? null : onAdd ? (
            <img
              src={addIcon}
              alt='addIcon'
              className='addIcon'
              onClick={() => onAdd({ id: "0" })}
            />
          ) : null}
        </span>
      );
    }
    if (onSelect) return t.name;
    return (
      <span>
        <span onClick={() => handleClick(t)}>{t.name}</span>
        {onEdit ? (
          <img
            src={editIcon}
            alt='editIcon'
            className='editIcon'
            onClick={() => onEdit(t)}
          />
        ) : null}
        {onDelete ? (
          <Popconfirm
            key="delete"
            title="确定删除该部门吗？"
            onConfirm={() => onDelete(t)}
            okText="确定"
            cancelText="取消"
          >
            <img
              src={deleteIcon}
              alt='deleteIcon'
              className='editIcon'
            />
          </Popconfirm>
        ) : null}
        {/* {onAdd ? (
          <PlusCircleOutlined
            key="add"
            onClick={() => {
              onAdd && onAdd(t);
            }}
            style={styles.icon}
          />
        ) : null} */}
      </span>
    );
  }, [handleClick, onSelect, onEdit, onDelete, onAdd, company?.enterpriseName]);

  const renderTree = useCallback((departmentsList: IDepartments[], id: string) => {
    const doms: ReactElement[] = [];
    departmentsList.forEach((t) => {
      if (t.parentId !== id) return;
      const props: {
        title: string | JSX.Element | undefined;
        children?: ReactElement[];
        key: string;
      } = {
        title: renderTitle(t),
        key: t.id,
      };
      // if (permissionList.some((l) => l.parentId === t.id && t.name)) {
      //   props.children = renderTree(list, t.id);
      // }
      doms.push(<TreeNode {...props} />);
    });
    return doms;
  }, [renderTitle]);

  let tree;
  list.forEach((l) =>
    !list.some((p) => p.id === l.parentId) ? (l.parentId = "0") : null
  );
  if (!tree) {
    tree = renderTree(list, "0");
  }

  return (
    <div className='departmentTree'>
      <Tree
        switcherIcon={<CaretDownOutlined className='arrowIcon' />}
        selectable={!!select}
        checkedKeys={getCheckedKeys(checkedKeys)}
        showLine
        defaultExpandedKeys={["-1"]}
        onCheck={select}
        checkable={checkable}
      >
        <TreeNode title={renderTitle()} key="-1">
          {tree}
          <TreeNode title={<span>
            <span onClick={() => handleClick({ name: '未分配', id: '0' })}>未分配</span>
          </span>} key='0' />
        </TreeNode>
      </Tree>
    </div>
  );
};

export default DepartmentTree;
