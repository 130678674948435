import { FC, useCallback } from "react";
import { Button, Form, Input, Select, Space } from "antd";

interface IProps {
  onSearch: (data: any) => void;
}

const FilterForm: FC<IProps> = ({ onSearch }) => {
  const [form] = Form.useForm();

  const handleSearch = useCallback(() => {
    form.validateFields().then(({ key, value }) => {
      const data: any = {};
      if (value) {
        data[key] = value;
      }
      onSearch(data);
    });
  }, [form, onSearch]);

  const handleReset = useCallback(() => {
    form.resetFields();
    onSearch({});
  }, [form, onSearch]);

  return (
    <Form form={form} layout="inline" style={{ marginBottom: 20 }}>
      <Form.Item>
        <Input.Group compact>
          <Form.Item name="key" noStyle initialValue="operatorName">
            <Select>
              <Select.Option value="operatorName">操作人</Select.Option>
              <Select.Option value="description">操作内容</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="value" noStyle initialValue="">
            <Input style={{ width: "60%" }} placeholder="请输入关键字" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item>
        <Space size="small">
          <Button type="primary" onClick={handleSearch}>
            搜索
          </Button>
          <Button onClick={handleReset}>重置</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FilterForm;
