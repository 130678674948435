import { FC, useCallback, useEffect, useState } from "react"
import { Form, Input, Modal, Upload, message } from "antd"
import { updateSysLogo, updateSysName } from "../../services/systemUser";
import { singleUploadFile } from "../../services/task";
import { isSuccessResponse } from "../../util";


interface IProps {
  id: number;
  visible: boolean;
  sysLogo?: string;
  sysName: string;
  onCancel: () => void;
  onOk: () => void;
}


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};


const DetailModal: FC<IProps> = ({ id, visible, sysLogo, onCancel, onOk, sysName }) => {

  // const [detailLoading, setDetailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: sysName,
      });
      setFiles(sysLogo ? [{ url: sysLogo }] : [])
    }
  }, [form, sysLogo, visible, sysName])


  const handleOk = useCallback(() => {
    form.validateFields().then(async ({ name }) => {
      setLoading(true);
      if (files && files.length > 0) {
        // 有文件的话，检查文件是否需要上传
        const file = files[0];
        if (!file.url) {
          // 不需要上传的话，证明不需要更新logo
          const uploadResult = await singleUploadFile({ file: file.originFileObj });
          const msg = uploadResult.message;
          if (isSuccessResponse(uploadResult)) {
            const newUrl = uploadResult.data.sourceUrl;
            const updateLogoRes = await updateSysLogo({ id, logo: newUrl });
            if (!isSuccessResponse(updateLogoRes)) {
              message.error(updateLogoRes.message || '更新Logo失败');
              return;
            }
          } else {
            message.error(msg || '文件上传失败');
            return;
          }
        }
      } else {
        const updateLogoRes = await updateSysLogo({ id, logo: '' });
        if (!isSuccessResponse(updateLogoRes)) {
          message.error(updateLogoRes.message || '更新Logo失败');
          return;
        }
      }

      const res = await updateSysName({ id, name });
      if (!isSuccessResponse(res)) {
        message.error(res.message || '更新组织名称失败');
      } else {
        onOk();
      }
      setLoading(false)
    })
  }, [form, onOk, files, id]);

  const onChangeFile = useCallback(({ fileList }) => {
    console.log('fileList', fileList);
    setFiles(fileList);
  }, [])

  console.log('files', files);

  return (
    <Modal
      title='编辑组织信息'
      visible={visible}
      okText='确定'
      onCancel={onCancel}
      onOk={handleOk}
      width={620}
      confirmLoading={loading}
    >
      <Form
        {...formItemLayout}
        form={form}
      >
        <Form.Item
          name="name"
          label="组织名称"
          rules={[{ required: true, message: '请输入组织名称', max: 30 }]}
        >
          <Input placeholder="请输入组织名称，30字以内" />
        </Form.Item>
        <Form.Item
          name="files"
          label="上传附件"
          rules={[{ required: true }]}
        >
          <div>
            <Upload
              accept="image/*"
              listType="picture-card"
              beforeUpload={() => false}
              onChange={onChangeFile}
              maxCount={1}
              fileList={files}
              showUploadList={{
                showRemoveIcon: true,
                showPreviewIcon: false,
              }}
            >
              {files.length > 0 ? null : '+'}
            </Upload>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DetailModal;
