import { Form, Input, Button, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PasswordInput from '../../components/Input/PasswordInput';
import { useAuth } from '../../models/auth';
import { login } from '../../services/systemUser';
import { isSuccessResponse } from '../../util';
import bgImage from '../../assets/bg.png';
import logoIcon from '../../assets/login_logo.png';
import passwordIcon from '../../assets/login_icon_password.png';
import userIcon from '../../assets/login_icon_user.png';
// import noSeeIcon from '../../assets/login_icon_closed_eyes.png';
// import seeIcon from '../../assets/login_icon_open_eyes.png';

import './index.scss';

const layout = {
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const Login = () => {

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation<any>();
  const { data, signin } = useAuth();

  const { from } = location.state || { from: { pathname: "/" } };
  const { token } = data;

  useEffect(() => {
    if (token) {
      history.replace(from);
    }
  }, [token, from, history])

  const handleLogin = useCallback(({ username, password }) => {
    setLoading(true);
    login({ username, password }).then((resp) => {
      if (isSuccessResponse(resp)) {
        const { data } = resp;
        signin(data.token);
        window.location.href = '/statistics';
      } else if (!resp.message) {
        message.error('账户或者密码错误');
        return;
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [signin])

  return (
    <div className='loginContainer' style={{ background: `url(${bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <div className='loginCard'>
        <div className='sysInfo'>
          <div><img src={logoIcon} alt='logo' className='logo' /></div>
          <div className='sysName'>真视角管理后台</div>
        </div>
        <Form
          {...layout}
          className='form'
          onFinish={handleLogin}
        >
          <Form.Item
            label=""
            name="username"
            rules={[{ required: true, message: '用户名必填' }]}
          >
            <Input placeholder='用户名' className='loginInput' maxLength={20} prefix={<img src={userIcon} alt='userIcon' className='inputIcon' />} />
          </Form.Item>
          {/* <div style={{ height: '5px' }} /> */}
          <Form.Item
            label=""
            name="password"
            rules={[{ required: true, message: '密码必填' }]}
          >
            <PasswordInput placeholder='密码' className='loginInput' prefix={<img src={passwordIcon} alt='passwordIcon' className='inputIcon' />} />
            {/* <Input.Password className='loginInput'
              iconRender={(visible) => <img src={visible ? seeIcon : noSeeIcon} alt='eyeIcon' style={{ width: '30px', height: '25px' }} />}
              prefix={<img src={passwordIcon} alt='passwordIcon' className='inputIcon' />} /> */}
          </Form.Item>

          <div className='tips'>
            <span></span>
            <span>如忘记密码，请联系管理员</span>
          </div>

          {/* <div className={styles.tips}>
          <span>没有账号，</span>
          <Link to="/register">去注册</Link>
        </div> */}

          <Form.Item {...tailLayout}>
            <Button className='loginButton' htmlType="submit" loading={loading}>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login;
