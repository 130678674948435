import { createContext, FC, useContext, useEffect, useState, useCallback } from "react";
import { getUserDetail } from "../services/systemUser";
import { companyDetailGlive } from "../services/company";
import { isSuccessResponse } from "../util";
import { isSysAdmin } from "../util/tool";
import { useAuth } from "./auth";

interface IUserModel {
  user: IUser | null;
  getDetial: () => void;
  company: ICompany | null;
}

const userContext = createContext<IUserModel>({ user: null, company: null, getDetial: () => { } });

export const UserProvider: FC = ({ children }) => {
  const { data } = useAuth();
  const [user, setUser] = useState<IUser | null>(null);
  const [company, setCompany] = useState<ICompany | null>(null);

  const getDetial = useCallback(() => {
    getUserDetail().then((resp) => {
      if (isSuccessResponse(resp)) {
        setUser(resp.data);
      }
    });
  }, []);

  useEffect(() => {
    if (data.token) {
      getDetial();
    } else {
      console.log('setUser null');
      setUser(null);
    }
  }, [data.token, getDetial]);

  useEffect(() => {
    if (user && !isSysAdmin(user)) {
      companyDetailGlive().then((res) => {
        if (isSuccessResponse(res)) {
          setCompany(res.data);
        }
      })
    }
  }, [user]);

  return (
    <userContext.Provider value={{ user, getDetial, company }}>
      {children}
    </userContext.Provider>
  );
};

export const useUser = () => {
  return useContext(userContext);
};
