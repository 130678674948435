import { FC, useCallback } from "react";
import { Form, InputNumber } from 'antd';
import { isSuccessResponse } from "../../util";
import { chargeUser } from "../../services/appUser";
import { liveInfoDataList } from '../CompanyManager/CompanyDetail/CompanyTimeInfo';
import FormModal from "../../components/FormModal";

interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: ({ success }: { success: boolean }) => Promise<void>;
  uids: string[];
}

// const userSupportChargeList = [
//   ...liveInfoDataList, ...recordInfoDataList
// ];

const userSupportChargeList = [
  liveInfoDataList[0]
];

const UserChargeModal: FC<IProps> = ({ visible, onCancel, onOk, uids }) => {

  const handleOk = useCallback(async (values: any) => {
    console.log('values', values);
    // 检查是不是起码有一项是有值的，才可以充值
    const haveValue = userSupportChargeList.some((i) => values[i.chargeKey] !== undefined && values[i.chargeKey] > 0);
    console.log(haveValue);
    if (haveValue) {
      const param: any = {};
      userSupportChargeList.forEach((i) => {
        const { chargeKey, unitCount } = i;
        const v = values[chargeKey];
        if (v !== undefined && v > 0) {
          param[chargeKey] = v * unitCount;
        }
      })

      const res = await chargeUser({ uids, ...param });
      onOk({ success: isSuccessResponse(res) });
      return true;
    }
    return false;
  }, [onOk, uids]);

  return (
    <FormModal
      title="充值"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
    >
      {
        userSupportChargeList.map((item) => {
          const { chargeKey, title } = item;
          return (
            <Form.Item
              key={chargeKey}
              name={chargeKey}
              label={title}
              rules={[{ min: 1, type: 'number', required: true }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          );
        })
      }
    </FormModal >
  )
}

export default UserChargeModal;
