import { Spin } from "antd";
import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import HeaderTitle from '../../../components/HeaderTitle';
import TitleLabel from '../../../components/TitleLabel';
import { livingDetail } from "../../../services/living";
import { isSuccessResponse } from "../../../util";
import { getLivingTimeString, LiveStatusEnum } from "../../../util/tool";

interface IParams {
  id: string;
}

function getStatus(value: string) {
  switch (value) {
    case "ORDER":
      return "已预约";
    case "FINISH":
      return "已结束";
    case "LIVING":
      return "进行中";
    default:
      return "";
  }
};

const LivingDetail = () => {
  const params = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const list = useMemo(() => {
    if (!data) return [];
    let arr = [
      { title: '标题', dataIndex: 'title' },
      { title: '描述', dataIndex: 'content' },
      {
        title: '组织/个人', dataIndex: 'companyName', render: (value: any, data: ILiving) => {
          return data.companyId > 0 ? value : '个人用户';
        },
      },
      { title: '直播人', dataIndex: 'nickname' },
      {
        title: '状态', dataIndex: 'status', render: (value: string) => {
          return getStatus(value);
        },
      },
    ];
    if (data.status === LiveStatusEnum.order) {
      arr.push({ title: '预约开始时间', dataIndex: 'orderStartTime' });
    } else {
      arr = arr.concat([
        {
          title: '直播时长', dataIndex: 'liveTime',
          render: (value: number) => {
            return getLivingTimeString(value || 0);
          },
        },
        {
          title: '累计观看', dataIndex: 'totalAudience', render: (value: number) => {
            return `${value || 0}人`;
          },
        },
        { title: '直播开始时间', dataIndex: 'startTime' },
        { title: '直播结束时间', dataIndex: 'endTime' }
      ]);
    }
    return arr;
  }, [data]);

  useEffect(() => {
    setLoading(true);
    livingDetail(Number(params.id))
      .then((resp) => {
        if (isSuccessResponse(resp)) {
          setData(resp.data);
          console.log(resp.data);
        }
      })
      .finally(() => setLoading(false));
  }, [params.id]);

  return (
    <div className='normalPage'>
      <HeaderTitle title='直播信息' />
      <Spin spinning={loading}>
        {data && (
          <div>
            {list.map((item: any) => {
              const { title, dataIndex, render } = item;
              const value = data[dataIndex];
              return (
                <TitleLabel style={{ marginBottom: 10 }} title={title + '：'} key={dataIndex} value={render ? render(value, data) : value} />
              );
            })}
          </div>
        )}
      </Spin>
    </div>
  );
};

export default LivingDetail;
