import { useState, useEffect, useCallback } from "react";
import { Col, message, Row } from "antd";
import {
  deleteDepartments,
  addDepartments,
  editDepartments,
  departmentsUrl,
  getDepartmentsDetail,
} from "../../services/department";
import { isSuccessResponse } from "../../util";
import { useDepartment } from "../../models/department";
import { CheckPermissionKeyInListHook } from "../../containers/PermissionComponents";
import DepartmentTree from "./DepartmentTree";
import DepartmentModal from "./DepartmentModal";
import AppUsers from "../AppUsers";

import './index.scss';

interface ISelDepartmentProps {
  id: string;
  name?: string;
}

const defaultDepartment: ISelDepartmentProps = {
  id: '-1',
  name: '全部'
}

const PermissionManager = () => {
  const [currentDepartment, setCurrentDepartment] = useState<IDepartments | undefined>(undefined);
  const [confirming, setConfirming] = useState(false);
  const [selDepartment, setSelDepartment] = useState<ISelDepartmentProps>(defaultDepartment);
  const { departmentList, getDepartmentList } = useDepartment();

  const onAdd = useCallback((parentDepartment: IDepartments) => setCurrentDepartment({ id: "0", parentId: parentDepartment.id }), [])

  const addOrUpdateDepartments = useCallback(async (p: IDepartments) => {
    return p.id !== "0" ? editDepartments(p) : addDepartments(p);
  }, [])

  useEffect(() => {
    getDepartmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = useCallback(() => setCurrentDepartment(undefined), []);
  const confirmFinish = useCallback(({
    res,
    successMsg,
  }: {
    res: any;
    successMsg?: string;
  }) => {
    if (isSuccessResponse(res)) {
      getDepartmentList();
      message.success(successMsg ? successMsg : "保存成功!");
      onCancel();
    }
  }, [getDepartmentList, onCancel]);

  const onOk = useCallback((p: IDepartments) => {
    console.log("onOK", p);
    if (p) {
      setConfirming(true);
      addOrUpdateDepartments(p).then((res) => {
        setConfirming(false);
        confirmFinish({ res });
      });
    }
  }, [confirmFinish, addOrUpdateDepartments]);

  const onEdit = useCallback((p: IDepartments) => {
    getDepartmentsDetail(p.id).then((resp) => {
      if (isSuccessResponse(resp)) {
        setCurrentDepartment(resp.data);
      }
    });
  }, []);

  const onDelete = useCallback(async (p: IDepartments) => {
    deleteDepartments(p.id).then((res) => {
      confirmFinish({ res, successMsg: "删除成功！" });
    });
  }, [confirmFinish]);

  const onClick = useCallback((p: IDepartments | undefined) => {
    console.log(p);
    setSelDepartment(p ? p : defaultDepartment);
  }, []);

  const canEdit = CheckPermissionKeyInListHook(departmentsUrl);

  return (
    <div>
      <Row>
        <Col span={5} className='normalPage'>
          <DepartmentTree
            list={departmentList}
            checkable={false}
            onEdit={canEdit ? onEdit : undefined}
            onAdd={canEdit ? onAdd : undefined}
            onDelete={canEdit ? onDelete : undefined}
            onClick={onClick}
          />
        </Col>
        <Col span={19} className='normalPage' style={{ paddingLeft: '0px', borderLeft: '1px solid #eee' }}>
          <AppUsers id={selDepartment.id === '-1' ? undefined : selDepartment.id} name={selDepartment.name || ""} />
        </Col>
      </Row>
      <DepartmentModal
        visible={!!currentDepartment}
        department={currentDepartment}
        onOk={onOk}
        onCancel={onCancel}
        confirming={confirming}
      />
    </div>
  );
};

export default PermissionManager;
