
import request from "../util/request";

const updateSysLogoUrl = '/sys/sysUser/sysLogo';
const updateSysNameUrl = '/sys/sysUser/sysName';
const resetPasswordUrl = '/sys/sysUser/resetPwd';

const operatorLogUrl = '/glive/web/logs/page';

export {
  updateSysLogoUrl,
  updateSysNameUrl,
  resetPasswordUrl,
  operatorLogUrl
}

interface ILogPageParams extends IPage {
  description?: string;
  operatorName?: string;
}

export function login({ username, password }: { username: string, password: string }) {
  return request<IResponse<IAuth> | IErrorResponse>('sys/sysUser/login', {
    method: 'POST',
    data: { username, password },
  })
}

export function getUserDetail() {
  return request<IResponse<IUser> | IErrorResponse>('sys/sysUser/userDetailInfo', {
    method: 'POST',
  })
}


export function getUserList(data: ITask) {
  return request<IResponse<{}> | IErrorResponse>('sys/sysUser/getListByPage', {
    method: 'POST',
    data,
  })
}

export function getSysUserPermissionTree() {
  return request<IResponse<{}> | IErrorResponse>('sys/sysUser/permissionTree', {
    method: 'POST',
  })
}

export function updateSysName({ id, name }: { id: number, name: string }) {
  return request<IResponse<{}> | IErrorResponse>(updateSysNameUrl, {
    method: 'POST',
    data: { id, sysName: name }
  })
}

export function updateSysLogo({ id, logo }: { id: number, logo: string }) {
  return request<IResponse<{}> | IErrorResponse>(updateSysLogoUrl, {
    method: 'POST',
    data: { id, sysLogo: logo }
  })
}

export function resetPassword({ newPassword, oldPassword }: { newPassword: string, oldPassword: string }) {
  return request<IResponse<{}> | IErrorResponse>(resetPasswordUrl, {
    method: 'POST',
    data: { newPassword, oldPassword }
  })
}

export function operatorLogRecoed(params: ILogPageParams) {
  return request<IResponse<IPagination<IOperatorLog>> | IErrorResponse>(operatorLogUrl, {
    method: 'GET',
    params,
  })
}
