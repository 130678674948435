import { useParams } from "react-router";
import RecordTable from './Table';
import { IParams } from '../CompanyBaseInfo';

const CompanyChargeRecord = ({ active }: { active: boolean }) => {
  const params = useParams<IParams>();
  return (
    <RecordTable companyId={params.id} active={active} />
  )
}

export default CompanyChargeRecord;
