import { useCallback, useState, useMemo } from 'react';
import { message } from 'antd';
import { Image, View } from '../../../../components/reactComponents';
import downloadIcon from '../../../../assets/task_management_icon_download_small.png';
import {
  downloadFileByUrl,
  getFileNameByUrl,
} from "../../../../util";
import { getHTTPSUrl, getFileTypeByFileName } from "../../../../util/tool";
import VideoModal from '../TaskLogList/TaskMediaItem/VideoModal';
import AntImage from '../TaskLogList/TaskMediaItem/AntImage';

import './index.scss';

function getVideoIdByUrl(url: string) {
  const fileName = getFileNameByUrl(url);
  if (fileName) {
    const arr = fileName.split('.');
    return 'video' + arr[0];
  }
  return 'video-custom';
}


interface IProps {
  url: string;
}

const AccessoryItem = ({ url }: IProps) => {
  const type = useMemo(() => getFileTypeByFileName(url), [url]);
  const isPicture = type === 'image';
  const [show, setShow] = useState(false);
  const downloadVideos = useCallback((e) => {
    if (!isPicture) {
      if (url) {
        downloadFileByUrl({ url: getHTTPSUrl(url), fileName: getFileNameByUrl(url) || 'default.mp4' });
      } else {
        message.info({ content: '该视频暂不可下载' })
      }
    } else {
      downloadFileByUrl({ url: getHTTPSUrl(url), fileName: getFileNameByUrl(url) || 'default.png' });
    }
  }, [isPicture, url])

  const id = getVideoIdByUrl(url);
  console.log(id);

  return (<View className='accessoryItem' >
    <View className='mediaView'>
      {isPicture ? <AntImage src={url}
        // mode='aspectFill'
        className='image' >
        {/* <Image src={downloadIcon} className='downloadIcon' onClick={downloadVideos} /> */}
      </AntImage> :
        <View className='videoView' onClick={() => setShow(true)}>
        </View>}
    </View>
    <Image src={downloadIcon} className='downloadIcon' onClick={downloadVideos} />
    <VideoModal visible={show} onCancel={() => setShow(false)} url={url} id={id} />
  </View>)

}

export default AccessoryItem;
