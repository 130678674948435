import { useCallback, useMemo, useEffect, useRef } from "react";
import { Table } from "antd";
import { useParams } from "react-router";
import { useUser } from "../../../../models/user";
import { isSysAdmin } from "../../../../util/tool";
import { liveInfoDataList, storeInfoDataList } from '../../../CompanyManager/CompanyDetail/CompanyTimeInfo';
import { fetchChargeRecordList } from "../../../../services/appUser";
import { formatDate } from "../../../../util";
import { IParams } from '../Detail';
import useList from "../../../../hooks/useList";

const columnsList: { title: string; dataIndex: string; render?: any }[] = [
  {
    title: '充值时间',
    dataIndex: 'createdAt',
    render: (value: string) => value ? formatDate(value) : '-',
  }
];

// const list = [...liveInfoDataList, ...recordInfoDataList];
const list = [liveInfoDataList[0]];

list.forEach((i) => {
  const { title, chargeKey, unitCount, unit } = i;
  columnsList.push({
    title,
    dataIndex: chargeKey,
    render: (number: number) => number > 0 ? `${Math.floor(number / unitCount)}${unit}` : '-',
  });
});


const ChargeRecordList = ({ active }: { active: boolean }) => {
  const activeRef = useRef(active);
  const params = useParams<IParams>();
  const {
    list,
    loading,
    filter,
    pagination,
    fetchList,
  } = useList<IChargeRecord>((p: any) => fetchChargeRecordList({ ...p, uid: params.uid }));
  const { user } = useUser();
  const sysAdmin = !!(user && isSysAdmin(user));

  const columns = useMemo(() => {
    if (sysAdmin) {
      const personalList = [...columnsList];
      storeInfoDataList.forEach((i) => {
        const { title, chargeKey, unitCount, unit } = i;
        personalList.push({
          title,
          dataIndex: chargeKey,
          render: (number: number) => number > 0 ? `${Math.floor(number / unitCount)}${unit}` : '-',
        });
      });

      return personalList;
    } else {
      return columnsList;
    }
  }, [sysAdmin]);

  const handlePageChange = useCallback((page: number, pageSize?: number) => {
    fetchList({ ...filter, page: pageSize !== pagination.pageSize ? 1 : page, size: pageSize })
  }, [fetchList, filter, pagination]);

  useEffect(() => {
    if (activeRef.current !== active) {
      activeRef.current = active;
      if (activeRef.current) {
        handlePageChange(1);
      }
    }
  }, [active, handlePageChange]);

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange
        }}
      />
    </div>
  )
}

export default ChargeRecordList;
