import { useMemo, useCallback, useState } from "react";
import { Spin, message, Modal, Button } from "antd";
// import { fetchUserDetail } from "../../../services/appUser";
import { formatDate, isSuccessResponse } from "../../../util";
import HeaderTitle from '../../../components/HeaderTitle';
import TitleLabel from '../../../components/TitleLabel';
import { useUser } from "../../../models/user";
import {
  deleUser,
  unfreezeUser,
} from "../../../services/appUser";
import { isSysAdmin, minuteTimeString, isUserFreeze } from "../../../util/tool";
import UserChargeModal from '../UserChargeModal';
import AddUserModal from "./AddUserModal";
import { columnsList } from "../index";


import './index.scss';

export interface IParams {
  uid: string;
}

const departmentsInfoItem = {
  title: "所属部门",
  dataIndex: "departments",
  render: (departments?: IDepartments[]) => {
    console.log("departments", departments);
    if (departments && departments.length > 0) {
      const d: IDepartments = departments[0];
      return d.name;
    }
  },
};

const timeInfoItem = {
  title: '剩余时长(分钟)',
  dataIndex: 'liveHdAvailableSeconds',
  render: (value: number, data: IAppUser) => {
    const { liveHdTotalSeconds, liveHdAvailableSeconds } = data.captureInfoVo || {};
    return `总时长${minuteTimeString(liveHdTotalSeconds || 0)}，剩余${minuteTimeString(liveHdAvailableSeconds || 0)}`;
  },
};

const createItem = {
  title: '创建时间',
  dataIndex: 'createdAt',
  render: (value: string) => (value ? formatDate(value) : "-"),
};

const infoList: any[] = [];
for (let index = 0; index < columnsList.length; index++) {
  const i = columnsList[index];
  if (i.dataIndex !== "opt") {
    if (i.dataIndex === timeInfoItem.dataIndex) {
      infoList.push(createItem);
      infoList.push(timeInfoItem);
    } else {
      infoList.push(i)
    }
  }
}

// const infoList = columnsList
//   .filter((i) => i.dataIndex !== "opt");
// const timeIndex = infoList.findIndex(i => i.dataIndex === timeInfoItem.dataIndex);


const Detail = ({
  loading,
  data,
  queryData,
}: {
  loading: boolean;
  data: IAppUser | null;
  queryData: () => void;
}) => {

  console.log("detail", data);
  const { user } = useUser();
  const [show, setShow] = useState(false);
  const [showCharge, setShowCharge] = useState(false);
  const sysAdmin = !!(user && isSysAdmin(user));

  const list = useMemo(() => {
    if (sysAdmin) {
      return infoList;
    } else {
      return infoList.concat([departmentsInfoItem]);;
    }
  }, [sysAdmin]);

  const info: any = data as any;

  const freeze = data ? isUserFreeze(data.status) : true;

  const freezeUser = useCallback((user: IAppUser) => {
    const freeze = isUserFreeze(user.status);
    const title = `确定要${freeze ? '解冻' : '冻结'}${user.nickname || user.phone}吗？`;
    const content = freeze ? '' : '冻结后，该用户时长讲清零';
    Modal.confirm({
      title,
      content,
      onOk: async () => {
        let res;
        if (freeze) {
          res = await unfreezeUser(user.uid);
        } else {
          res = await deleUser(user.uid);
        }

        if (isSuccessResponse(res)) {
          message.success(freeze ? '解冻成功' : '冻结成功')
        } else {
          message.error(res.message || '请求失败')
        }
        queryData();
        return true;
      }
    })
  }, [queryData]);

  const onCancel = useCallback(() => { setShow(false) }, []);
  const handleAddOk = useCallback(() => {
    onCancel();
    queryData();
  }, [onCancel, queryData]);

  const handleChargeOk = useCallback(async ({ success }) => {
    setShowCharge(false);
    queryData();
  }, [queryData]);

  return (
    <Spin spinning={loading}>
      <HeaderTitle title='成员基本信息' />
      <div className='userDetail'>
        <div className='userDetailInfo'>
          {data && (
            list.map((item: any) => {
              const { title, dataIndex, render } = item;
              const value = info[dataIndex];
              return (
                <TitleLabel style={{ marginBottom: 10 }} title={title + '：'} key={dataIndex} value={render ? render(value, data) : value} />
              );
            })
          )}
        </div>
        {!sysAdmin && <div>
          {!freeze && <Button onClick={() => setShowCharge(true)} type='primary'>
            充值
          </Button>}
          {!freeze && <Button style={{ marginLeft: '20px' }} onClick={() => setShow(true)} className='normarlEditButton'>
            编辑
          </Button>}
          <Button style={{ marginLeft: '20px' }} onClick={data ? () => freezeUser(data) : undefined} className='normarlDeleteButton'>
            {freeze ? '解冻' : '冻结'}
          </Button>
        </div>}
      </div>
      {data && <AddUserModal
        visible={show}
        onCancel={onCancel}
        onOk={handleAddOk}
        uid={data.uid}
      />}
      {data && <UserChargeModal
        uids={[`${data.uid}`]}
        visible={showCharge}
        onCancel={() => setShowCharge(false)}
        onOk={handleChargeOk}
      />}
    </Spin>
  );
};

export default Detail;
