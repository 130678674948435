
import request from "../util/request";

const rolePageUrl = '/sys/sysRole/getListByPage';
const addRoleUrl = '/sys/sysRole/add';
const editRoleUrl = '/sys/sysRole/update';
const deleteRoleUrl = '/sys/sysRole/delete';
const roleListUrl = '/sys/sysRole/pull';
const getRoleUrl = '/sys/sysRole/detail';

export {
  rolePageUrl,
  addRoleUrl,
  editRoleUrl,
  deleteRoleUrl,
  roleListUrl,
}

interface IRoleParam {
  roleName?: string;
  permissions?: number[];
  remark?: string;
}

interface IRoleEditParam extends IRoleParam {
  id: string;
}

export function rolePage(param: IAepPage) {
  return request<IResponse<IAepPagination<IRole>> | IErrorResponse>(rolePageUrl, {
    method: 'POST',
    data: param,
  })
}

export function addRole({ roleName, permissions, remark }: IRoleParam) {
  return request<IResponse<{}> | IErrorResponse>(addRoleUrl, {
    method: 'POST',
    data: { roleName, permissions, remark },
  })
}

export function editRole({ id, roleName, permissions, remark }: IRoleEditParam) {
  return request<IResponse<{}> | IErrorResponse>(editRoleUrl, {
    method: 'POST',
    data: { id, roleName, permissions, remark },
  })
}

export function deleteRole(ids: string[]) {
  return request<IResponse<{}> | IErrorResponse>(deleteRoleUrl, {
    method: 'POST',
    data: ids,
  })
}

export function pullRole() {
  return request<IResponse<{}> | IErrorResponse>(roleListUrl, {
    method: 'POST',
  })
}

export function getRole(id: string) {
  return request<IResponse<IRolePermission> | IErrorResponse>(getRoleUrl, {
    method: 'POST',
    data: id
  })
}
