import { Space } from "antd";
import GTable from '../../components/GTable';
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import useList from "../../hooks/useList";
import { livingPage } from "../../services/living";
import { getLivingTimeString } from "../../util/tool";
import FilterForm from "./FilterForm";

interface IProps {
  uid?: number;
}

const LivingList = (props: IProps) => {
  const { list, loading, filter, pagination, fetchList } = useList<ILiving>(
    (p: any) => livingPage({ ...p, ...props, })
  );

  const columns = useMemo(() => {
    return [
      {
        title: "标题",
        dataIndex: "title",
      },
      {
        title: "组织/个人",
        dataIndex: "companyId",
        render: (value: number, record: any) => {
          if (value > 0) {
            return record.companyName;
          } else {
            return '个人用户'
          }
        }
      },
      {
        title: "直播人",
        dataIndex: "nickname",
      },
      {
        title: "状态",
        dataIndex: "status",
        render: (value: string) => {
          switch (value) {
            case "ORDER":
              return "已预约";
            case "FINISH":
              return "已结束";
            case "LIVING":
              return "进行中";
            default:
              return "";
          }
        },
      },
      {
        title: "开始时间",
        dataIndex: "startTime",
      },
      {
        title: "结束时间",
        dataIndex: "endTime",
      },
      {
        title: "直播时长",
        dataIndex: "liveTime",
        render: (value: number, record: any) => {
          if (record.status === "LIVING" || record.status === "FINISH") {
            return getLivingTimeString(value || 0);
          }
        },
      },
      {
        title: "累计观看",
        dataIndex: "totalAudience",
        render: (value: string, record: any) => {
          if (record.status === "LIVING" || record.status === "FINISH") {
            return (value || 0) + "人";
          } else {
            return "";
          }
        },
      },
      {
        title: "操作",
        dataIndex: "opt",
        render: (_: any, record: ILiving) => {
          return (
            <>
              <Space size="middle" style={{ marginRight: "1vw" }}>
                <Link className='tableDetailButton' to={`/living/${record.id}`}>详情</Link>
              </Space>
            </>
          );
        },
      },
    ];
  }, []);

  const handlePageChange = useCallback(
    (page: number, size?: number) => {
      fetchList({
        ...filter,
        page: size !== pagination.pageSize ? 1 : page,
        size,
      });
    },
    [fetchList, filter, pagination]
  );

  const handleSearch = useCallback(
    (data: any) => {
      fetchList({ ...data, page: 1, size: pagination.pageSize });
    },
    [pagination.pageSize, fetchList]
  );

  return (
    <div className='normalPage'>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <h3>{"直播列表"}</h3>
      </div>
      <FilterForm onSearch={handleSearch} />
      <GTable
        rowKey="id"
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange,
        }}
      />
    </div>
  );
};

export default LivingList;
