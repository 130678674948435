import { Avatar, Card } from "antd";
import Meta from "antd/lib/card/Meta";
import "./cardList.scss";

interface IProps {
  icon: string;
  title: string;
  description: string;
  timetype?: string;
}

const CardList = ({ icon, title, description, timetype }: IProps) => {
  return (
    <div>
      <Card className="card">
        <Meta
          avatar={<Avatar src={icon} className="avatar" />}
          title={<div className="description">
            <span>{description}</span>
            <span className="timetype">
              {timetype}
            </span>
          </div>}
          description={<span className="title">{title}</span>}
          style={{ display: "flex", alignItems: "center" }}
        />
      </Card>
    </div>
  );
};

export default CardList;
