import { FC, useCallback } from "react";
import { useUser } from "../models/user";

interface IProps {
  permissionKey: string;
}

export function checkPermissionKeyInList(key: string, list?: IPermission[]) {
  if (list && list.length > 0) {
    return list.find((i: IPermission) => i.uri === key);
  }
  return false;
}

export function CheckPermissionKeyInListHook(permissionKey: string) {
  const { user } = useUser();
  return checkPermissionKeyInList(permissionKey, user?.permissions);
}

interface IProps {
  permissionKey: string;
}

const PermissionComponents: FC<IProps> = ({ permissionKey, children }) => {
  const { user } = useUser();

  const checkPermission = useCallback(() => {
    return checkPermissionKeyInList(permissionKey, user?.permissions);
  }, [permissionKey, user?.permissions]);

  const havePermission = checkPermission();
  return user && havePermission && children ? (
    <span>{children}</span>
  ) : (
    <span />
  );
};

export default PermissionComponents;
