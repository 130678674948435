import { useMemo } from 'react';
import TitleValueLabel from '../../../../components/TitleLabel';
import { View, Text, } from '../../../../components/reactComponents';
import { getLivingTimeString } from '../../../../util/tool';
import TaskMediaItem from './TaskMediaItem'

import './index.scss'

const LiveInfoItem = ({ log, executeUserId }: { log: ITaskLogItem, executeUserId?: number }) => {
  const { createUserNickname, createUsername } = log;
  const { end, video, picture, usernames, liveTime, totalAudience, description } = log.logDetail.livingLog || {};

  const liveEndInfo = useMemo(() => {
    const showMember = true;
    return (end ?
      <View>
        <Text className='bigText'>共直播{getLivingTimeString(liveTime || 0)}</Text>
        <View className='liveInfoEndView'>
          <View className='liveInfoEndHeader'>
            <View className='desView'>
              <TitleValueLabel title='直播描述：' value={description || ''} />
            </View>
            {showMember && <View className='desView'>
              <View>
                <TitleValueLabel title='已邀请成员：' value={usernames || ''} />
                <TitleValueLabel title='累计观看：' value={`${totalAudience || 0}人`} />
              </View>
              <span />
            </View>}
          </View>
          {(video && video.length > 0) || (picture && picture.length > 0) ? <View className='mediaView'>
            {(video && video.length > 0) && video.map((videoItem) => {
              const { file, username, id, mp4FileSize, time, startTime, mp4Files } = videoItem;
              return <TaskMediaItem
                key={id}
                url={file}
                username={username}
                downloadUrls={mp4Files}
                type='video'
                size={mp4FileSize}
                time={time}
                startTime={startTime}
              />;
            })}
            {(picture && picture.length > 0) && picture.map((pictureItem) => {
              const { picture: url, username, id, pictureSize, startTime } = pictureItem;
              return <TaskMediaItem
                key={id}
                url={url}
                username={username}
                type='image'
                size={pictureSize}
                startTime={startTime}
              />;
            })}
          </View> : null}
        </View>
      </View> : null);
  }, [end, liveTime, totalAudience, usernames, description, video, picture]);

  return (<View className='liveInfoItem'>
    <View className='handlerView'>
      <Text className='handlerText'>
        由<Text className='handler'>{createUserNickname || createUsername}</Text>{end ? '结束直播' : '开始直播'}
      </Text>
    </View>
    {liveEndInfo}
  </View>)
}

export default LiveInfoItem;
