import { memo, useMemo, useCallback } from 'react';
import { Menu } from 'antd';
import _ from 'lodash'
import { Link, useLocation } from 'react-router-dom';
import { companyMenus, sysAdminMenus, IMenuItem, ISubMenuItem } from './Routes';
import { useUser } from "../../models/user";
import { isSysAdmin } from '../../util/tool';
import { checkPermissionKeyInList } from '../../containers/PermissionComponents';

const { SubMenu } = Menu;


const DashboardMenu = () => {
  const { user } = useUser();

  const location = useLocation();

  const selectedKey = useMemo(() => {
    return `/${location.pathname.split('/')[1] || 'tasks'}`;
  }, [location.pathname]);
  const sysAdmin = user && isSysAdmin(user);
  const menus = useMemo(() => {
    let arr: IMenuItem[] = sysAdmin ? sysAdminMenus : companyMenus;
    return _.cloneDeep(arr.filter((i: IMenuItem) => !i.menuVisible));
  }, [sysAdmin]);

  const checkPermission = useCallback((meun: ISubMenuItem) => {
    return meun.key && checkPermissionKeyInList(meun.key, user?.permissions);
  }, [user?.permissions])

  const menuData = menus.filter((i: IMenuItem) => {
    if (i.subMenus) {
      const newSubMenus = i.subMenus.filter((sub: ISubMenuItem) => checkPermission(sub));
      // console.log('newSubMenus', newSubMenus, i.subMenus);
      i.subMenus = newSubMenus;
      return newSubMenus && newSubMenus.length > 0;
    } else {
      return checkPermission(i);
    }
  });

  // console.log('menuData', menuData, menus);

  return (
    <Menu
      mode="inline"
      selectedKeys={[selectedKey]}
    >
      {
        menuData.map((r: IMenuItem) => {
          if (r.subMenus) {
            return (
              <SubMenu key={r.path} icon={r.icon} title={r.name}>
                {
                  r.subMenus.map((sub: ISubMenuItem) => <Menu.Item key={sub.path} >
                    <Link to={sub.path}>{sub.name}</Link>
                  </Menu.Item>)
                }
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={r.path} icon={r.icon}>
                <Link to={r.path}>{r.name}</Link>
              </Menu.Item>
            );
          }
        })
      }
      {/* {
        menuData.map((r) => r.menuVisible == null || r.menuVisible ? (
          <Menu.Item key={r.path} icon={r.icon}>
            <Link to={r.path}>{r.name}</Link>
          </Menu.Item>
        ) : null)
      } */}
    </Menu>
  )
};

export default memo(DashboardMenu);
