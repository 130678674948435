import { useState, useCallback } from "react";
import { Tabs } from "antd";
import { useParams } from "react-router";
import Detail, { IParams } from "./Detail";
import appUserHook from "./appUserDetailHook";
// import TimeInfo from "./TimeInfo";
import UseRecordList from "./UseRecordList";
import ChargeRecordList from "./ChargeRecordList";
import TaskList from "../../Tasks/TaskList";
import LivingList from "../../Living/LivingList";
import { CheckPermissionKeyInListHook } from "../../../containers/PermissionComponents";
import {
  // userTimeInfoUrl,
  usedRecordPageUrl,
  chargeRecordPageUrl,
} from "../../../services/appUser";
import { taskPageUrl } from "../../../services/task";
import { useHistory } from "react-router-dom";

import './index.scss';

const { TabPane } = Tabs;

const AppUserDetail = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const istask = history.location.pathname.includes("notask");
  const { loading, data, queryUser } = appUserHook({ id: params.uid });
  const [activeKey, setActiveKey] = useState("taskList");
  const canUserTask = CheckPermissionKeyInListHook(taskPageUrl);
  // const canUseTime = CheckPermissionKeyInListHook(userTimeInfoUrl);
  const canUseRecord = CheckPermissionKeyInListHook(usedRecordPageUrl);
  const canChangreRecord = CheckPermissionKeyInListHook(chargeRecordPageUrl);

  const onChange = useCallback((activeKey: string) => {
    setActiveKey(activeKey);
  }, []);

  return (
    <div className='normalPage'>
      <Detail loading={loading} data={data} queryData={queryUser} />
      <Tabs defaultActiveKey="taskList" onChange={onChange} type="card">
        {canUserTask && !istask && (
          <TabPane tab="任务列表" key="taskList">
            {!loading && data && (
              <TaskList active={activeKey === "taskList"} appUser={data} />
            )}
          </TabPane>
        )}
        {istask && (
          <TabPane tab="直播列表" key="livingList">
            {!loading && data && <LivingList uid={data.uid} />}
          </TabPane>
        )}
        {/* {canUseTime && (
          <TabPane tab="时长信息" key="timeInfo">
            <TimeInfo active={activeKey === "timeInfo"} />
          </TabPane>
        )} */}
        {canUseRecord && (
          <TabPane tab="使用记录" key="useRecord">
            <UseRecordList active={activeKey === "useRecord"} />
          </TabPane>
        )}
        {canChangreRecord && (
          <TabPane tab="充值记录" key="chargeRecord">
            <ChargeRecordList active={activeKey === "chargeRecord"} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default AppUserDetail;
