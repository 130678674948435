import { useEffect, useState, useCallback, useMemo } from "react";
import { Spin, Row, Col } from "antd";
import HeaderTitle from '../../components/HeaderTitle';
import { companyInfo } from "../../services/company";
import { isSuccessResponse } from "../../util";
import { IIntoItem, liveInfoDataList, storeInfoDataList } from '../CompanyManager/CompanyDetail/CompanyTimeInfo';
import TimeProgress from '../../components/TimeProgress';
import styles from '../CompanyManager/CompanyDetail/index.module.css';

const timeInfoList = [{ title: '流量', list: [liveInfoDataList[0]] }];
const storageInfoList = [{ title: '存储空间', list: storeInfoDataList }];


const TimeInfo = ({ active, type = 'time', }: { active: boolean, type: string }) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ICompany | null>(null);
  const infoList = useMemo(() => {
    return type === 'time' ? timeInfoList : storageInfoList;
  }, [type])

  const queryInfo = useCallback(() => {
    setLoading(true);
    companyInfo().then((resp) => {
      if (isSuccessResponse(resp)) {
        console.log(resp.data);
        setData(resp.data)
      }
    }).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (active) {
      queryInfo();
    }
  }, [queryInfo, active]);

  return (
    <Spin spinning={loading}>
      {data && <div className={styles.timeInfo}>
        {
          infoList.map((listItem) => {
            const { title, list } = listItem;
            return <div key={title}>
              <HeaderTitle title={title} />
              <Row>
                {list.map((item: IIntoItem) => {
                  const { title, unit, color, totalKey, resetKey, unitCount } = item;
                  const info = data as any;
                  const total = Math.floor(info[totalKey] / unitCount);
                  const value = Math.floor(info[resetKey] / unitCount);
                  return <Col span={12} className={styles.col} key={totalKey}>
                    <TimeProgress
                      title={title}
                      strokeColor={color}
                      unit={unit}
                      total={total}
                      value={value}
                    />
                  </Col>
                })}
              </Row>
            </div>
          })
        }
      </div>}
    </Spin>
  )
}

export default TimeInfo;
