import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, message, Space } from "antd";
import {
  batchDeleteLicense,
  fetchImportTemplate,
  fetchLicenseList,
  importPreActivateUsers,
  createAndDeleteUrl,
} from "../../services/license";
import { formatDate, isSuccessResponse } from "../../util";
import GTable from "../../components/GTable";
import useList from "../../hooks/useList";
import { useUser } from "../../models/user";
import { isSysAdmin, getCloudStorageDisplayString } from "../../util/tool";
import FilterForm from "./FilterForm";
import LicenseModal from "./LicenseModal";
import ImportModal from "../../components/ImportModal";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
import PreActivateModal from "./PreActivateModal";
import { CheckPermissionKeyInListHook } from '../../containers/PermissionComponents';

const Licenses = () => {

  const {
    list,
    loading,
    filter,
    pagination,
    rowSelection,
    // selectedRowKeys,
    // setSelectedRowKeys,
    handleDelete,
    fetchList,
  } = useList<ILicense>((p: any) => fetchLicenseList({ ...p, licenseStatus: 'INIT' }), { batchDeleteFn: batchDeleteLicense });

  const [genVisible, setGenVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [preActivateVisible, setPreActivateVisible] = useState(false);
  const [templateUrl, setTemplateUrl] = useState('');

  const { user } = useUser();
  const sysAdmin = !!(user && isSysAdmin(user));

  useEffect(() => {
    fetchImportTemplate().then((resp) => {
      if (isSuccessResponse(resp)) {
        setTemplateUrl(resp.data.url);
      }
    })
  }, [])

  const columns = useMemo(() => {
    const arr: { title: string; dataIndex: string; render?: any }[] = [{
      title: '批次号',
      dataIndex: 'batchNum'
    },
    {
      title: '授权码',
      dataIndex: 'code',
    },
    {
      title: '绑定的用户名',
      dataIndex: 'bindingUsername',
    },
    {
      title: '充值分钟数',
      dataIndex: 'availableMinutes',
    },
    ];
    if (sysAdmin) {
      arr.push({
        title: '存储量',
        dataIndex: 'cloudStorage',
        render: (value: number | undefined | null) => getCloudStorageDisplayString(value)
      })
    }

    return [
      ...arr,
      {
        title: '是否使用',
        dataIndex: 'used',
        render: (value: boolean) => value ? '是' : '否',
      },
      {
        title: '是否激活',
        dataIndex: 'activated',
        render: (value: boolean) => value ? '是' : '否',
      },
      {
        title: '激活时间',
        dataIndex: 'activateTime',
        render: (value: string, record: ILicense) => value && record.activated ? formatDate(value) : '-',
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: (value: string) => formatDate(value),
      },
    ]
  }, [sysAdmin]);

  const handlePageChange = useCallback((page: number, pageSize?: number) => {
    fetchList({ ...filter, page: pageSize !== pagination.pageSize ? 1 : page, size: pageSize })
  }, [fetchList, filter, pagination]);

  const handleSearch = useCallback((data: any) => {
    fetchList({ ...data, page: 1, size: pagination.pageSize })
  }, [fetchList, pagination.pageSize]);

  const handleGenCancel = useCallback(() => {
    setGenVisible(false);
  }, []);

  const handleOk = useCallback(() => {
    setGenVisible(false);
    fetchList();
  }, [fetchList])

  const handleGen = useCallback(() => {
    setGenVisible(true);
  }, [])

  const handleImportCancel = useCallback(() => {
    setImportVisible(false);
  }, []);

  // const handleImportShow = useCallback(() => {
  //   setImportVisible(true);
  // }, []);

  const handleImportOk = useCallback((file: File) => {
    return importPreActivateUsers(file).then((resp) => {
      if (isSuccessResponse(resp)) {
        fetchList();
        setImportVisible(false);
      } else if (!resp.message) {
        message.error('导入失败');
      }
    })
  }, [fetchList]);

  // const handlePreActivateCode = useCallback(() => {
  //   if (selectedRowKeys.length === 0) {
  //     message.warn('请先选择授权码');
  //     return;
  //   }
  //   if (list.filter(l => selectedRowKeys.includes(l.code)).some(l => l.used)) {
  //     message.warn('选中的授权码存在已使用的，请重新选择');
  //     return;
  //   }
  //   Modal.confirm({
  //     title: '确定要使用选中的授权码吗？',
  //     icon: <ExclamationCircleOutlined />,
  //     onOk() {
  //       return preActivateUsers({ licenseCodes: selectedRowKeys as string[] }).then((resp) => {
  //         if (isErrorResponse(resp)) {
  //           !resp.message && message.error('预授权失败');
  //         } else {
  //           message.success('预授权成功');
  //           setSelectedRowKeys([]);
  //           fetchList();
  //         }
  //       });
  //     },
  //   })
  // }, [selectedRowKeys, list, setSelectedRowKeys, fetchList]);

  const handlePreActivateCancel = useCallback(() => {
    setPreActivateVisible(false);
  }, []);

  const handlePreActivateOk = useCallback(() => {
    message.success('预授权成功');
    setPreActivateVisible(false);
    fetchList();
  }, [fetchList])

  // const handlePreActivateNum = useCallback(() => {
  //   setPreActivateVisible(true);
  // }, [])

  // const handlePreActivate = useCallback(({ key }) => {
  //   if (key === 'code') {
  //     handlePreActivateCode();
  //   } else if (key === 'num') {
  //     handlePreActivateNum();
  //   }
  // }, [handlePreActivateCode, handlePreActivateNum]);

  // const menu = useMemo(() => {
  //   return (
  //     <Menu onClick={handlePreActivate}>
  //       <Menu.Item key="code">通过授权码</Menu.Item>
  //       <Menu.Item key="num">通过指定数量</Menu.Item>
  //     </Menu>
  //   )
  // }, [handlePreActivate]);

  const rowSelectionAndDisabled = useMemo(() => ({
    ...rowSelection,
    getCheckboxProps: (record: ILicense) => ({
      disabled: record.used
    })
  }), [rowSelection])
  const canCreateAndDelete = CheckPermissionKeyInListHook(createAndDeleteUrl);

  return (
    <div className='normalPage'>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <h3>许可证列表</h3>
        <Space size="small">
          {canCreateAndDelete && <Button type="primary" onClick={handleGen}>生成</Button>}
          {canCreateAndDelete && <Button type="primary" className='tableDeleteButton' danger onClick={handleDelete}>批量删除</Button>}
        </Space>
      </div>
      <FilterForm onSearch={handleSearch} />
      <GTable
        rowKey="code"
        columns={columns}
        dataSource={list}
        loading={loading}
        rowSelection={rowSelectionAndDisabled}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange
        }}
      />
      <LicenseModal
        visible={genVisible}
        onCancel={handleGenCancel}
        onOk={handleOk}
        showStore={sysAdmin}
      />
      <ImportModal
        title="导入预授权用户"
        hint="支持文件格式：.xlsx 和 .xls。"
        accept=".xlsx,.xls"
        visible={importVisible}
        templateUrl={templateUrl}
        onCancel={handleImportCancel}
        onOk={handleImportOk}
      />
      <PreActivateModal
        visible={preActivateVisible}
        onCancel={handlePreActivateCancel}
        onOk={handlePreActivateOk}
      />
    </div>
  )
}

export default Licenses;
