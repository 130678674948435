import { useCallback, useState } from 'react';
import { message } from 'antd';
import { Image, View, Text } from '../../../../../components/reactComponents';
import { getFileSize } from '../../../../../util/tool';
import playIcon from '../../../../../assets/home_video_icon.png'
import downloadIcon from '../../../../../assets/home_download_icon.png';
import VideoModal from './VideoModal';
import {
  downloadFileByUrl,
  getFileNameByUrl,
} from "../../../../../util";
import { getHTTPSUrl } from "../../../../../util/tool";
import AntImage from './AntImage';

import './index.scss';

function getVideoIdByUrl(url: string) {
  const fileName = getFileNameByUrl(url);
  if (fileName) {
    const arr = fileName.split('.');
    return 'video' + arr[0];
  }
  return 'video-custom';
}


interface IProps {
  startTime: string;
  time?: number;
  username?: string;
  size: number;
  url: string;
  type: string;
  downloadUrls?: string[];
}

const TaskMediaItem = ({ startTime, time, username, size, url, type, downloadUrls }: IProps) => {
  const isPicture = type === 'image';
  const minute = time ? Math.floor(time / 60) : 0;
  const seconds = time ? time - minute * 60 : 0;
  console.log('downloadUrls', downloadUrls);
  const [show, setShow] = useState(false);
  const downloadVideos = useCallback((e) => {
    if (!isPicture) {
      if (downloadUrls && downloadUrls.length > 0) {
        // const downloadLink = downloadUrls.join(';');
        for (let index = 0; index < downloadUrls.length; index++) {
          const element = downloadUrls[index];
          downloadFileByUrl({ url: getHTTPSUrl(element), fileName: getFileNameByUrl(element) || 'default.mp4' });
        }
      } else {
        message.info({ content: '该视频暂不可下载' })
      }
    } else {
      downloadFileByUrl({ url: getHTTPSUrl(url), fileName: getFileNameByUrl(url) || 'default.png' });
    }
  }, [isPicture, downloadUrls, url])

  const id = getVideoIdByUrl(url);
  console.log(id);

  return (<View className='taskMediaItem' >
    <View className='mediaView'>
      {isPicture ? <AntImage src={url}
        // mode='aspectFill'
        className='image' /> :
        <View className='videoView' onClick={() => setShow(true)}>
          <Image src={playIcon} className='playIcon' />
        </View>}
    </View>
    <View className='mediaInfoView'>
      <View className='mediaSizeInfoView'>
        <View >
          <Text className='titleText'>{startTime}</Text>
          <View>
            {!isPicture && <Text className='sizeText'>{`共${minute > 0 ? minute + '分钟' : ''}${seconds}秒  `}</Text>}
            <Text className='sizeText'>{getFileSize(size)}</Text>
          </View>
        </View>
        <Image src={downloadIcon} className='downloadIcon' onClick={downloadVideos} />
      </View>
      <View>
        {username && <Text className='userText'>{username}{'  '}{isPicture ? '截图' : '录制'}</Text>}
      </View>
    </View>
    <VideoModal visible={show} onCancel={() => setShow(false)} url={url} id={id} />
  </View>)

}

export default TaskMediaItem;
