import { createContext, Dispatch, FC, useContext, useReducer } from "react";

export interface ITrendData {
  time: string;
  value: number;
}

export interface ITrendlist {
  title: string;
  value: number; // 存放查询天数 7，30
  id: number;
  data: ITrendData[];
}

export interface ITrendState {
  totalUser: ITrendlist;
  activeUser: ITrendlist;
  newUser: ITrendlist;
}

export const initState: ITrendState = {
  newUser: {
    title: "新增用户趋势",
    data: [],
    id: 3,
    value: 7,
  },
  activeUser: {
    title: "用户活跃趋势",
    data: [],
    id: 2,
    value: 7,
  },
  totalUser: {
    title: "累计用户趋势",
    data: [],
    id: 1,
    value: 7,
  },
};

function reducerm(state: ITrendState, action: any): any {
  switch (action.type) {
    case 1:
      const { totalUser } = action.payload.newData;
      console.log("===", { ...state, ...{ totalUser: totalUser } });

      return { ...state, ...{ totalUser: totalUser } };
    case 2:
      const { activeUser } = action.payload.newData;
      console.log("===", { ...state, ...{ activeUser: activeUser } });

      return { ...state, ...{ activeUser: activeUser } };
    case 3:
      const { newUser } = action.payload.newData;
      return { ...state, ...{ newUser: newUser } };
    default:
      return { ...state, ...(action.payload.newData || {}) };
  }
}

const trendContext = createContext<{
  data: ITrendState;
  dispatch: Dispatch<any>;
}>({ data: initState, dispatch: () => { } });

export const TrendProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(reducerm, initState);
  return (
    <trendContext.Provider value={{ data, dispatch }}>
      {children}
    </trendContext.Provider>
  );
};

export const useTrend = () => {
  return useContext(trendContext);
};
