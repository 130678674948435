import { ComponentType, FC } from "react";

type Component = ComponentType | [ComponentType, { [key: string]: any }];

interface Props {
  components: Component[];
}

const Compose: FC<Props> = ({ components, children }) => (
  <>
    {components.reverse().reduce((acc, curr) => {
      const [Provider, props] = Array.isArray(curr) ? [curr[0], curr[1]] : [curr, {}];
      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </>
);

export default Compose;
