import { useCallback, useMemo, useEffect, useRef, useState } from "react";
import { Space, message } from "antd";
import Table from '../../components/GTable';
import HeaderTitle from '../../components/HeaderTitle';
import useList from "../../hooks/useList";
import { chargeUserUrl } from "../../services/appUser";
import { queryDepartmentMember } from "../../services/department";
import { CheckPermissionKeyInListHook } from "../../containers/PermissionComponents";
import UserChargeModal from '../AppUsers/UserChargeModal';
import { minuteTimeString, isUserFreeze } from '../../util/tool';


interface ITem {
  title: string;
  dataIndex: string;
  render?: any;
}


const columnsList: ITem[] = [
  {
    title: "昵称",
    dataIndex: "nickname",
  },
  {
    title: '总分钟数',
    dataIndex: 'liveHdTotalSeconds',
    render: (value: number) => minuteTimeString(value),
  },
  {
    title: '剩余分钟数',
    dataIndex: 'liveHdAvailableSeconds',
    render: (value: number) => minuteTimeString(value),
  },
];


const UserTimeList = ({ active }: { active?: boolean }) => {
  const activeRef = useRef(active);
  const [chargeUserList, setChargeUserList] = useState<string[]>([]);
  const canCharge = CheckPermissionKeyInListHook(chargeUserUrl);

  const columns = useMemo(() => {
    if (canCharge) {
      const list = [...columnsList];
      list.push({
        title: "操作",
        dataIndex: "opt",
        render: (_: any, record: IAppUser) => {
          const freeze = isUserFreeze(record.status);
          return (
            <>
              {(canCharge && !freeze) && (
                <Space size="middle" style={{ marginRight: "1vw" }}>
                  <span
                    className={freeze ? 'tableDetailButton' : 'tableEditButton'}
                    onClick={freeze ? undefined : () => {
                      setChargeUserList([`${record.uid}`])
                    }}
                  >
                    {freeze ? '已冻结' : '充值'}
                  </span>
                </Space>
              )}
            </>
          );
        },
      });
      return list;
    }
    return columnsList;
  }, [canCharge]);

  const {
    list,
    loading,
    filter,
    pagination,
    fetchList,
  } = useList<IAppUser>(queryDepartmentMember);

  const handlePageChange = useCallback((page: number, pageSize?: number) => {
    fetchList({ ...filter, page: pageSize !== pagination.pageSize ? 1 : page, size: pageSize });
  }, [fetchList, filter, pagination]);

  useEffect(() => {
    if (activeRef.current !== active) {
      activeRef.current = active;
      if (activeRef.current) {
        handlePageChange(1);
      }
    }
  }, [active, handlePageChange]);

  const handldChargeCancel = useCallback(() => setChargeUserList([]), []);

  const handldChargeOK = useCallback(
    async ({ success }: { success: boolean }) => {
      if (success) {
        message.success("充值成功");
        handldChargeCancel();
        handlePageChange(1);
      }
    },
    [handlePageChange, handldChargeCancel]
  );

  return (
    <div>
      <HeaderTitle title='成员流量分配' />
      <Table
        rowKey="uid"
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange
        }}
      />
      <UserChargeModal
        uids={chargeUserList}
        visible={chargeUserList && chargeUserList.length > 0}
        onCancel={handldChargeCancel}
        onOk={handldChargeOK}
      />
    </div>
  )
}

export default UserTimeList;
