import { Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  cameraPanel,
  cameraTrend,
  userPanel,
  userTrend,
} from "../../services/statistics";
import HeaderTitle from '../../components/HeaderTitle';
import { isSuccessResponse } from "../../util";
import CardList from "./cardList";
import Trend from "./trend";

import user from '../../assets/data_icon_user_total.png';
import userAdd from '../../assets/data_icon_user_new.png';
import userToday from '../../assets/data_icon_user_active_today.png';
import userYesterday from '../../assets/data_icon_user_active_yesterday.png';

import todatTime from '../../assets/data_icon_live_broadcast_total.png';
import todayAvt from '../../assets/data_icon_live_broadcast_average.png';
import countToday from '../../assets/data_icon_live_broadcast_number_today.png';
import countYesterday from '../../assets/data_icon_live_broadcast_average_yesterday.png';

import { useTrend, ITrendlist, ITrendState } from "../../models/trend";
import { initState } from "../../models/trend";

import './index.scss';

const _ = require("lodash");
const dayjs = require("dayjs");

const userDataInfoList = [
  { icon: user, title: "累计用户总数", description: "" },
  { icon: userAdd, title: "今日新增用户数", description: "" },
  { icon: userToday, title: "今日活跃用户", description: "" },
  { icon: userYesterday, title: "昨日活跃用户", description: "", },
];

const cameraDataInfoList = [
  { icon: todatTime, title: "今日直播总时长", description: "", timetype: "秒", },
  { icon: todayAvt, title: "今日场均直播时长", description: "", timetype: "秒", },
  { icon: countToday, title: "今日直播次数", description: "" },
  { icon: countYesterday, title: "昨日直播次数", description: "" },
];

const descMap = [
  "todayUserCount",
  "todayNewUser",
  "todayActiveUser",
  "yesterdayActiveUser",
];

function getStartTime(day: number) {
  const endTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const startTime = dayjs()
    .subtract(day, "day")
    .format("YYYY-MM-DD HH:mm:ss");
  return { startTime, endTime };
}

function getTime(time: number) {
  if (time < 60) {
    return [String(time), "秒"];
  } else if (time < 3600) {
    const newtime = (time / 60).toFixed(0);
    return [String(newtime), "分钟"];
  } else {
    const newtime = (time / 60 / 60).toFixed(0);
    return [String(newtime), "小时"];
  }
};

interface IList {
  icon: string;
  title: string;
  description: string;
  timetype?: string;
}

const Statistics = () => {
  const { data, dispatch } = useTrend();
  const [useList, setUserList] = useState<IList[]>(userDataInfoList);
  const [cameraList, setCameraList] = useState<IList[]>(cameraDataInfoList);
  const [cameraTrendData, setCameraTrendData] = useState<ITrendlist>();
  const [trendlist, setTrendlist] = useState<ITrendState>();

  useEffect(() => {
    setTrendlist(data);
  }, [data]);

  const getUserPanel = useCallback(() => {
    userPanel().then((res) => {
      if (isSuccessResponse(res)) {
        const newlist = _.cloneDeep(userDataInfoList);
        newlist.forEach((item: any, idx: any) => {
          const key = descMap[idx];
          // TODO 动态key的type
          // @ts-ignore
          item.description = (res.data[key] || 0).toString();
        });
        setUserList(newlist);
      }
    });
  }, []);

  const getCameraPanel = useCallback(() => {
    cameraPanel().then((resp) => {
      if (isSuccessResponse(resp)) {
        const newCameralist = _.cloneDeep(cameraDataInfoList);
        const {
          todayLiveCount,
          todayLiveSecondCount,
          yesterdayLiveCount,
        } = resp.data;
        const todayLiveSecond = todayLiveSecondCount || 0;
        const todayLiveCountNumber = todayLiveCount || 0;
        const muchTime =
          todayLiveCountNumber === 0 ? 0 : todayLiveSecond / todayLiveCountNumber;
        const liveTimeArr = getTime(todayLiveSecond);
        newCameralist[0].description = liveTimeArr[0];
        newCameralist[0].timetype = liveTimeArr[1];

        const muchTimeArr = getTime(muchTime);
        newCameralist[1].description = muchTimeArr[0];
        newCameralist[1].timetype = muchTimeArr[1];

        newCameralist[2].description = todayLiveCountNumber ? todayLiveCount.toString() : '0';
        newCameralist[3].description = yesterdayLiveCount ? yesterdayLiveCount.toString() : '0';
        setCameraList(newCameralist);
      }
    });
  }, []);

  const getCameraTrend = useCallback((day: number) => {
    const time = getStartTime(day);
    cameraTrend(time).then((res) => {
      if (isSuccessResponse(res)) {
        let liveSecondCount: ITrendlist = {
          title: "直播总时长趋势",
          data: [],
          id: 0,
          value: day,
        };
        res.data.forEach((item, index) => {
          const time = dayjs(item.statTime).format("MM-DD");
          liveSecondCount.data[index] = { time, value: item.liveSecondCount };
        });
        setCameraTrendData(liveSecondCount);
      }
    });
  }, []);

  const getUserTrend = useCallback((day: number, id?: number) => {
    const time = getStartTime(day);
    userTrend(time).then((res) => {
      if (isSuccessResponse(res)) {
        const newData = _.cloneDeep(initState);
        res.data.forEach((item, index) => {
          const time = dayjs(item.statTime).format("MM-DD");
          for (const key in newData) {
            newData[key].value = day;
            // @ts-ignore
            newData[key].data[index] = { time, value: item[key] };
          }
        });
        if (id) {
          dispatch({
            type: id,
            payload: { newData: newData },
          });
        } else {
          dispatch({
            type: "update",
            payload: { newData: newData },
          });
        }
      }
    });
  }, [dispatch])

  const getData = useCallback(() => {
    getUserPanel();
    getCameraPanel();
    getCameraTrend(7);
    getUserTrend(7);
  }, [getUserPanel, getCameraPanel, getCameraTrend, getUserTrend]);

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className='panelCard'>
        <div>
          <HeaderTitle title='用户统计' />
        </div>
        <div>
          <Row gutter={[16, 16]}>
            {useList.map((item, index) => (
              <Col span={6} key={`1_${index}`}>
                <CardList
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <div className='panelCard'>
        <div>
          <HeaderTitle title='直播统计' />
        </div>
        <div>
          <Row gutter={[16, 16]}>
            {cameraList.map((item, index) => (
              <Col span={6} key={`2_${index}`}>
                <CardList
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  timetype={item.timetype}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div>
        <Row gutter={[24, 24]}>
          {trendlist &&
            Object.values(trendlist).map((item, index) => (
              <Col span={12} key={`usertrend_${index}`}>
                <Trend
                  data={item.data}
                  title={item.title}
                  value={item.value}
                  id={item.id}
                  onActive={(timeType: any) => {
                    getUserTrend(timeType, item.id);
                  }}
                />
              </Col>
            ))}
          {cameraTrendData && (
            <Col span={12}>
              <Trend
                data={cameraTrendData.data}
                title={cameraTrendData.title}
                value={cameraTrendData.value}
                id={cameraTrendData.id}
                onActive={(timeType: number) => {
                  getCameraTrend(timeType);
                }}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Statistics;
