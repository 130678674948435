import { useEffect, useCallback, useState } from "react";
import { Button } from 'antd';
import HeaderTitle from '../../components/HeaderTitle';
import TitleLabel from '../../components/TitleLabel';
import { useUser } from "../../models/user";
import { updateSysLogoUrl, updateSysNameUrl } from '../../services/systemUser';
import { CheckPermissionKeyInListHook } from '../../containers/PermissionComponents';
import DetailModal from './DetailModal';

import './index.scss';

const CompanyInfoSetting = () => {
  const [show, setShow] = useState(false);
  const { user, company, getDetial } = useUser();
  const logo = user && user.sysLogo ? user.sysLogo : undefined;
  const sysName = company ? company.sysName : process.env.REACT_APP_TITLE;

  useEffect(() => {
    getDetial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showUpdateModal = useCallback(() => { setShow(true) }, []);
  const hideUpdateModal = useCallback(() => { setShow(false) }, []);
  const onOk = useCallback(() => { hideUpdateModal(); getDetial(); }, [hideUpdateModal, getDetial]);

  const canUpdate = CheckPermissionKeyInListHook(updateSysLogoUrl) && CheckPermissionKeyInListHook(updateSysNameUrl);


  return (
    <div className='normalPage companyInfoSetting'>
      <HeaderTitle title='组织基本信息' />
      <div className='infoView'>
        <div className='userDetailInfo'>
          <TitleLabel style={{ marginBottom: 10 }} title='组织名称：' value={sysName} />
          <TitleLabel style={{ marginBottom: 10 }} title='组织Logo：' value={
            logo ? <img src={logo} className='logoImage' alt="Logo" /> : null}
          />
        </div>
        {canUpdate && <Button onClick={showUpdateModal} className='normarlEditButton'>
          编辑
        </Button>}
      </div>
      {company?.sysAccountId && <DetailModal
        visible={show}
        id={company?.sysAccountId}
        sysLogo={logo}
        sysName={sysName || ''}
        onCancel={hideUpdateModal}
        onOk={onOk}
      />}
    </div>
  );
};

export default CompanyInfoSetting;
