import { FC, useEffect, useCallback } from "react";
import { Form, Input, Modal } from "antd";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};


interface IProps {
  department?: IDepartments;
  visible: boolean;
  confirming?: boolean;
  onCancel: () => void;
  onOk: (p: IDepartments) => void;
}

const DepartmentModal: FC<IProps> = ({
  department,
  visible,
  onCancel,
  onOk,
  confirming,
}) => {
  const getInitDepartment = useCallback((depar: IDepartments | undefined) => {
    return {
      description: undefined,
      parentId: undefined,
      name: undefined,
      ...depar,
    };
  }, []);

  const title = department && department.id !== "0" ? "编辑部门" : "新建部门";
  const initPermission = getInitDepartment(department);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      console.log("显示", department);
      const p = getInitDepartment(department);
      form.resetFields();
      form.setFieldsValue(p);
    }
  }, [visible, department, form, getInitDepartment]);

  const onConfirm = useCallback(() => {
    const error = form.getFieldsError();
    console.log("error", error);
    if (error) {
      for (let index = 0; index < error.length; index++) {
        const element = error[index];
        if (element && element.errors && element.errors.length > 0) {
          return;
        }
      }
    }
    if (department) {
      onOk({
        ...form.getFieldsValue(),
        id: department.id,
        parentId: department.parentId,
      });
    }
  }, [form, department, onOk]);

  const { name, description } = initPermission;

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      confirmLoading={confirming}
    >
      <Form layout="horizontal" form={form}>
        <FormItem
          {...formItemLayout}
          hasFeedback
          label="名称"
          name="name"
          initialValue={name}
          rules={[
            { required: true, message: "请输入部门名称" },
            {
              pattern: /^[一-龥a-zA-Z0-9]{1,20}$/,
              message: "需要1-20个字符，只能输入中文、数字或英文字母",
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          {...formItemLayout}
          hasFeedback
          label="简介"
          name="description"
          initialValue={description}
          rules={[{ min: 1, max: 200, message: "最多能输入200个字符" }]}
        >
          <Input />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default DepartmentModal;
