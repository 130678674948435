import { Input } from "antd";
import noSeeIcon from '../../assets/login_icon_closed_eyes.png';
import seeIcon from '../../assets/login_icon_open_eyes.png';


const PasswordInput = (props: any) => {
  return (
    <Input.Password
      {...props}
      iconRender={(visible) => <img src={visible ? seeIcon : noSeeIcon}
        alt='eyeIcon' style={{ width: '25px', height: '20px' }}
      />
      }
    />
  );
}

export default PasswordInput;
