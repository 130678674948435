import { FC } from "react";
import './index.scss';

interface IProps {
  title: any;
}

const HeaderTitle: FC<IProps> = ({ title }) => {
  return (
    <span>
      <span className='headerTitle'>
        <span className='colorBlock' />
        <span>{title}</span>
      </span>
    </span>
  )
}

export default HeaderTitle;
