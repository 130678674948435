import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isSuccessResponse } from "../util";

interface IFilter extends IPage {
  [key: string]: any;
}

interface IOptions {
  params?: IPage;
  batchDeleteFn?: (
    keys: React.Key[]
  ) => Promise<IResponse<{}> | IErrorResponse>;
  crossPageSelect?: boolean;
}

export default function useList<T>(
  fetchListFn: (
    params: IFilter
  ) => Promise<(IResponse<IPagination<T>> | IErrorResponse) | null>,
  options?: IOptions
) {
  const { params = {}, batchDeleteFn, crossPageSelect } = options || {};
  const { page = 1, size = 10 } = params;
  const filterRef = useRef<IFilter>({ page, size });
  const [list, setList] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const paginationRef = useRef({
    page,
    pageSize: size,
    totalPages: 1,
    total: 0,
  });

  const fetchList = useCallback(
    (params?: IFilter) => {
      const newFilter = params || filterRef.current;
      const { page = 1, size = 10 } = newFilter;
      filterRef.current = newFilter;
      // console.log('newFilter', newFilter, size);
      setLoading(true);
      fetchListFn({ ...newFilter, page: page - 1, size })
        .then((res) => {
          if (isSuccessResponse(res)) {
            setList(res.data.content);
            paginationRef.current = {
              page,
              pageSize: size,
              totalPages: res.data.totalPages,
              total: res.data.totalElements,
            };
          }
        })
        .finally(() => setLoading(false));
    },
    [fetchListFn]
  );

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!crossPageSelect) {
      setSelectedRowKeys([]);
    }
  }, [list, crossPageSelect]);

  const fetchNext = useCallback(() => {
    const { page, pageSize, totalPages } = paginationRef.current;
    if (page === totalPages) {
      return;
    }
    fetchList({ ...filterRef.current, page: page + 1, size: pageSize });
  }, [fetchList]);

  const fetchPre = useCallback(() => {
    const { page, pageSize } = paginationRef.current;
    if (page === 1) {
      return;
    }
    fetchList({ ...filterRef.current, page: page - 1, size: pageSize });
  }, [fetchList]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: setSelectedRowKeys,
    }),
    [selectedRowKeys, setSelectedRowKeys]
  );

  const handleDelete = useCallback(() => {
    if (!batchDeleteFn) {
      return;
    }
    if (selectedRowKeys.length === 0) {
      message.warn("请先勾选需要删除项");
      return;
    }

    Modal.confirm({
      title: "确定要删除已选择项吗？",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        return batchDeleteFn(selectedRowKeys).then((resp) => {
          if (isSuccessResponse(resp)) {
            message.success("删除成功");
            setSelectedRowKeys([]);
            fetchList({ ...filterRef.current, page: 1 });
          } else if (!resp.message) {
            message.error("删除失败");
          }
        });
      },
    });
  }, [batchDeleteFn, selectedRowKeys, fetchList]);

  return {
    list,
    loading,
    filter: filterRef.current,
    pagination: paginationRef.current,
    selectedRowKeys,
    rowSelection,
    setSelectedRowKeys,
    fetchList,
    fetchNext,
    fetchPre,
    handleDelete,
  };
}
