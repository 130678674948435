import { FC, } from "react";

const Text: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({ children, ...rest }) => {
  return (
    <span
      {...rest}
    >
      {children}
    </span>
  )
}

export default Text;
