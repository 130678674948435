import { createContext, FC, useCallback, useContext, useReducer } from "react";

const TOKEN_KEY = 'token';
const defaultToken = localStorage.getItem(TOKEN_KEY) || ''

enum Action {
  signin = 'signin',
  signout = 'signout',
}

interface IAuthState {
  token: string;
}

const initialState: IAuthState = {
  token: defaultToken
};

type AuthPayload = {
  [Action.signin]: IAuthState;
  [Action.signout]: undefined
}

type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

function reducer(state: IAuthState, action: AuthActions): IAuthState {
  switch (action.type) {
    case Action.signin:
      localStorage.setItem(TOKEN_KEY, action.payload.token);
      return { token: action.payload.token };
    case Action.signout:
      localStorage.removeItem(TOKEN_KEY);
      return { token: '' };
    default:
      return state;
  }
}

const authContext = createContext<{
  data: IAuthState;
  signin: (token: string) => void;
  signout: () => void;
}>({ data: initialState, signin: () => { }, signout: () => { } });

export const AuthProvider: FC = ({ children }) => {
  const [data, dispatch] = useReducer(reducer, initialState);

  const signin = useCallback((token: string) => {
    dispatch({
      type: Action.signin,
      payload: { token },
    });
  }, []);

  const signout = useCallback(() => {
    dispatch({ type: Action.signout })
  }, []);

  return (
    <authContext.Provider value={{ data, signin, signout }}>
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(authContext);
};
