
import request from "../util/request";

const addPermissionUrl = '/sys/sysPermission/add';
const editPermissionUrl = '/sys/sysPermission/update';
const deletePermissionUrl = '/sys/sysPermission/delete';

export {
  addPermissionUrl,
  editPermissionUrl,
  deletePermissionUrl,
}

interface IPermissionParam {
  icon?: string;
  pPermissionId?: string;
  permissionKey?: string;
  permissionName?: string;
  permissionType?: number;
  sort?: number;
  uri?: string;
}

interface IPermissionEditParam extends IPermissionParam {
  id: string;
}

export function addPermission({ icon, pPermissionId, permissionKey, permissionName, permissionType, sort, uri }: IPermissionParam) {
  return request<IResponse<{}> | IErrorResponse>(addPermissionUrl, {
    method: 'POST',
    data: { icon, pPermissionId, permissionKey, permissionType, sort, permissionName, uri },
  })
}

export function editPermission({ id, icon, pPermissionId, permissionKey, permissionName, permissionType, sort, uri }: IPermissionEditParam) {
  return request<IResponse<{}> | IErrorResponse>(editPermissionUrl, {
    method: 'POST',
    data: { id, icon, pPermissionId, permissionKey, permissionType, sort, permissionName, uri },
  })
}

export function deletePermission(ids: string[]) {
  return request<IResponse<{}> | IErrorResponse>(deletePermissionUrl, {
    method: 'POST',
    data: ids,
  })
}

