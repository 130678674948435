import { Component } from "react";
import Videojs from "video.js";
import "video.js/dist/video-js.css";

class VideoPlayer extends Component {


  componentWillUnmount() {
    // 销毁播放器
    console.log('video componentWillUnmount')
    if (this.player) {
      this.player.dispose();
    }
  }


  componentDidMount() {
    const { src, height, width, id } = this.props;
    if (!this.player) {
      const p = Videojs.getPlayer(id);
      console.log(p);
      if (p) {
        this.player = p;
        this.player.options({
          height: height || '300',
          width: width || '300',
          bigPlayButton: true,
          textTrackDisplay: false,
          errorDisplay: false,
          crossOrigin: "anonymous",
          controlBar: true,
          type: "application/x-mpegURL",
        });
      } else {
        this.player = Videojs(
          id,
          {
            height: height || '300',
            width: width || '300',
            bigPlayButton: true,
            textTrackDisplay: false,
            errorDisplay: false,
            crossOrigin: "anonymous",
            controlBar: true,
            type: "application/x-mpegURL",
          },
          function () {
            // this.play();
          }
        );
      }
    }
    console.log('componentDidMount', this.player);
    this.player.src({ src });
  }
  render() {
    const { id } = this.props;
    return (
      <video
        id={id}
        className="video-js"
        controls
        preload="auto"
      ></video>
    );
  }
}

export default VideoPlayer;
