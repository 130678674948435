import { message } from "antd";
import { isErrorResponse } from ".";

interface MyRequestInit extends RequestInit {
  data?: number | string | { [key: string]: any } | FormData;
  params?: { [key: string]: any },
  json?: boolean,
}

export default function request<T>(url: string, init?: MyRequestInit): Promise<T> {
  const token = localStorage.getItem('token');
  const isFormData = init?.data instanceof FormData;
  const isJson = isFormData ? false : (init?.json == null ? true : init.json);
  const data = {
    ...init,
    headers: {
      ...(isJson ? { 'Content-Type': 'application/json' } : {}),
      ...init?.headers,
      ...(token ? { 'Authorization': token } : {}),
      'platform-type': 'web',
    },
  }
  if (init?.data) {
    data.body = isFormData ? init.data as FormData : JSON.stringify(init.data)
  }
  let realUrl = `/api/${url}`
  if (init?.params) {
    const paramsStrArr = Object.entries(init.params)
      .filter(([, value]) => value != null)
      .map(([key, value]) => `${key}=${value}`);
    realUrl = `${realUrl}?${paramsStrArr.join('&')}`
  }
  return fetch(realUrl, data)
    .then((res) => {
      // console.log('res', res);
      const respContentType = res.headers.get('Content-Type');
      if (respContentType === 'application/vnd.ms-excel') {
        return res.blob();
      }
      return res.status !== 204 ? res.json() : res.text()
    })
    .then(res => {
      if (res instanceof Blob) {
        return res;
      }
      if (res && (res.code === '527' || res.code === '505')) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      if (isErrorResponse(res) && res.message) {
        message.error(res.message)
      }
      return res;
    });
}