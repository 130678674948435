import { createContext, FC, useContext, useState } from "react";
import { getDepartmentsPage } from "../services/department";
import { isSuccessResponse } from "../util";

const departmentContext = createContext<{
  departmentList: IDepartments[];
  getDepartmentList: () => void;
}>({ departmentList: [], getDepartmentList: () => { } });

export const DepartmentProvider: FC = ({ children }) => {
  const [departmentList, setDepartmentList] = useState<IDepartments[]>([]);

  const getDepartmentList = () => {
    getDepartmentsPage({ page: 0, size: 10000 }).then((resp) => {
      if (isSuccessResponse(resp)) {
        setDepartmentList(resp.data.content);
      }
    });
  };

  return (
    <departmentContext.Provider value={{ departmentList, getDepartmentList }}>
      {children}
    </departmentContext.Provider>
  );
};

export const useDepartment = () => {
  return useContext(departmentContext);
};
