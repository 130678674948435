
import request from "../util/request";

const livingPageUrl = '/glive/web/tasks/living_record/page';
const livingDetailUrl = '/glive/web/tasks/living_record/{id}';

export {
    livingPageUrl,
    livingDetailUrl
}

interface ILivingPageParams{
    page?:number
    size?:number
    status?:string
}

export function livingPage(params: ILivingPageParams) {
  return request<IResponse<IPagination<ILiving>> | IErrorResponse>(livingPageUrl, {
    method: 'GET',
    params,
  })
}

export function livingDetail(id:number){
  const url = livingDetailUrl.replace('{id}', `${id}`);
  return request<IResponse<ILiving> | IErrorResponse>(url, {
    method: 'GET',
  })
}
