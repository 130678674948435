import { View } from '../../../../components/reactComponents';
import AccessoryItem from './AccessoryItem';

import './index.scss';


interface IProps {
  list: IExtendVOSItem[];
}


const AccessoryList = ({ list }: IProps) => {

  return (<View className='accessoryList' >
    {list.map((i: IExtendVOSItem) => {
      return <AccessoryItem url={i.accessory} key={i.id} />
    })}
  </View>)

}

export default AccessoryList;
