import { useEffect, useState, useCallback, useRef } from "react";
import { Descriptions, Spin, Row, Col, Button, message, Form, InputNumber } from "antd";
import { useParams } from "react-router";
import { CheckPermissionKeyInListHook } from '../../../containers/PermissionComponents';
import { companyChargeUrl, companyTimeInfo, companyCharge } from "../../../services/company";
import { isSuccessResponse } from "../../../util";
import FormModal from "../../../components/FormModal";
import { IParams } from './CompanyBaseInfo';
import TimeProgress from '../../../components/TimeProgress';
import styles from './index.module.css';


export interface IIntoItem {
  title: string;
  unit: string;
  color: string;
  totalKey: string;
  resetKey: string;
  chargeKey: string;
  unitCount: number;
}

const liveInfoDataList: IIntoItem[] = [
  {
    title: '通话视频分钟数', unit: '分钟', color: '#4AD9D9',
    totalKey: 'liveHdTotalSeconds', resetKey: 'liveHdAvailableSeconds',
    chargeKey: 'liveHdSeconds', unitCount: 60,
  },
  // {
  //   title: 'HD通话视频分钟数', unit: '分钟', color: '#4AD9D9',
  //   totalKey: 'liveHdTotalSeconds', resetKey: 'liveHdAvailableSeconds',
  //   chargeKey: 'liveHdSeconds', unitCount: 60,
  // },
  {
    title: 'FHD通话视频分钟数', unit: '分钟', color: '#7ECEFD',
    totalKey: 'liveFhdTotalSeconds', resetKey: 'liveFhdAvailableSeconds',
    chargeKey: 'liveFhdSeconds', unitCount: 60,
  },
  {
    title: '2K通话视频分钟数', unit: '分钟', color: '#2185C5',
    totalKey: 'live2KTotalSeconds', resetKey: 'live2KAvailableSeconds',
    chargeKey: 'live2KSeconds', unitCount: 60,
  },
];

const recordInfoDataList: IIntoItem[] = [
  {
    title: 'HD录屏视频分钟数', unit: '分钟', color: '#ACEBAE',
    totalKey: 'recordHdTotalSeconds', resetKey: 'recordHdAvailableSeconds',
    chargeKey: 'recordHdSeconds', unitCount: 60,
  },
  {
    title: 'FHD录屏视频分钟数', unit: '分钟', color: '#85DB18',
    totalKey: 'recordFhdTotalSeconds', resetKey: 'recordFhdAvailableSeconds',
    chargeKey: 'recordFhdSeconds', unitCount: 60,
  },
  {
    title: '2K录屏视频分钟数', unit: '分钟', color: '#A7C520',
    totalKey: 'record2KTotalSeconds', resetKey: 'record2KAvailableSeconds',
    chargeKey: 'record2KSeconds', unitCount: 60,
  },
];

const storeInfoDataList: IIntoItem[] = [
  {
    title: '存储量', unit: 'G', color: '#FFE11A',
    totalKey: 'cloudStorageTotalSpace', resetKey: 'cloudStorageAvailableSpace',
    chargeKey: 'cloudStorage', unitCount: 1024 * 1024,
  },
];

export const infoList = [
  { title: '通话时长信息', list: [liveInfoDataList[0]] },
  // { title: '录屏时长信息', list: recordInfoDataList },
  { title: '存储信息', list: storeInfoDataList },
];

export {
  liveInfoDataList,
  recordInfoDataList,
  storeInfoDataList,
}

const CompanyTimeInfo = ({ active }: { active: boolean }) => {
  const activeRef = useRef(false);
  const params = useParams<IParams>();
  const [loading, setLoading] = useState(false);
  const [chargeInfo, setChargeInfo] = useState<IIntoItem | null>(null);
  const [data, setData] = useState<ICompany | null>(null);

  const queryInfo = useCallback(() => {
    setLoading(true);
    companyTimeInfo({ id: parseInt(params.id) }).then((resp) => {
      if (isSuccessResponse(resp)) {
        console.log(resp.data);
        setData(resp.data)
      }
    }).finally(() => setLoading(false));
  }, [params.id]);

  const handleOk = useCallback(async (values: any) => {
    console.log('values', values);
    if (chargeInfo) {
      const { chargeKey, unitCount } = chargeInfo;
      const value = values[chargeKey];
      return companyCharge({
        [chargeKey]: value * unitCount,
        companyId: parseInt(params.id)
      }).then((resp) => {
        if (isSuccessResponse(resp)) {
          setChargeInfo(null);
          queryInfo();
        } else if (!resp.message) {
          message.error('充值失败');
        }
      });
    }
    return false;
  }, [queryInfo, chargeInfo, params.id]);

  useEffect(() => {
    if (activeRef.current !== active) {
      activeRef.current = active;
      if (activeRef.current) {
        queryInfo();
      }
    }
  }, [queryInfo, active]);

  const canCharge = CheckPermissionKeyInListHook(companyChargeUrl);

  return (
    <Spin spinning={loading}>
      {data && <div className={styles.timeInfo}>
        {
          infoList.map((listItem) => {
            const { title, list } = listItem;
            return <div key={title}>
              <Descriptions title={title} />
              <Row>
                {list.map((item: IIntoItem) => {
                  const { title, unit, color, totalKey, resetKey, unitCount } = item;
                  const info = data as any;
                  const total = Math.floor(info[totalKey] / unitCount);
                  const value = Math.floor(info[resetKey] / unitCount);
                  return <Col span={12} className={styles.col} key={totalKey}>
                    <TimeProgress
                      title={title}
                      strokeColor={color}
                      unit={unit}
                      total={total}
                      value={value}
                    />
                    {canCharge && <Button className={styles.chargeBtn} onClick={() => setChargeInfo(item)}>
                      充值
                    </Button>}
                  </Col>
                })}
              </Row>
            </div>
          })
        }
        {chargeInfo && <FormModal
          title="充值"
          visible={!!chargeInfo}
          onCancel={() => setChargeInfo(null)}
          onOk={handleOk}
        >
          <Form.Item
            name={chargeInfo.chargeKey}
            label={chargeInfo.title}
            rules={[{ required: true, message: `${chargeInfo.title}必填` }]}
          >
            <InputNumber />
          </Form.Item>
        </FormModal>}
      </div>}
    </Spin>
  )
}

export default CompanyTimeInfo;
