import { useCallback } from "react";
import { useParams } from "react-router";
import { companyDetail } from "../../../services/company";
import BaseInfoContainer from './BaseInfoContainer';

export interface IParams {
  id: string;
}

const CompanyBaseInfo = ({ active }: { active: boolean }) => {
  const params = useParams<IParams>();
  const detailFun = useCallback(() => {
    return companyDetail(parseInt(params.id));
  }, [params.id]);
  return <BaseInfoContainer baseInfoFun={detailFun} active={active} />
}

export default CompanyBaseInfo;
