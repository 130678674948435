import { FC, } from "react";

const Image: FC<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = ({ children, alt, ...rest }) => {
  return (
    <img
      alt={alt}
      {...rest}
    >
      {children}
    </img>
  )
}

export default Image;
