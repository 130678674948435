import { companyChargeRecordUrl, } from "../../services/company";
import { CheckPermissionKeyInListHook } from '../../containers/PermissionComponents';
import RecordTable from '../CompanyManager/CompanyDetail/CompanyChargeRecord/Table';

const CompanyChargeRecord = () => {
  const companyChargeRecord = CheckPermissionKeyInListHook(companyChargeRecordUrl);
  return (
    companyChargeRecord ? <div className='normalPage'>
      <RecordTable />
    </div> : null
  )
}

export default CompanyChargeRecord;
