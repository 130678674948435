
import request from "../util/request";

const usersPageUrl = '/glive/web/users/page';
const deleteUsersUrl = '/glive/web/users'

export {
  usersPageUrl,
  deleteUsersUrl
}

interface IUserPageParams {
  page?: number
  size?: number
}

export function usersPage(params: IUserPageParams) {
  return request<IResponse<IPagination<IAppUser>> | IErrorResponse>(usersPageUrl, {
    method: 'GET',
    params,
  })
}

export function deleteUser(uid: number) {
  return request<IResponse<{}> | IErrorResponse>(deleteUsersUrl, {
    method: 'DELETE',
    params: { uid }
  })
}
