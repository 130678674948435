import { downloadFileFromBlob } from "../util";
import request from "../util/request";

const licensesPageUrl = '/glive/web/licenses/page';
const createAndDeleteUrl = '/glive/web/licenses';
const preActivateTemplateUrl = '/glive/web/licenses/pre_activate_template';
const importUsernamesUrl = '/glive/web/licenses/import/usernames';
const preActivateUserUrl = '/glive/web/licenses/pre_activate_user';


export enum LicensesTypeEnum {
  live = 'live',
  record = 'record',
}

export enum LicensesResolutionEnum {
  hd = 'hd',
  fhd = 'fhd',
  _2k = '2k',
}

export {
  licensesPageUrl,
  createAndDeleteUrl,
  preActivateTemplateUrl,
  importUsernamesUrl,
  preActivateUserUrl,
}

export function fetchLicenseList(params: IPage) {
  return request<IResponse<IPagination<ILicense>> | IErrorResponse>(licensesPageUrl, {
    method: 'GET',
    params,
  })
}

export function createLicense(data: ILicense) {
  return request<IResponse<{}> | IErrorResponse>(createAndDeleteUrl, {
    method: 'POST',
    data,
  })
}

export function batchDeleteLicense(codes: any[]) {
  return request<IResponse<{}> | IErrorResponse>(createAndDeleteUrl, {
    method: 'DELETE',
    data: { codes },
  })
}

export function fetchImportTemplate() {
  return request<IResponse<{ url: string }> | IErrorResponse>(preActivateTemplateUrl, {
    method: 'GET',
  })
}

export function importPreActivateUsers(file: File) {
  const body = new FormData();
  body.append('file', file);
  return request<IResponse<{}> | IErrorResponse>(importUsernamesUrl, {
    method: 'POST',
    body,
    json: false,
  })
}

interface IPreActivateUserCodes {
  licenseCodes: string[]
}

interface IPreActivateUserNum {
  num: number;
}

export function preActivateUsers(data: IPreActivateUserCodes | IPreActivateUserNum) {
  return request<Blob | IErrorResponse>(preActivateUserUrl, {
    method: 'POST',
    data,
  }).then((res) => {
    if (res instanceof Blob) {
      downloadFileFromBlob(res, `${Date.now()}.xlsx`);
      return { code: 0 }
    }
    return res;
  })
}
