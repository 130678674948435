import { FC, } from "react";

const View: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ children, ...rest }) => {
  return (
    <div
      {...rest}
    >
      {children}
    </div>
  )
}

export default View;
