import { FC } from "react"
import { Modal } from "antd"
import VideoPlayer from '../../../../../components/Video';

interface IProps {
  visible: boolean;
  url: string;
  id: string;
  onCancel: () => void;
}

const VideoModal: FC<IProps> = ({ id, visible, url, onCancel }) => {
  console.log('url', url);
  const realUrl = url.replace('http://', 'https://')
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      modalRender={(modal) => {
        return (<div
          className='ant-modal-content'
          style={{
            width: '100%', height: '100%', backgroundColor: '#fff',
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }}
        >
          <div >
            {visible && <VideoPlayer id={id} src={realUrl} width='600' height='450' />}
          </div>

          {/* <div style={{ backgroundColor: '#fff', width: '1rem', height: '1rem' }} /> */}
          {/* <div className='ant-modal-content'>{modal}</div> */}
        </div>)
      }}
    >
      <div style={{ backgroundColor: '#fff', width: '100%', height: '100%' }}></div>
    </Modal>
  )
}

export default VideoModal;
