import { FC, useCallback, useState } from "react";
import { Select } from "antd";

const { Option } = Select


interface IItem {
  text: string;
  value: string | number;
}

interface IProps {
  fetchData: (search: string) => Promise<IItem[]>,
  defaultOptions?: IItem[];
}

const SearchInput: FC<IProps> = ({ fetchData, defaultOptions = [], ...rest }) => {

  const [data, setData] = useState<IItem[]>(defaultOptions);

  const handleSearch = useCallback((value: string) => {
    if (!value) {
      setData([]);
    } else {
      fetchData(value).then(setData)
    }
  }, [fetchData]);
  // console.log(data);
  return (
    <Select
      {...rest}
      showSearch
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      notFoundContent={null}
    >
      {
        data.map(d => (
          <Option key={d.value} value={d.value}>{d.text}</Option>
        ))
      }
    </Select>
  )
}

export default SearchInput;
