import { useCallback, useMemo, useState } from "react";
import { Button, message, Space, Popconfirm } from "antd";
import {
  rolePage,
  editRoleUrl,
  addRoleUrl,
  deleteRoleUrl,
  getRole,
  deleteRole,
  addRole,
  editRole,
} from "../../services/role";
import useList from "../../hooks/aepUseList";
import GTable from '../../components/GTable';
import { isSuccessResponse } from "../../util";
import { CheckPermissionKeyInListHook } from "../../containers/PermissionComponents";
import RoleModal from "./RoleModal";
import { useUser } from "../../models/user";

const RoleList = () => {
  const { list, loading, filter, pagination, fetchList } =
    useList<IRole>(rolePage);

  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const { user } = useUser();
  const [checkedkeys, setCheckedkeys] = useState<string[]>([]);
  const [data, setData] = useState<IRolePermission>({} as IRolePermission);

  const [isRoleModal, setRoleModal] = useState<boolean>(false);

  const canEdit = CheckPermissionKeyInListHook(editRoleUrl);
  const canDelete = CheckPermissionKeyInListHook(deleteRoleUrl);
  const canAdd = CheckPermissionKeyInListHook(addRoleUrl);

  const handlePageChange = useCallback(
    (page: number, size?: number) => {
      fetchList({
        ...filter,
        current: size !== pagination.size ? 1 : page,
        size,
      });
    },
    [fetchList, filter, pagination]
  );

  //TODO 点击修改,获取用户信息
  const handleEdit = useCallback(
    async (role: IRole) => {
      if (user && user.permissions) {
        setPermissions(user.permissions);
      }
      getRole(role.id).then((resp) => {
        if (isSuccessResponse(resp)) {
          const { data } = resp;
          const checkedkeys: string[] = [];
          data.permissions.forEach((item) => {
            item.id && item.permissionKey && checkedkeys.push(`${item.id}`);
          });
          setData(data);
          setCheckedkeys(checkedkeys);
          setRoleModal(true);
        }
      });
    },
    [user]
  );

  //TODO 点击确定删除按钮
  const handleDel = useCallback(
    (role: IRole) => {
      const ids: string[] = [];
      ids[0] = role.id;
      deleteRole(ids).then((resp) => {
        if (isSuccessResponse(resp)) {
          handlePageChange(1, 10);
        }
      });
    },
    [handlePageChange]
  );

  const columns = useMemo(() => {
    const columnsList: { title: string; dataIndex: string; render?: any }[] = [
      {
        title: "角色名",
        dataIndex: "roleName",
      },
      {
        title: "简介",
        dataIndex: "remark",
      },
      {
        title: "系统用户数",
        dataIndex: "sysUserCount",
      },
    ];
    if (canDelete || canEdit) {
      columnsList.push({
        title: "操作",
        dataIndex: "opt",
        render: (_: any, record: IRole) => (
          <>
            {
              <Space size="middle" style={{ marginRight: "1vw" }}>
                <span
                  className='tableEditButton'
                  onClick={() => {
                    handleEdit(record)
                  }}
                >
                  编辑
                </span>
              </Space>
            }
            {
              <Space size="middle" style={{ marginRight: "1vw" }}>
                <Popconfirm
                  title="确定删除该角色吗?"
                  onConfirm={(e) => handleDel(record)}
                  onCancel={() => { }}
                  okText="确定"
                  cancelText="取消"
                >
                  <span className='tableDeleteButton'>
                    删除
                  </span>
                </Popconfirm>
              </Space>
            }
          </>
        ),
      });
    }
    return columnsList;
  }, [canDelete, canEdit, handleDel, handleEdit]);

  //TODO 点击新建角色
  const handleAdd = () => {
    if (user && user.permissions) {
      setPermissions(user.permissions);
    }
    setCheckedkeys([]);
    setRoleModal(true);
    setData({} as IRolePermission);
  };

  const onSelect = (keys: string[]) => {
    setCheckedkeys(keys);
  };

  const onOk = useCallback(
    (resp: any) => {
      const { roleName, remark } = resp;
      if (data.id) {
        // true update
        const { id } = data;
        editRole({
          id,
          roleName,
          remark,
          permissions: checkedkeys.map(Number),
        }).then((res) => {
          handlePageChange(1, 10);
        });
        setRoleModal(false);
      } else {
        // add
        if (roleName && remark) {
          checkedkeys.pop();
          addRole({
            roleName,
            remark,
            permissions: checkedkeys.map(Number),
          }).then((res) => {
            handlePageChange(1, 10);
          });
          setRoleModal(false);
        } else {
          message.warning("角色名和简介为必填");
        }
      }
    },
    [handlePageChange, data, checkedkeys]
  );

  const onCancel = useCallback(() => {
    setRoleModal(false);
    setPermissions([]);
  }, []);

  return (
    <div className='normalPage'>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <h3>用户列表</h3>
        {canAdd && (
          <Button type="primary" onClick={handleAdd}>
            新建角色
          </Button>
        )}
      </div>
      <GTable
        rowKey="id"
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.size,
          total: pagination.total,
          onChange: handlePageChange,
        }}
      />
      <RoleModal
        title="角色管理"
        visible={isRoleModal}
        onCancel={onCancel}
        permissions={permissions}
        checkedkeys={checkedkeys}
        data={data}
        onSelect={onSelect}
        onOk={onOk}
      />
    </div>
  );
};

export default RoleList;
