import { useCallback, useMemo } from "react";
import GTable from '../../components/GTable';
import useList from "../../hooks/useList";
import { operatorLogRecoed } from "../../services/systemUser";
import FilterForm from "./FilterForm";

const OperatorLog = () => {
  const { list, loading, filter, pagination, fetchList } =
    useList<IOperatorLog>(operatorLogRecoed);

  const columns = useMemo(() => {
    return [
      {
        title: "操作时间",
        dataIndex: "createdAt",
      },
      {
        title: "操作人",
        dataIndex: "operatorName",
      },
      {
        title: "操作内容",
        dataIndex: "description"
      },
    ];
  }, []);

  const handlePageChange = useCallback(
    (page: number, size?: number) => {
      fetchList({
        ...filter,
        page: size !== pagination.pageSize ? 1 : page,
        size,
      });
    },
    [fetchList, filter, pagination]
  );

  const handleSearch = useCallback(
    (data: any) => {
      fetchList({ ...data, page: 1, size: pagination.pageSize });
    },
    [pagination.pageSize, fetchList]
  );

  return (
    <div className='normalPage'>
      <div>
        <h3>操作日志</h3>
      </div>
      <FilterForm onSearch={handleSearch} />
      <GTable
        rowKey="id"
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange,
        }}
      />
    </div>
  );
};

export default OperatorLog;
