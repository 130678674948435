import { useEffect, useState, useCallback } from "react";
import { fetchUserDetail } from "../../../services/appUser";
import { isSuccessResponse } from "../../../util";

interface IProps {
  id: string;
}

export default function useAppUserDetail({ id }: IProps) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IAppUser | null>(null);
  const queryUser = useCallback(() => {
    setLoading(true);
    fetchUserDetail(id).then((resp) => {
      if (isSuccessResponse(resp)) {
        console.log(resp.data);
        setData(resp.data)
      }
    }).finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    queryUser();
  }, [queryUser]);

  return {
    loading,
    data,
    queryUser,
  };
}
