import { FC, useCallback } from "react"
import { Form, InputNumber, message } from "antd"

import { isErrorResponse } from "../../util";
import { preActivateUsers } from "../../services/license";
import FormModal from "../../components/FormModal";


interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
}

const PreActivateModal: FC<IProps> = ({ visible, onCancel, onOk }) => {

  const handleOk = useCallback((values: any) => {
    return preActivateUsers(values).then((resp) => {
      if (isErrorResponse(resp)) {
        !resp.message && message.error('预授权失败');
      } else {
        onOk()
      }
    })
  }, [onOk]);

  return (
    <FormModal
      title="通过数量预授权"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form.Item
        name="num"
        label="数量"
        rules={[{ required: true, message: '数量必填' }]}
      >
        <InputNumber min={1} max={500} />
      </Form.Item>
    </FormModal>
  )
}

export default PreActivateModal;
