import { FC, useEffect, useCallback } from 'react';
import { Form, Input, Select, Modal } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

interface IProps {
  permission?: IPermission;
  visible: boolean;
  confirming?: boolean;
  onCancel: () => void;
  onOk: (p: IPermission) => void;
}

const PermissionModal: FC<IProps> = ({ permission, visible, onCancel, onOk, confirming }) => {

  const getInitPermissions = useCallback((permission: IPermission | undefined) => {
    return {
      permissionName: undefined, permissionType: 1, uri: undefined, permissionKey: undefined,
      ...permission
    };
  }, []);

  const title = permission && permission.id !== '0' ? '编辑权限' : '新建权限';
  const initPermission = getInitPermissions(permission);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      console.log('显示', permission);
      const p = getInitPermissions(permission);
      form.resetFields();
      form.setFieldsValue(p);
    }
  }, [visible, permission, form, getInitPermissions]);

  const onChangeType = useCallback((value: number) => {
    form.setFieldsValue({ permissionType: value });
  }, [form]);

  const onConfirm = useCallback(() => {
    const error = form.getFieldsError();
    console.log('error', error);
    if (error) {
      for (let index = 0; index < error.length; index++) {
        const element = error[index];
        if (element && element.errors && element.errors.length > 0) {
          return;
        }
      }
    }
    if (permission) {
      onOk({ ...form.getFieldsValue(), icon: 'none', id: permission.id, pPermissionId: permission.pPermissionId });
    }
  }, [form, onOk, permission]);

  const { permissionName, permissionType, uri, permissionKey, } = initPermission;

  return <Modal
    title={title}
    visible={visible}
    onOk={onConfirm}
    onCancel={onCancel}
    confirmLoading={confirming}
  >
    <Form layout="horizontal" form={form}>
      <FormItem   {...formItemLayout}
        hasFeedback
        label='名称'
        name="permissionName"
        initialValue={permissionName}
        rules={[
          { required: true, message: '请输入权限名称' },
          { pattern: /^[一-龥a-zA-Z0-9]{1,20}$/, message: '需要1-20个字符，只能输入中文、数字或英文字母' },
        ]}>
        <Input />
      </FormItem>
      <FormItem  {...formItemLayout}
        hasFeedback
        label='类型'
        name="permissionType"
        initialValue={permissionType}
      >
        <Select defaultValue={1} style={{ width: 120 }} onChange={onChangeType}>
          <Option value={1}>导航菜单</Option>
          <Option value={2}>功能模块</Option>
        </Select>
      </FormItem>
      <FormItem  {...formItemLayout}
        hasFeedback
        label='后端接口'
        name="uri"
        initialValue={uri}
        rules={[
          { required: true, message: '请输入uri' },
          { pattern: /^[^\s]{1,100}$/, message: '最多能输入100个字符，并不允许输入空格' },
        ]}>
        <Input />
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.permissionType !== currentValues.permissionType}
      >
        {({ getFieldValue }) =>
          getFieldValue('permissionType') === 1 ? (
            <FormItem {...formItemLayout}
              hasFeedback
              name='permissionKey' label='页面URL' initialValue={permissionKey} rules={[
                { required: true, message: '请输入路径' },
                { pattern: /^[^\s]{1,200}$/, message: '最多能输入200个字符，并不允许输入空格' }]}>
              <Input />
            </FormItem>
          ) : null
        }
      </FormItem>
    </Form>
  </Modal>
}

export default PermissionModal;

