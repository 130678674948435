import { useState, useCallback } from "react";
import { message } from "antd";
import {
  deletePermission,
  addPermission,
  editPermission,
  addPermissionUrl,
  editPermissionUrl,
  deletePermissionUrl,
} from "../../services/permission";
import { isSuccessResponse } from "../../util";
import { useUser } from "../../models/user";
import { checkPermissionKeyInList } from "../../containers/PermissionComponents";
import PermissionTree from "./PermissionTree";
import PermissionModal from "./PermissionModal";

async function addOrUpdatePermission(p: IPermission) {
  return p.id !== "0" ? editPermission(p) : addPermission(p);
}

const PermissionManager = () => {
  const [currentPermission, setCurrentPermission] = useState<
    IPermission | undefined
  >(undefined);
  const [confirming, setConfirming] = useState(false);

  const { user, getDetial } = useUser();

  const onAdd = (parentPermission: IPermission) => {
    setCurrentPermission({ id: "0", pPermissionId: parentPermission.id });
  };

  const onCancel = useCallback(() => {
    setCurrentPermission(undefined);
  }, []);

  const confirmFinish = useCallback(({
    res,
    successMsg,
  }: {
    res: any;
    successMsg?: string;
  }) => {
    if (isSuccessResponse(res)) {
      getDetial();
      message.success(successMsg ? successMsg : "保存成功!");
      onCancel();
    }
  }, [onCancel, getDetial]);

  const onOk = useCallback((p: IPermission) => {
    console.log("onOK", p);
    if (p) {
      setConfirming(true);
      addOrUpdatePermission(p).then((res) => {
        setConfirming(false);
        confirmFinish({ res });
      });
    }
  }, [confirmFinish]);

  const onEdit = useCallback((p: IPermission) => {
    setCurrentPermission(p);
  }, []);

  const onDelete = useCallback(async (p: IPermission) => {
    deletePermission([p.id]).then((res) => {
      confirmFinish({ res, successMsg: "删除成功！" });
    });
  }, [confirmFinish]);

  const canAdd = checkPermissionKeyInList(addPermissionUrl, user?.permissions);
  const canEdit = checkPermissionKeyInList(editPermissionUrl, user?.permissions);
  const canDelete = checkPermissionKeyInList(deletePermissionUrl, user?.permissions);
  // console.log('canAdd', canAdd, canEdit, canDelete);
  return (
    user && (
      <div className='normalPage departmentTree'>
        {user.permissions && (
          <PermissionTree
            list={user.permissions}
            onEdit={canEdit ? onEdit : undefined}
            onAdd={canAdd ? onAdd : undefined}
            onDelete={canDelete ? onDelete : undefined}
            checkable={false}
          />
        )}
        <PermissionModal
          visible={!!currentPermission}
          permission={currentPermission}
          onOk={onOk}
          onCancel={onCancel}
          confirming={confirming}
        />
      </div>
    )
  );
};

export default PermissionManager;
