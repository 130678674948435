import { FC, useCallback, useEffect, useMemo, useState, useRef } from "react";
import {
  Button,
  message,
  Space,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import {
  userDetailUrl,
  chargeUserUrl,
  deleUser,
  editUsersDep,
  addUserUrl,
  unfreezeUser,
} from "../../services/appUser";
import GTable from '../../components/GTable';
import { isSuccessResponse } from "../../util";
import { minuteTimeString, isUserFreeze } from '../../util/tool';
import useList from "../../hooks/useList";
import FilterForm from "./FilterForm";
import { CheckPermissionKeyInListHook } from "../../containers/PermissionComponents";
import UserChargeModal from "./UserChargeModal";
import AddUserModal from "./AppUserDetail/AddUserModal";
import { queryDepartmentMember } from "../../services/department";
import ChooseDepartModal from "../Departments/ChooseDepartModal";
import ImportUserModal from './ImportUserModal';

import './index.scss';

interface ITem {
  title: string;
  dataIndex: string;
  render?: any;
}

export const columnsList: ITem[] = [
  // {
  //   title: "用户名",
  //   dataIndex: "username",
  // },
  {
    title: "昵称",
    dataIndex: "nickname",
  },
  {
    title: "手机号",
    dataIndex: "phone",
  },
  {
    title: '剩余时长(分钟)',
    dataIndex: 'liveHdAvailableSeconds',
    render: (value: number) => minuteTimeString(value),
  },
  {
    title: '状态',
    dataIndex: 'status',
    render: (value: string) => {
      const freeze = isUserFreeze(value);
      return <span className={`normalStatusView ${freeze ? 'freezeStatusView' : ''}`}>{freeze ? '冻结' : '正常'}</span>;
    },
  },
  // {
  //   title: "创建时间",
  //   dataIndex: "createdAt",
  //   render: (value: string) => (value ? formatDate(value) : "-"),
  // },
];

interface IProps {
  id: string | undefined;
  name: string;
}

const AppUsers: FC<IProps> = ({ id, name }) => {
  const idRef = useRef(id);
  const [showImport, setShowImport] = useState(false);
  const queryList = useCallback(
    async (p: any) => {
      return queryDepartmentMember({ ...p, departmentId: id });
    },
    [id]
  );

  const {
    list,
    loading,
    filter,
    pagination,
    selectedRowKeys,
    rowSelection,
    setSelectedRowKeys,
    fetchList,
  } = useList<IAppUser>(queryList);

  const [chargeUserList, setChargeUserList] = useState<string[]>([]);
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const [chooseDepaetModal, setChooseDepaetModal] = useState<boolean>(false);
  const [uid, setUid] = useState<number | undefined>(undefined);

  const canDetail = CheckPermissionKeyInListHook(userDetailUrl);
  const canEdit = CheckPermissionKeyInListHook(addUserUrl);
  const canCharge = CheckPermissionKeyInListHook(chargeUserUrl);

  const handlePageChange = useCallback(
    (page: number, pageSize?: number) => {
      fetchList({
        ...filter,
        page: pageSize !== pagination.pageSize ? 1 : page,
        size: pageSize,
      });
    },
    [fetchList, filter, pagination]
  );

  useEffect(() => {
    if (id !== idRef.current) {
      idRef.current = id;
      handlePageChange(1)
    }
  }, [id, handlePageChange]);

  const freezeUser = useCallback((user: IAppUser) => {
    const freeze = isUserFreeze(user.status);
    const title = `确定要${freeze ? '解冻' : '冻结'}${user.nickname || user.phone}吗？`;
    const content = freeze ? '' : '冻结后，该用户时长将清零';
    Modal.confirm({
      title,
      content,
      onOk: async () => {
        let res;
        if (freeze) {
          res = await unfreezeUser(user.uid);
        } else {
          res = await deleUser(user.uid);
        }
        console.log('pagination.page', pagination.page);
        handlePageChange(pagination.page, pagination.pageSize);
        if (isSuccessResponse(res)) {
          message.success(freeze ? '解冻成功' : '冻结成功')
        } else {
          message.error(res.message || '请求失败')
        }
        return true;
      }
    })
  }, [handlePageChange, pagination]);

  // const onConfirm = useCallback((record: any) => {
  //   deleUser(record.uid).then((resp) => {
  //     if (isSuccessResponse(resp)) {
  //       handlePageChange(1);
  //     }
  //   });
  // }, [handlePageChange]);

  const columns = useMemo(() => {
    if (canDetail || canEdit) {
      const list = [...columnsList];
      list.push({
        title: "操作",
        dataIndex: "opt",
        render: (_: any, record: IAppUser) => {
          const freeze = isUserFreeze(record.status);
          return (
            <>
              {canDetail && (
                <Space size="middle" style={{ marginRight: "1vw" }}>
                  <Link className='tableDetailButton' to={`/departments/appUsers/${record.uid}`}>详情</Link>
                </Space>
              )}
              {(canEdit && !freeze) && (
                <Space size="middle" style={{ marginRight: "1vw" }}>
                  <span
                    className='tableEditButton'
                    onClick={() => {
                      setUid(record.uid);
                      setAddUserModal(true);
                    }}
                  >
                    编辑
                  </span>
                </Space>
              )}
              {canEdit && (
                <Space size="middle" style={{ marginRight: "1vw" }}>
                  {/* <Popconfirm
                    title="确定删除该员工吗?"
                    onConfirm={() => onConfirm(record)}
                    onCancel={() => { }}
                    okText="确定"
                    cancelText="取消"
                  > */}
                  <span className='tableDeleteButton' onClick={() => freezeUser(record)}>
                    {freeze ? '解冻' : '冻结'}
                  </span>
                </Space>
              )}
            </>
          );
        },
      });
      return list;
    }
    return columnsList;
  }, [canDetail, canEdit, freezeUser]);

  const handleSearch = useCallback(
    (data: any) => {
      console.log(data);
      fetchList({ ...data, page: 1, size: pagination.pageSize });
    },
    [fetchList, pagination.pageSize]
  );

  const handleActive = useCallback(() => {
    if (selectedRowKeys.length === 0) {
      message.warn("未选择需要充值的用户");
      return;
    }
    setChargeUserList(selectedRowKeys as string[]);
  }, [selectedRowKeys]);

  const handldChargeCancel = useCallback(() => setChargeUserList([]), []);

  const handldChargeOK = useCallback(
    async ({ success }: { success: boolean }) => {
      if (success) {
        message.success("充值成功");
        setSelectedRowKeys([]);
        handldChargeCancel();
        handlePageChange(1);
      }
    },
    [setSelectedRowKeys, handlePageChange, handldChargeCancel]
  );

  const showChoose = useCallback(() => setChooseDepaetModal(true), []);
  const chooseCancel = useCallback(() => setChooseDepaetModal(false), []);

  const chooseOk = useCallback(
    (department: IDepartments | undefined) => {
      if (department) {
        editUsersDep(Number(department.id), selectedRowKeys as number[]).then(
          () => {
            chooseCancel()
            handlePageChange(1);
          }
        );
      }
    },
    [selectedRowKeys, handlePageChange, chooseCancel]
  );

  const showAddModal = useCallback(() => setAddUserModal(true), []);

  const addModalOnCancel = useCallback(() => {
    setUid(undefined);
    setAddUserModal(false);
  }, []);


  const handleAddOk = useCallback(() => {
    addModalOnCancel();
    handlePageChange(1);
  }, [addModalOnCancel, handlePageChange]);

  const rowSelectionAndDisabled = useMemo(
    () => ({
      ...rowSelection,
      getCheckboxProps: (user: IAppUser) => ({
        disabled: isUserFreeze(user.status),
      })
    }),
    [rowSelection]
  );
  console.log('id', id);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
          paddingLeft: '20px',
        }}
      >
        <h3>{name || "全部"}的用户列表</h3>
        <div style={{ display: "flex" }}>
          <Button
            type="primary"
            style={{ marginRight: "1vw" }}
            onClick={showChoose}
            disabled={selectedRowKeys.length === 0}
          >
            分配员工
          </Button>
          <Button
            type="primary"
            style={{ marginRight: "1vw" }}
            onClick={showAddModal}
          >
            新增成员
          </Button>
          <Button type="primary" style={{ marginRight: "1vw" }} onClick={() => setShowImport(true)}>
            批量导入
          </Button>
          {canCharge && (
            <Button
              type="primary"
              onClick={handleActive}
              disabled={selectedRowKeys.length === 0}
            >
              批量充值
            </Button>
          )}
        </div>
      </div>
      <FilterForm onSearch={handleSearch} />
      <GTable
        className='appUserTable'
        rowKey="uid"
        columns={columns}
        dataSource={list}
        loading={loading}
        rowSelection={rowSelectionAndDisabled}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: handlePageChange,
          // showTotal: (total) => <span className='tableTotalItem'>查询结果{total}条</span>
        }}
      />
      <UserChargeModal
        uids={chargeUserList}
        visible={chargeUserList && chargeUserList.length > 0}
        onCancel={handldChargeCancel}
        onOk={handldChargeOK}
      />
      <AddUserModal
        visible={addUserModal}
        onCancel={addModalOnCancel}
        onOk={handleAddOk}
        uid={uid}
      />
      <ChooseDepartModal
        visible={chooseDepaetModal}
        handleOk={chooseOk}
        handleCancel={chooseCancel}
      />
      <ImportUserModal
        visible={showImport}
        onCancel={() => setShowImport(false)}
        onOk={async ({ success }) => {
          setShowImport(false)
        }}
      />
    </div>
  );
};

export default AppUsers;
