import { useMemo, useRef } from 'react';
import TitleValueLabel from '../../../../components/TitleLabel';
import { View, Text, } from '../../../../components/reactComponents';
import { TaskLogTypeEnum, noSecondTimeString } from '../../../../util/tool';
import TaskLiveInfoItem from './TaskLiveInfoItem';
import TaskLocalInfoItem from './TaskLocalInfoItem';

import './index.scss'

interface IProps {
  log: ITaskLogItem;
  isActive?: boolean;
}

const OrderInfoItem = ({ log }: { log: ITaskLogItem }) => {
  const { createUserNickname } = log;
  const { logType, startTime, usernames } = log.logDetail.orderLog || {};
  const isCanncelRef = useRef(false);

  const statusText = useMemo(() => {
    switch (logType) {
      case TaskLogTypeEnum.create:
        return '预约直播';
      case TaskLogTypeEnum.update:
        return '更新预约';
      case TaskLogTypeEnum.cancel:
        isCanncelRef.current = true;
        return '取消预约';
      default:
        return '预约直播';
    }
  }, [logType])

  const timeString = useMemo(() => noSecondTimeString(startTime), [startTime]);

  return (<View className='orderInfoItem'>
    <View className='handlerView'>
      <Text className='handlerText'>
        由<Text className='handler'>{createUserNickname}</Text>{statusText}
      </Text>
    </View>
    {!isCanncelRef.current && <TitleValueLabel title='预约直播时间：' value={timeString} />}
    {!isCanncelRef.current && <TitleValueLabel title='已邀请成员：' value={usernames || ''} />}
  </View>)
}

const FinishInfoItem = ({ log }: { log: ITaskLogItem }) => {
  const { createUserNickname, logDetail, createUsername } = log;
  const { remarkList } = logDetail;
  const remark = remarkList && remarkList.length > 0 ? remarkList[0].remarkContent : ''

  return (<View className='orderInfoItem'>
    <View className='handlerView'>
      <Text className='handlerText'>
        由<Text className='handler'>{createUserNickname || createUsername}</Text>完成任务
      </Text>
    </View>
    <TitleValueLabel title='完成描述：' value={remark} />
  </View>)
}

const UpdateItem = ({ log }: { log: ITaskLogItem }) => {
  const { createUserNickname, createUsername, logDetail } = log;
  const { valueChangeList } = logDetail;

  return (<View className='orderInfoItem'>
    <View className='handlerView'>
      <Text className='handlerText'>
        由<Text className='handler'>{createUserNickname || createUsername}</Text>编辑任务
      </Text>
    </View>
    {valueChangeList?.map((i: ITaskValueChange) => {
      const { fieldName, newValue, oldValue } = i;
      return (<TitleValueLabel
        key={fieldName}
        title={`${fieldName}：`}
        value={<Text className='titleText' style={{ position: 'relative', top: -4 }}>由
          <Text className='updateContent'>“{oldValue}”</Text>
          {`\n 更改为`}
          <Text className='updateContent'>“{newValue}”</Text>
        </Text>}
      />
      );
    })}
  </View>)
}

const TaskLogItem = ({ log, isActive }: IProps) => {
  const { createdAt, createUserNickname, createUsername, logType, logDetail } = log;

  const time = noSecondTimeString(createdAt);

  const logState = useMemo(() => {
    switch (logType) {
      case TaskLogTypeEnum.create:
        return (<View className='handlerView'>
          <Text className='handlerText'>
            由<Text className='handler'>{createUserNickname || createUsername}</Text>创建任务
          </Text>
        </View>);
      case TaskLogTypeEnum.cancel:
        return (<View className='handlerView'>
          <Text className='handlerText'>
            由<Text className='handler'>{createUserNickname || createUsername}</Text>取消预约
          </Text>
        </View>);
      case TaskLogTypeEnum.update:
        return <UpdateItem log={log} />;
      case TaskLogTypeEnum.order:
        return (logDetail.orderLog ? <OrderInfoItem log={log} /> : null)
      case TaskLogTypeEnum.living:
        return (logDetail.livingLog ? <TaskLiveInfoItem
          log={log}
        /> : null)
      case TaskLogTypeEnum.upload:
        return (logDetail.localUpload ? <TaskLocalInfoItem
          log={log}
        /> : null)
      case TaskLogTypeEnum.finish:
        return (logDetail ? <FinishInfoItem log={log} /> : null)

      default:
        return null;
    }

  }, [logType, logDetail, createUserNickname, createUsername, log])

  return (<View className={`taskLogItem ${isActive ? 'active' : ''}`} >
    <View className={`point ${isActive ? 'active' : ''}`} />
    <View style={{ width: '100%', position: "relative", top: '-0.4rem' }}>
      <View><Text className='titleText'>{time}</Text></View>
      {logState}
    </View>
  </View>)

}

export default TaskLogItem;
