import { createContext, FC, useContext, useState, useCallback } from "react";
import { companyPage } from "../services/company";
import { isSuccessResponse } from "../util";

const companyContext = createContext<{
  companyList: ICompany[];
  getCompanyList: () => void;
}>({ companyList: [], getCompanyList: () => { } });

export const CompanyProvider: FC = ({ children }) => {
  const [companyList, setCompanyList] = useState<ICompany[]>([]);

  const getCompanyList = useCallback(() => {
    companyPage({ current: 0, size: 200 }).then((resp) => {
      if (isSuccessResponse(resp)) {
        setCompanyList(resp.data.content);
      }
    });
  }, []);

  return (
    <companyContext.Provider value={{ companyList, getCompanyList }}>
      {children}
    </companyContext.Provider>
  );
};

export const useCompany = () => {
  return useContext(companyContext);
};
