import { FC, useCallback, useEffect } from "react";
import { Button, Form, Select, Space } from "antd";
import { useCompany } from '../../models/companyList';
import { DATETIME_FORMAT } from "../../util";
import DatePicker from "../../components/DatePicker";

const { RangePicker } = DatePicker;

interface IProps {
  onSearch: (data: any) => void;
}

const FilterForm: FC<IProps> = ({ onSearch }) => {

  const { companyList, getCompanyList } = useCompany();
  const [form] = Form.useForm();

  useEffect(() => {
    getCompanyList()
  }, [getCompanyList])


  const handleSearch = useCallback(() => {
    form.validateFields().then(({ company, status, times }) => {
      const data: any = {};
      // if (value) {
      //   data[key] = value;
      // }
      if (company > -1) {
        data.companyId = company;
      }
      if (status && status !== "") {
        data.status = status;
      }
      console.log('times', times)
      if (times && times.length > 0) {
        data.startTime = times[0].format(DATETIME_FORMAT);
        data.endTime = times[1].format(DATETIME_FORMAT);
      }
      onSearch(data);
    });
  }, [form, onSearch]);

  const handleReset = useCallback(() => {
    form.resetFields();
    onSearch({});
  }, [form, onSearch]);
  return (
    <Form form={form} layout="inline" style={{ marginBottom: 20 }}>
      <Form.Item label="组织/个人" name="company" initialValue={-1}>
        <Select style={{ width: 150 }}>
          <Select.Option value={-1}>全部</Select.Option>
          <Select.Option value={0}>个人用户</Select.Option>
          {companyList.map((c: ICompany) => <Select.Option value={c.id}>{c.name}</Select.Option>)}
        </Select>
      </Form.Item>
      {/* <Form.Item>
        <Input.Group compact>
          <Form.Item name="key" noStyle initialValue="nickname">
            <Select>
              <Select.Option value="nickname">直播人</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="value" noStyle initialValue="">
            <Input style={{ width: "67%" }} placeholder="请输入关键字" />
          </Form.Item>
        </Input.Group>
      </Form.Item> */}
      <Form.Item label="状态" name="status" initialValue="">
        <Select style={{ width: 100 }}>
          <Select.Option value="">全部</Select.Option>
          <Select.Option value={"ORDER"}>已预约</Select.Option>
          <Select.Option value={"LIVING"}>进行中</Select.Option>
          <Select.Option value={"FINISH"}>已结束</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="times" label="开始时间范围">
        <RangePicker
          showTime
          format={DATETIME_FORMAT}
          placeholder={['查询起始时间', '查询结束时间']}
        />
      </Form.Item>
      <Form.Item>
        <Space size="small">
          <Button type="primary" onClick={handleSearch}>
            搜索
          </Button>
          <Button onClick={handleReset}>重置</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FilterForm;
