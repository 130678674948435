import { FC, useCallback, useEffect, useState } from "react"
import dayjs from "dayjs";
import { Form, Input, Modal, Spin, Upload, message } from "antd"
import SearchInput from "../../components/SearchInput";
import DatePicker from "../../components/DatePicker";
import { fetchUsers } from "../../services/appUser";
import { DATETIME_FORMAT, DATETIME_NO_SECOND_FORMAT, isSuccessResponse } from "../../util";
import { createTask, editTask, fetchTaskDetail, uploadFile } from "../../services/task";

interface IProps {
  visible: boolean;
  task: ITask | null;
  appUser?: IAppUser | null;
  onCancel: () => void;
  onOk: () => void;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};


const fileSize = 300;
const fileMaxSize = fileSize * 1024 * 1024;

function disabledDate(current: any) {
  return current && (current.format('x') < dayjs().endOf('d').add(-1, 'd').valueOf());
}

const TaskModal: FC<IProps> = ({ visible, task, onCancel, onOk, appUser }) => {

  const [detailLoading, setDetailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!task && visible) {
      form.setFieldsValue({
        title: '',
        content: '',
        executeUserId: appUser ? appUser.uid : null,
        times: [],
        endTime: null,
      });
    }
  }, [form, task, visible, appUser])

  useEffect(() => {
    if (task) {
      setDetailLoading(true);
      fetchTaskDetail(task.id).then((resp) => {
        setDetailLoading(false);
        if (isSuccessResponse(resp)) {
          const taskDetail = { ...task, ...resp.data, };
          // extendVOS
          const { title, content, executeUserId, expectedFinishTime, extendVOS } = taskDetail;
          const fileList = extendVOS && extendVOS.length > 0 ? extendVOS.map((i: IExtendVOSItem) => {
            return { url: i.accessory, id: i.id }
          }
          ) : [];
          setFiles(fileList);
          form.setFieldsValue({
            title,
            content,
            executeUserId,
            endTime: dayjs(expectedFinishTime),
            // times: [
            //   dayjs(taskDetail.planStartTime),

            // ]
          });
        } else {
          onCancel();
        }
      })
    }
  }, [form, task, onCancel]);

  const searchUser = useCallback((search: string) => {
    return fetchUsers(search).then((res) => {
      if (isSuccessResponse(res)) {
        return res.data;
      }
      return [];
    }).then((users) => {
      return users.map(u => ({
        value: u.uid,
        text: u.nickname || u.username,
      }))
    })
  }, []);

  const handleOk = useCallback(() => {
    form.validateFields().then(async ({ times, endTime, ...values }) => {
      // const planStartTime = times[0].format(DATETIME_FORMAT)
      setLoading(true);
      console.log('handleOk', files);
      const extendVOS: IExtendVOSItem[] = [];
      if (files && files.length > 0) {
        // 有文件的话，检查文件是否需要上传
        const needUploadFiles: any = [];
        files.forEach(f => {
          if (!f.url) {
            needUploadFiles.push(f);
          } else {
            extendVOS.push({ id: f.id, accessory: f.url });
          }
        })
        if (needUploadFiles && needUploadFiles.length > 0) {
          for (let index = 0; index < needUploadFiles.length; index++) {
            const result = await uploadFile({ file: needUploadFiles[index].originFileObj });
            const msg = result.message;
            if (isSuccessResponse(result)) {
              extendVOS.push({ accessory: result.data.sourceUrl });
            } else {
              message.error(msg || '文件上传失败');
              setLoading(false);
              return;
            }
          }
        }
      }

      const expectedFinishTime = endTime.format(DATETIME_FORMAT);
      const data = { ...values, expectedFinishTime, extendVOS };
      if (task) {
        data.id = task.id;
      }
      (task ? editTask : createTask)(data).then((resp) => {
        if (isSuccessResponse(resp)) {
          onOk()
        }
      }).finally(() => setLoading(false));
    })
  }, [form, onOk, files, task]);

  const defaultOptions = task ? [{
    text: task.executeUserNickname,
    value: task.executeUserId
  }] : [];

  const onChangeFile = useCallback(({ fileList }) => {
    console.log('fileList', fileList);
    const list: any[] = [];
    for (let index = 0; index < fileList.length; index++) {
      const element = fileList[index];
      if (element.size > fileMaxSize) {
        message.info(`文件大小不能超过${fileSize}M`);
      } else {
        list.push(element);
      }
    }
    setFiles(list);
  }, [])

  console.log('taksModal', task, files)

  return (
    <Modal
      title={`${task ? '编辑' : '新增'}任务`}
      visible={visible}
      okText={task ? '确定' : '新增'}
      onCancel={onCancel}
      onOk={handleOk}
      width={620}
      confirmLoading={loading}
    >
      <Spin spinning={detailLoading}>
        <Form
          {...formItemLayout}
          form={form}
        >
          <Form.Item
            name="title"
            label="任务标题"
            rules={[{ required: true, message: '请输入任务标题', max: 30 }]}
          >
            <Input placeholder="请输入任务标题，30字以内" />
          </Form.Item>
          <Form.Item
            name="content"
            label="任务描述"
            rules={[{ required: true, message: '请输入任务描述', max: 300 }]}
          >
            <Input.TextArea rows={3} placeholder="请输入任务描述，300字以内" />
          </Form.Item>
          <Form.Item
            name="files"
            label="上传附件"
          >
            <div>
              <Upload
                accept="image/*,video/*"
                listType="picture-card"
                beforeUpload={() => false}
                onChange={onChangeFile}
                maxCount={3}
                fileList={files}
                showUploadList={{
                  showRemoveIcon: true,
                  showPreviewIcon: false,
                }}
              >
                {files.length > 2 ? null : '+'}
              </Upload>
              <div>可上传图片、视频({files.length}/3)</div>
            </div>
          </Form.Item>
          <Form.Item
            name="endTime"
            label="期望完成时间"
            rules={[{ required: true, message: '请选择任务时间' }]}
          >
            <DatePicker
              showTime
              format={DATETIME_NO_SECOND_FORMAT}
              disabledDate={disabledDate}
              // showToday={false}
              showNow={false}
              showSecond={false}
              placeholder="选择期望完成时间"
            />
          </Form.Item>
          <Form.Item
            name="executeUserId"
            label="执行人"
            rules={[{ required: true, message: '执行人必填' }]}
          >
            {(!task && appUser) ? <span>
              {appUser.nickname}
            </span> : <SearchInput
              fetchData={searchUser}
              defaultOptions={defaultOptions}
            />}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default TaskModal;
