import dayjs from 'dayjs';

export function isSuccessResponse(resp: unknown): resp is IResponse<any> {
  const { code } = (resp as IResponse<any>);
  return code === '200';
}

export function isErrorResponse(resp: unknown): resp is IErrorResponse {
  const { code, message } = resp as IErrorResponse;
  return code !== '200' && !!message;
}

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_NO_SECOND_FORMAT = 'YYYY-MM-DD HH:mm';

export function formatDate(date?: dayjs.ConfigType, formatString?: string) {
  return dayjs(date).format(formatString || DATETIME_FORMAT)
}


export function downloadFileFromBlob(blob: Blob, fileName: string) {
  const a = document.createElement('a');
  const blodUrl = window.URL.createObjectURL(blob);
  a.href = blodUrl;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(blodUrl);
}

export function downloadFileByUrl({ url, fileName, onLoad }: { url: string, fileName: string, onLoad?: () => void }) {
  const xhr = new XMLHttpRequest();
  xhr.open("get", url, true);
  xhr.responseType = "blob";
  xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
  xhr.onload = function () {
    // let url = URL.createObjectURL(xhr.response);
    // let a = document.createElement("a");
    // let event = new MouseEvent("click");
    // a.href = url;
    // a.download = fileName;
    // a.dispatchEvent(event);
    // URL.revokeObjectURL(url);
    downloadFileFromBlob(xhr.response as Blob, fileName);
    if (onLoad) {
      onLoad();
    }
  };
  xhr.send();
}

export const mobileExp = {
  exp: /^1[3456789]\d{9}$/,
  errorMessage: '请输入正确手机号'
};

export const pwdExp = {
  exp: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,16}$/,
  errorMessage: '必须包含大小写字母和数字，长度6-16字符'
};

// 根据url获取文件名称
export function getFileNameByUrl(url: string) {
  if (url && url.length > 0) {
    const targetUrl = url;
    const list = targetUrl.split('/');
    const name = list[list.length - 1];
    return decodeURIComponent(name);
  }
  return null;
}

// 视频名字太长，裁剪去后半段
export function splitFileName(fileName: string, index: string) {
  if (fileName && fileName.length > 0) {
    const targetUrl = fileName;
    const firstIndex = targetUrl.indexOf('_');
    if (firstIndex > -1) {
      console.log("=========", targetUrl.substring(firstIndex + 1));//2411621409494274_0.mp4
      const url = targetUrl.substring(firstIndex + 1);
      return `${url.split('_')[0]}_${index}.mp4`
      // return targetUrl.substring(firstIndex + 1);
    }
  }
  return fileName;
}