import { FC, useCallback, useState } from "react";
import { Modal } from "antd";
import { useDepartment } from "../../models/department";
import DepartmentTree from "./DepartmentTree";

interface IProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: (department: IDepartments | undefined) => void;
}

const ChooseDepartModal: FC<IProps> = ({
  visible,
  handleCancel,
  handleOk,
}) => {
  const { departmentList } = useDepartment();
  const [checkKey, setCheckKey] = useState<string[]>([]);
  const [selDepartment, setSelDepartment] = useState<IDepartments | undefined>(undefined);

  const onSelect = useCallback((d: IDepartments | undefined) => {
    setSelDepartment(d);
    setCheckKey(d ? [`${d.id}`] : []);
  }, []);

  const onOk = useCallback(() => {
    handleOk(selDepartment);
  }, [handleOk, selDepartment]);

  return (
    <Modal
      visible={visible}
      title="部门选择"
      onCancel={handleCancel}
      onOk={onOk}
    >
      <DepartmentTree
        list={departmentList}
        checkable={true}
        onSelect={onSelect}
        checkedKeys={checkKey}
      />
    </Modal>
  );
};

export default ChooseDepartModal;
