import { InboxOutlined } from "@ant-design/icons";
import { message, Modal, Upload } from "antd";
import { RcFile, UploadProps } from "antd/lib/upload";
import { FC, useCallback, useEffect, useState } from "react";

const { Dragger } = Upload;

interface IProps extends UploadProps {
  visible: boolean;
  title: string;
  hint?: string;
  templateUrl?: string;
  onCancel: () => void;
  onOk: (file: RcFile) => Promise<any>;
}

const ImportModal: FC<IProps> = ({ title, hint, visible, templateUrl, onCancel, onOk, ...rest }) => {

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<RcFile | null>(null);

  useEffect(() => {
    if (!visible) {
      setFile(null);
    }
  }, [visible])

  const beforeUpload = useCallback((file: RcFile) => {
    setFile(file);
    return false;
  }, []);

  const handleRemove = useCallback(() => setFile(null), []);

  const handleOk = useCallback(() => {
    if (!file) {
      message.warn('请上传文件');
      return;
    }
    setLoading(true);
    onOk(file).finally(() => setLoading(false));
  }, [onOk, file])

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={loading}
    >
      <div>
        <Dragger
          {...rest}
          fileList={file ? [file] : []}
          beforeUpload={beforeUpload}
          onRemove={handleRemove}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击或者拖拽文件到该区域</p>
          <p className="ant-upload-hint">
            {hint && (
              <span>{hint}</span>
            )}
            {templateUrl && (
              <a
                onClick={(e) => { e.stopPropagation() }}
                href={templateUrl}
                target="_blank"
                rel="noreferrer"
              >点击下载导入模版</a>
            )}
          </p>
        </Dragger>
      </div>
    </Modal>
  )
}

export default ImportModal;
