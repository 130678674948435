import { useState, useCallback } from "react";
import { Tabs } from "antd";
import TimeInfo from './TimeInfo';
import UserTimeList from './UserTimeList';

const { TabPane } = Tabs;

const CompanyCapital = () => {

  const [activeKey, setActiveKey] = useState("time");

  const onChange = useCallback((activeKey: string) => {
    setActiveKey(activeKey);
  }, []);


  return (
    <div className='normalPage'>
      <Tabs defaultActiveKey="time" onChange={onChange} type="card">
        <TabPane tab="流量" key="time">
          <TimeInfo active={activeKey === "time"} type='time' />
          <UserTimeList active={activeKey === "time"} />
        </TabPane>
        <TabPane tab="存储空间" key="storge">
          <TimeInfo active={activeKey === "storge"} type='storge' />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default CompanyCapital;
