import { View } from '../../../../components/reactComponents';
import TaskLogItem from './TaskLogItem';
import './index.scss';

const TaskLogList = ({ logList }: { logList: ITaskLogItem[] }) => {

  return (
    <View>
      {logList.map((i, index) => <TaskLogItem log={i} key={i.id} isActive={index === logList.length - 1} />)}
    </View >
  )
}

export default TaskLogList;
