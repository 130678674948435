import request from "../util/request";

const departmentsUrl = '/glive/web/departments';
const departmentDetailUrl = '/glive/web/departments/department';
const departmentsPageUrl = '/glive/web/departments/page';
const querymemberUrl = '/glive/web/member/page'


export {
  departmentsUrl,
  departmentsPageUrl,
  querymemberUrl,
  departmentDetailUrl
}


interface IDepartmentsAddParam {
  description?: string;
  name?: string;
  parentId?: string;
}

interface IDepartmentsEditParam extends IDepartmentsAddParam {
  id: string;
}

interface IQuerymemberParam extends IPage {
  departmentId?: string;
}


export function getDepartmentsPage(params: IPage) {
  return request<IResponse<IPagination<IDepartments>> | IErrorResponse>(departmentsPageUrl, {
    method: 'GET',
    params,
  })
}


export function getDepartmentsDetail(departmentId: string) {
  return request<IResponse<IDepartments> | IErrorResponse>(departmentDetailUrl, {
    method: 'GET',
    params: { departmentId }
  })
}

export function deleteDepartments(departmentId: string) {
  return request<IResponse<any> | IErrorResponse>(`${departmentsUrl}?departmentId=${departmentId}`, {
    method: 'DELETE',
  })
}

export function addDepartments(params: IDepartmentsAddParam) {
  return request<IResponse<IDepartments> | IErrorResponse>(departmentsUrl, {
    method: 'POST',
    data: params,
  })
}

export function editDepartments(params: IDepartmentsEditParam) {
  return request<IResponse<IDepartments> | IErrorResponse>(departmentsUrl, {
    method: 'PUT',
    data: params,
  })
}

export function queryDepartmentMember(params: IQuerymemberParam) {
  return request<IResponse<IPagination<IAppUser>> | IErrorResponse>(querymemberUrl, {
    method: 'GET',
    params
  })
}
