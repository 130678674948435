import './index.scss'

const TitleValueLabel = ({ title, value, style }: { title: string, value: any, style?: any }) => {
  return (<div className='titleValueLabel' style={style}>
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <span className='titleText' style={{ display: 'inline-block' }}>
        {title}
      </span>
      <div style={{ flex: 1 }}>
        <span className='bigText' style={{ display: 'inline-block', wordBreak: 'break-word' }}>{value}</span>
      </div>
    </div>
  </div>)
}

export default TitleValueLabel;
