import request from "../util/request";
import { downloadFileFromBlob } from "../util";

const uesrPageUrl = '/glive/web/users/page';
const uesrListUrl = '/glive/web/users/list';
const activeUserUrl = '/glive/web/licenses/activate_user';
const chargeRecordPageUrl = '/glive/web/users/charge_record';
const usedRecordPageUrl = '/glive/web/users/used_record';
const focusUserPageUrl = '/glive/web/users/focus';
const focusedUserPageUrl = '/glive/web/users/focused';
const userDetailUrl = '/glive/web/users/{uid}';
const chargeUserUrl = '/glive/web/users/charge';
const userTimeInfoUrl = '/glive/web/users/capture';
const addUserUrl = '/glive/web/users';
const multAddUsersUrl = '/glive/web/users/batch';
const editUserDepUrl = '/glive/web/users/department';
const editUsersDepUrl = '/glive/web/users/department/batch';
const downloadTemplateUrl = '/glive/web/users/batch_template';
const unfreezeUserUrl = '/glive/web/users/unfreeze';

export {
  uesrPageUrl,
  uesrListUrl,
  activeUserUrl,
  chargeRecordPageUrl,
  usedRecordPageUrl,
  focusUserPageUrl,
  focusedUserPageUrl,
  userDetailUrl,
  chargeUserUrl,
  userTimeInfoUrl,
  addUserUrl,
  multAddUsersUrl,
  editUserDepUrl,
  editUsersDepUrl,
  downloadTemplateUrl
}


interface IUserParams extends IPage {
  nickname?: string;
  mobile?: string;
}

interface IChargeParams extends IPage {
  uid?: string;
}

interface IUseParams extends IPage {
  uid?: string;
}

interface IFocusedUseParams extends IPage {
  focusOnUid?: string;
}

interface IChargeUserParams {
  live2KSeconds?: number;
  liveFhdSeconds?: number;
  liveHdSeconds?: number;
  record2KSeconds?: number;
  recordFhdSeconds?: number;
  recordHdSeconds?: number;
  uid: string[];
}

interface IAddUserParams {
  phone: string;
  password?: string;
  nickname?: string;
  // departmentIds?: number[]
  departmentId?: number;
}

interface IEditUserDepParams {
  departmentId: number[]
  uid: number;
}

interface IUploadTemplate {
  url: string
  fileName: string
}

interface IupdateUserParams {
  departmentId: number
  nickname: string
  phone: string
  uid: number
}

export function fetchUserList(params: IUserParams) {
  return request<IResponse<IPagination<IAppUser>> | IErrorResponse>(uesrPageUrl, {
    method: 'GET',
    params,
  })
}

export function fetchUsers(name: string) {
  return request<IResponse<IAppUser[]> | IErrorResponse>(uesrListUrl, {
    method: 'GET',
    params: { name },
  })
}

export function activateUsers(uids: any[]) {
  return request<IResponse<{}> | IErrorResponse>(activeUserUrl, {
    method: 'POST',
    data: { uids },
  })
}

export function fetchChargeRecordList(params: IChargeParams) {
  return request<IResponse<IPagination<IChargeRecord>> | IErrorResponse>(chargeRecordPageUrl, {
    method: 'GET',
    params,
  })
}

export function fetchUseRecordList(params: IUseParams) {
  return request<IResponse<IPagination<IUseRecord>> | IErrorResponse>(usedRecordPageUrl, {
    method: 'GET',
    params,
  })
}

export function fetchFocusUserList(params: IUseParams) {
  return request<IResponse<IPagination<IAppUser>> | IErrorResponse>(focusUserPageUrl, {
    method: 'GET',
    params,
  })
}

export function fetchFocusedUserList(params: IFocusedUseParams) {
  return request<IResponse<IPagination<IAppUser>> | IErrorResponse>(focusedUserPageUrl, {
    method: 'GET',
    params,
  })
}

export function fetchUserDetail(uid: string) {
  const url = userDetailUrl.replace('{uid}', uid);
  return request<IResponse<IAppUser> | IErrorResponse>(url, {
    method: 'GET',
  })
}

export function chargeUser(params: IChargeUserParams) {
  return request<IResponse<any> | IErrorResponse>(chargeUserUrl, {
    method: 'POST',
    data: params,
  })
}

export function getUserTimeInfo(id: string) {
  return request<IResponse<IAppUser> | IErrorResponse>(userTimeInfoUrl, {
    method: 'GET',
    params: { id },
  })
}

export function addUser(params: IAddUserParams) {
  return request<IResponse<IAppUser> | IErrorResponse>(addUserUrl, {
    method: 'POST',
    data: params,
  })
}

export function multaddUser(file: File) {
  return request<IResponse<IAppUser> | IErrorResponse>(multAddUsersUrl, {
    method: 'POST',
    data: { file },
  })
}

//编辑单个用户部门
export function editUserDep(params: IEditUserDepParams) {
  return request<IResponse<IAppUser> | IErrorResponse>(editUserDepUrl, {
    method: 'PATCH',
    data: { params }
  })
}

export function editUsersDep(departmentId: number, uids: number[]) {
  return request<IResponse<IAppUser> | IErrorResponse>(editUsersDepUrl, {
    method: 'PATCH',
    data: { departmentId, uids }
  })
}

export function deleUser(uid: number) {
  return request<IResponse<IAppUser> | IErrorResponse>(addUserUrl, {
    method: 'DELETE',
    params: { uid }
  })
}

export function unfreezeUser(uid: number) {
  const url = `${unfreezeUserUrl}?uid=${uid}`
  return request<IResponse<IAppUser> | IErrorResponse>(url, {
    method: 'POST',
  })
}

export function updateUser(params: IupdateUserParams) {
  return request<IResponse<IAppUser> | IErrorResponse>(addUserUrl, {
    method: 'PUT',
    data: params
  })
}

export function downloadTemplate() {
  return request<IResponse<IUploadTemplate> | IErrorResponse>(downloadTemplateUrl, {
    method: 'GET',
  })
}

export function importTemplate({ file, resultFileName }: { file: File, resultFileName?: string }) {
  const data = new FormData();
  data.append('file', file, file.name);
  return request<Blob | IErrorResponse>(multAddUsersUrl, {
    method: 'POST',
    data,
  }).then((res) => {
    console.log('res', res);
    if (res instanceof Blob) {
      downloadFileFromBlob(res, `${resultFileName ? resultFileName : Date.now()}.xlsx`);
      return { code: '201' }
    }
    return res;
  })
}


