import {
  Route,
  Redirect,
  RouteProps
} from "react-router-dom";

import { useAuth } from "../models/auth";

export default function PrivateRoute({ children, ...rest }: RouteProps) {
  const { data } = useAuth();
  return (
    <Route
      {...rest}
      render={
        ({ location }) => data.token ? children : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}