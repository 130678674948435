import { Modal, Form, Input, Row, Col } from "antd";
import { FC, useEffect, useCallback } from "react";
import PermissionTree from "../Permission/PermissionTree";


interface IProps {
  visible: boolean;
  onCancel: () => void;
  title: string;
  permissions: IPermission[];
  checkedkeys: string[];
  data: IRolePermission;
  onSelect?: (keys: string[]) => void;
  onOk: (resp: any) => void;
}


const RoleModal: FC<IProps> = ({
  visible,
  onCancel,
  title,
  permissions,
  checkedkeys,
  data,
  onSelect,
  onOk,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    if (data.id) {
      form.setFieldsValue({
        roleName: data.roleName,
        remark: data.remark,
      });
    }
  }, [data, form]);

  const handleOk = useCallback(() => {
    const formValue = form.getFieldsValue();
    onOk(formValue);
  }, [form, onOk])

  return (
    <Modal
      getContainer={false}
      visible={visible}
      onCancel={onCancel}
      title={title}
      onOk={handleOk}
      width="635px"
    >
      <Form form={form}>
        <Row>
          <Col span={14}>
            <h4>权限列表</h4>
            <PermissionTree
              list={permissions}
              checkedKeys={checkedkeys}
              onSelect={onSelect}
              checkable={true}
            />
          </Col>
          <Col
            span={10}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "2vw",
              borderLeft: "solid 1px #edeaea",
            }}
          >
            <Form.Item name="roleName" label="角色名" required>
              <Input placeholder="请输入角色名称" />
            </Form.Item>
            <Form.Item name="remark" label="简介" required>
              <Input placeholder="请输入角色描述" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RoleModal;
