import request from "../util/request";

const companyPageUrl = '/enterprises/page';
const companyDetailUrl = '/enterprises/detail/{id}';
const companyTimeInfoUrl = '/enterprises/capture/{id}';
const companyChargeRecordUrl = '/glive/cc/record';
const companyChargeUrl = '/glive/cc/inc';

const companyDetailGliveUrl = '/glive/company/detail';
const companyInfoUrl = '/glive/cc/info';

export {
  companyPageUrl,
  companyTimeInfoUrl,
  companyDetailUrl,
  companyChargeUrl,
  companyInfoUrl,
  companyChargeRecordUrl,
  companyDetailGliveUrl,
}

interface IChargeParams {
  cloudStorage?: number;
  companyId?: number;
  live2KSeconds?: number;
  liveFhdSeconds?: number;
  liveHdSeconds?: number;
  record2KSeconds?: number;
  recordFhdSeconds?: number;
  recordHdSeconds?: number;
  remark?: string;
}

interface IChargeRecordParams extends IPage {
  companyId?: number;
}

export interface IChargeRecord {
  cloudStorage?: number;
  companyId: number;
  companyName?: string;
  createdAt?: string;
  live2KSeconds?: number;
  liveFhdSeconds?: number;
  liveHdSeconds?: number;
  record2KSeconds?: number;
  recordFhdSeconds?: number;
  recordHdSeconds?: number;
  remark?: string;
  sysUserId?: number;
}


export function companyPage(params: IAepPage) {
  return request<IResponse<IPagination<ICompany>> | IErrorResponse>(companyPageUrl, {
    method: 'POST',
    data: params,
  })
}

export function companyDetail(id: number) {
  const url = companyDetailUrl.replace('{id}', `${id}`);
  return request<IResponse<ICompany> | IErrorResponse>(url, {
    method: 'GET',
  })
}

export function companyDetailGlive() {
  return request<IResponse<ICompany> | IErrorResponse>(companyDetailGliveUrl, {
    method: 'POST',
  })
}

export function companyInfo() {
  return request<IResponse<ICompany> | IErrorResponse>(companyInfoUrl, {
    method: 'GET'
  })
}

export function companyTimeInfo({ id }: { id: number }) {
  const url = companyTimeInfoUrl.replace('{id}', `${id}`);
  return request<IResponse<ICompany> | IErrorResponse>(url, {
    method: 'GET',
    // params: { id },
  })
}

export function companyCharge(params: IChargeParams) {
  return request<IResponse<ICompany> | IErrorResponse>(companyChargeUrl, {
    method: 'PUT',
    data: params,
  })
}

export function companyChargeRecord(params: IChargeRecordParams) {
  return request<IResponse<IPagination<IChargeRecord>> | IErrorResponse>(companyChargeRecordUrl, {
    method: 'GET',
    params,
  })
}
